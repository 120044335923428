import { colors as muiColors } from '@mui/material';

const buildGradient = (start: string, end: string) => `linear-gradient(180deg, ${start} 0%, ${end} 100%)`;

const grey = buildGradient(muiColors.grey[400], muiColors.grey[600]);
const blue = buildGradient(muiColors.blue[700], muiColors.blue[900]);
const indigo = buildGradient(muiColors.indigo[400], muiColors.indigo[600]);
const green = buildGradient(muiColors.green[400], muiColors.green[600]);
const orange = buildGradient(muiColors.orange[400], muiColors.orange[700]);
const red = buildGradient(muiColors.red[500], muiColors.red[700]);

export default {
	grey,
	blue,
	indigo,
	green,
	orange,
	red,
};

export const colors = {
	grey,
	blue,
	indigo,
	green,
	orange,
	red,
};
