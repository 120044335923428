import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			dropzone: {
				display: 'flex',
				width: '100%',
				itemsAlign: 'center',
				justifyContent: 'center',
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},

			dropzoneDisabled: {
				'&>label': {
					backgroundColor: (props: { darkMode: boolean }) =>
						!props.darkMode ? `#f4f4f4 !important` : `#303030 !important`,
					borderColor: '#e3e3e3 !important',
					color: '#959595 !important',
					cursor: 'default !important',
				},
			},

			dropzoneLabel: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				minHeight: '16rem',
				borderRadius: '0.5rem',
				cursor: 'pointer',
				border: `2px ${theme.palette.border.dark} dashed`,
				backgroundColor: theme.palette.background.default,
				color: theme.palette.lightGray.main,
				overflow: 'auto',
			},

			dropzoneLabelActive: {
				border: `2px ${theme.palette.primary.main} dashed`,
				backgroundColor: theme.palette.background.default,
				color: theme.palette.primary.main,
			},

			dropzoneInput: {
				display: 'none',
			},

			dropzoneContent: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '1rem',
				width: '100%',
			},
		};
	},
	{ name: `DelegationList_DelegationModal_DelegationModalContent_DelegationDetails_DelegationAttachments_Dropzone` }
);
