import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Divider, Popover } from '@mui/material';

import { ENotificationReadStatusFilter, IUserNotification } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { Tabs } from '@/layouts/Dashboard/NotificationsPopover/Tabs';
import {
	get_user_notifications_popover,
	set_All_Notification_As_Read_Popover,
	set_Single_Notification_As_Read_Popover,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';

import { QueryListener } from 'view/QueryListener';

import { AlertPopoverProps } from './model';
import { Notification } from './Notification';
import { NotificationSkeleton } from './NotificationSkeleton';
import { Placeholder } from './PlaceHolder';
import PopoverHeader from './PopoverHeader';
import { Resources } from './resources';
import { useStyles } from './styles';

export const NotificationsPopover = (props: AlertPopoverProps): JSX.Element => {
	const { anchorEl, open, closeModal } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTrans('NotificationPopover');

	const [isLoading, startLoading, finishLoading] = useToggle(false);
	const [selectedTab, setSelectedTabInner] = useState<ENotificationReadStatusFilter>(
		ENotificationReadStatusFilter.NotRead
	);

	const userNotifications: IUserNotification[] = useSelector(
		(state: IReducerState) => state.DashBoard.userNotificationsPopover
	);
	const countNotReadNotifications: number = useSelector(
		(state: IReducerState) => state.DashBoard.countOfNotReadUserNotifications
	);

	useEffect(() => {
		if (!selectedTab || open != true) return;

		dispatch(get_user_notifications_popover({ filterByReadStatus: ENotificationReadStatusFilter.All }));
	}, []);

	useEffect(() => {
		if (
			selectedTab === ENotificationReadStatusFilter.Read ||
			!countNotReadNotifications ||
			open != true ||
			!selectedTab
		) {
			return;
		}

		dispatch(get_user_notifications_popover({ filterByReadStatus: selectedTab }));
	}, [countNotReadNotifications]);

	const setSelectedTab = (tab: ENotificationReadStatusFilter) => {
		setSelectedTabInner(tab);
		if (!selectedTab) return;

		startLoading();
		dispatch(get_user_notifications_popover({ filterByReadStatus: tab })).finally(finishLoading);
	};

	const setAllNotificationAsRead = () => {
		startLoading();
		dispatch(
			set_All_Notification_As_Read_Popover(
				Resources.notificationsPopoverDefaultSkip,
				Resources.notificationsPopoverDefaultTake,
				{
					filterByReadStatus: selectedTab,
				}
			)
		).finally(finishLoading);
	};

	const setSingleNotificationAsRead = async (notificationId: number) => {
		dispatch(
			set_Single_Notification_As_Read_Popover(
				notificationId,
				Resources.notificationsPopoverDefaultSkip,
				Resources.notificationsPopoverDefaultTake,
				{ filterByReadStatus: selectedTab }
			)
		);
	};

	const handleShowAllClick = () => {
		history.push(Resources.notificationsListPath);
		closeModal();
	};

	const onQueryChange = (query: string) => {
		if (query) closeModal();
	};

	return (
		<Popover
			open={open ?? false}
			onClose={closeModal}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: Resources.GlobalResources.bottom,
				horizontal: Resources.GlobalResources.center,
			}}
		>
			<div className={clsx(classes.popover, classes.maxOfWidth)}>
				<PopoverHeader setAllNotificationAsRead={setAllNotificationAsRead} closePopver={closeModal} />
				<Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
				<Divider />

				<div className={classes.notificationContainer}>
					<VisibilityControl condition={!isLoading} alternative={<NotificationSkeleton />}>
						<VisibilityControl condition={Boolean(userNotifications?.length)} alternative={<Placeholder />}>
							{userNotifications?.map(notification => (
								<Notification
									notification={notification}
									key={notification.Id}
									setSingleNotificationAsRead={setSingleNotificationAsRead}
									closeModal={closeModal}
								/>
							))}
							<div className={classes.flexXCenter}>
								<Button
									size={Resources.GlobalResources.small}
									className={classes.showAllButton}
									variant={Resources.GlobalResources.contained}
									onClick={handleShowAllClick}
								>
									{t('showAll')}
								</Button>
							</div>
						</VisibilityControl>
					</VisibilityControl>
				</div>
				<QueryListener query={Resources.issueId} callback={onQueryChange} />
			</div>
		</Popover>
	);
};
