import { t } from 'i18next';
import { OptionsObject, ProviderContext } from 'notistack';

export const displayError = (errorCode: number, snackbar: ProviderContext, variant: OptionsObject): void => {
	let errorMessage: string;
	switch (errorCode) {
		case 400: {
			errorMessage = t('Error.error_400');
			break;
		}
		case 401: {
			errorMessage = t('Error.error_401');
			break;
		}
		case 403: {
			errorMessage = t('Error.error_403');
			break;
		}
		case 404: {
			errorMessage = t('Error.error_404');
			break;
		}
		case 500: {
			errorMessage = t('Error.error_500');
			break;
		}
		default:
			errorMessage = t('Error.unknownError');
	}
	snackbar.enqueueSnackbar(errorMessage, variant);
};
