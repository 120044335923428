import { Theme, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		popoverOption: {
			cursor: 'pointer',
			padding: '5px 8px',
			margin: '6px',
			borderRadius: '5px',
			transition: '.2s',

			'&:hover': {
				backgroundColor: theme.palette.background.default,
			},
		},

		menu: {
			'& .MuiMenu-list': {
				paddingTop: '4px',
				paddingBottom: '4px',
				color: 'red',
			},
		},

		menuItem: {
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			},
		},
		badge: {
			backgroundColor: theme.palette.background.paper,
			color: colors.grey[600],
			border: `1px solid ${colors.grey['900']}`,
		},
		transformBadge: {
			transform: 'rotate(30deg)',
		},
		badgeGrid: {
			cursor: 'pointer',
			flex: 0.2,
		},
		paddingRight: {
			paddingRight: theme.spacing(1),
		},
		paddingRightEmpty: {
			paddingRight: theme.spacing(3.6),
		},
	}),
	{ name: `NotificationListMoreOptions` }
);
