import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	rtl: 'rtl',
	ltr: 'ltr',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						Tabs: {
							General: 'Główne',
							Mappings: 'Mapowania',
							Swimlines: 'Swimliny',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						Tabs: {
							General: 'General',
							Mappings: 'Mappings',
							Swimlines: 'Swimlines',
						},
					},
				},
			},
		},
	},
};

export const getTabs = () => [
	{
		value: EKanbanConfigurationTabs.General,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.General'),
	},
	{
		value: EKanbanConfigurationTabs.Swimlines,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.Swimlines'),
	},
	{
		value: EKanbanConfigurationTabs.Mappings,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.Mappings'),
	},
];
export enum EKanbanConfigurationTabs {
	General = 'General',
	Swimlines = 'Swimlines',
	Mappings = 'Mappings',
}
