import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EParameter, EUrlType, IWorkLog } from '@skillandchill/tasker-types';
import { StartPause } from '@skillandchill/tasker-widgets2/dist/widgets/StartPause';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { work_log_finish_NEW, work_log_pause_NEW, work_log_start_NEW } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useToggle } from '@/utils/hooks/useToggle';
import { showTimer } from '@/utils/utils';

import { Props } from './model';
import { useStyles } from './style';

export const TileStartPause = (props: Props): JSX.Element => {
	const { issue } = props;
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();

	const loggedUserId: number = useSelector((state: IReducerState) => state.Session.taskerUser.Id as number);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const [isVisible, setVisible, setInVisible] = useToggle(showTimer(issue?.RoleToIssueRole ?? [], loggedUserId));
	const preference = useSelector((x: IReducerState) => x?.Session?.preferences);

	const [canOpenUrl, setCanOpenUrl] = useState<boolean>(getCanOpenUrl());
	const [urls, setUrls] = useState<string[]>(getUrls());

	useEffect(() => {
		setCanOpenUrl(getCanOpenUrl());
	}, [preference]);

	useEffect(() => {
		setUrls(getUrls());
	}, [issue?.IssueUrl]);

	useEffect(() => {
		const shouldBeVisible = showTimer(issue?.RoleToIssueRole ?? [], loggedUserId);
		if (shouldBeVisible) setVisible();
		else setInVisible();
	}, [issue, activeWorklogs]);

	function getCanOpenUrl() {
		return (
			preference?.filter(x => x?.ParameterId == EParameter.User_OpenMeetWithStartClock && !!x?.ValueBoolean)
				?.length > 0
		);
	}

	function getUrls() {
		return issue?.IssueUrl?.filter(x => x?.UrlTypeId === EUrlType.Meet)?.map(y => y?.Url) ?? [];
	}

	const issueIds = useMemo(() => {
		return activeWorklogs?.map(activeWorklog => activeWorklog?.IssueId);
	}, [activeWorklogs]);

	const isActive = useMemo(() => {
		if (activeWorklogs) {
			const filter = issueIds.filter(x => x == issue?.Id);
			return filter?.length > 0;
		}
		return false;
	}, [issueIds, issue]);

	const handleFinish = (issueId: number): Promise<void> => dispatch(work_log_finish_NEW(issueId));

	const handlePause = (issueId: number): Promise<void> => dispatch(work_log_pause_NEW(issueId));

	const handleStart = (issueId: number): Promise<void> => {
		return dispatch(work_log_start_NEW(issueId));
	};

	return (
		<div className={classes.fullHeight}>
			<VisibilityControl condition={isVisible}>
				<StartPause
					active={isActive}
					canOpen={canOpenUrl}
					urls={urls}
					handleStart={() => handleStart(issue?.Id)}
					handlePause={() => handlePause(issue?.Id)}
					handleFinish={() => handleFinish(issue?.Id)}
				/>
			</VisibilityControl>
		</div>
	);
};

export default TileStartPause;
