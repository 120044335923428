export const w1Width = '45%';
export const w2Width = '17%';
export const w3Width = '12%';
export const w4Width = '9%';
export const w5Width = '7%';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fullWidth: 'fullWidth' as const,
	history: 'history' as const,
	url: (id: number) => `/user/${id}`,
};

export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					onlyMine: 'Mój czas pracy',
					delete: 'Usuń',
					edit: 'Modyfikuj',
					workLogDelete: 'Usunięto wpis czasu pracy',
					errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					onlyMine: 'My time work',
					delete: 'Delete',
					edit: 'Edit',
					workLogDelete: 'Worklog deleted',
					errorWorkLogDelete: 'Error while deleting worklog',
				},
			},
		},
	},
};
