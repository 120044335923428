import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	menuId: 'menuId',
	aariaLabelledby: 'aria-labelledby',
	basicButton: 'basic-button',
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			TableOptions: {
				markAsRead: 'Oznacz jako przeczytane (Oznaczy jako przeczytane wszystkie powiadomienia w systemie)',
			},
		},
	},
	en: {
		NotificationList: {
			TableOptions: {
				markAsRead: 'Set as read (Mask as read all notification in the system)',
			},
		},
	},
};
