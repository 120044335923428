import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fullWidth: 'fullWidth' as const,
	history: 'history' as const,
	minutes: 'm',
	noMinutes: '0m',
	options: {
		variant: 'h6' as const,
	},
};
