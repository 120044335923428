import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@skillchill/redux-promisify';

import {
	EBudgetObject,
	EBudgetPurpose,
	IBudget,
	IBudgetCreate,
	IBudgetDataOptions,
	ICustomerChartData,
	IIncomeLine,
	ILinePeriodical,
	IOutcomeLine,
} from '@skillandchill/tasker-types';
import { ETypeLine } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/linePeriodicalToBudget';
import { IChart } from '@skillandchill/tasker-types/dist/types/endPoints/customerChart';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const BUDGET_SAVE: ActionType = 'BUDGET_SAVE';
export const BUDGET_GET_TENANT: ActionType = 'BUDGET_GET_TENANT';
export const BUDGET_GET_BY_ID_TREE: ActionType = 'BUDGET_GET_BY_ID_TREE';
export const CHART_CUSTOMER_GET: ActionType = 'CHART_CUSTOMER_GET';
export const CHART_DATA_SET: ActionType = 'CHART_DATA_SET';
export const CHART_CUSTOMER_ID_SET: ActionType = 'CHART_CUSTOMER_ID_SET';
export const CHART_TASKER_USER_PROJECT_GET: ActionType = 'CHART_TASKER_USER_PROJECT_GET';
export const CHART_PROJECT_GET: ActionType = 'CHART_PROJECT_GET';
export const CHART_COSTS_BY_YEAR: ActionType = 'CHART_COSTS_BY_YEAR';
export const MOVE_BUDGET: ActionType = 'MOVE_BUDGET';
export const DID_CHANGE_BUDGET: ActionType = 'DID_CHANGE_BUDGET';
export const DELETE_BUDGET_OPTION: ActionType = 'DELETE_BUDGET_OPTION';
export const BUDGET_GET_BY_CUSTOMER_ID_TREE: ActionType = 'BUDGET_GET_BY_CUSTOMER_ID_TREE';
export const BUDGET_GET_BY_PROJECT_ID_TREE: ActionType = 'BUDGET_GET_BY_PROJECT_ID_TREE';
export const BUDGET_GET_BY_ISSUE_ID_TREE: ActionType = 'BUDGET_GET_BY_ISSUE_ID_TREE';

const basePath = 'Budget';
const basePathIncome = 'Budget/IncomeLine';
const basePathOutCome = 'Budget/OutcomeLine';

function _postBudget(budget: IBudget): Action {
	return {
		type: BUDGET_SAVE,
		data: budget,
	};
}

function _getBudgetTenant(budget: IBudget[]): Action {
	return {
		type: BUDGET_GET_TENANT,
		data: budget,
	};
}

function _getBudgetByCustomerId(budget: IBudget[]): Action {
	return {
		type: BUDGET_GET_BY_CUSTOMER_ID_TREE,
		data: budget,
	};
}

function _getBudgetByProjectId(budget: IBudget[]): Action {
	return {
		type: BUDGET_GET_BY_PROJECT_ID_TREE,
		data: budget,
	};
}

function _getBudgetByIssueId(budget: IBudget[]): Action {
	return {
		type: BUDGET_GET_BY_ISSUE_ID_TREE,
		data: budget,
	};
}

function _getBudgetById(budget: IBudget[]): Action {
	return {
		type: BUDGET_GET_BY_ID_TREE,
		data: budget,
	};
}

function _moveBudget(data: { Prev: IBudget; Curr: IBudget }): Action {
	return {
		type: MOVE_BUDGET,
		data,
	};
}

function _deleteBudgetOption(id: number, option: EBudgetObject): Action {
	return {
		type: DELETE_BUDGET_OPTION,
		data: { id, option },
	};
}

function _getCustomerChart(chartData: ICustomerChartData): Action {
	return {
		data: chartData,
		type: CHART_CUSTOMER_GET,
	};
}

function _setChartCustomerId(customerId: number): Action {
	return {
		data: customerId,
		type: CHART_CUSTOMER_ID_SET,
	};
}

function _setChartData(data: Partial<ICustomerChartData>): Action {
	return {
		data,
		type: CHART_DATA_SET,
	};
}

function _getTaskerUserProjectChart(chartData: IChart): Action {
	return {
		data: chartData,
		type: CHART_TASKER_USER_PROJECT_GET,
	};
}
function _getProjectChart(chartData: IChart): Action {
	return {
		data: chartData,
		type: CHART_PROJECT_GET,
	};
}

function _getTaskerUserCostsByYearChart(chartData: IChart): Action {
	return {
		data: chartData,
		type: CHART_COSTS_BY_YEAR,
	};
}

export function didChangeBudget(): Action {
	return {
		type: DID_CHANGE_BUDGET,
	};
}

export function budget_post(budget: Partial<IBudget>, purpose: EBudgetPurpose, idObjectConnected: number): AsyncAction {
	const data: IBudgetCreate = {
		data: budget,
		purpose: purpose,
		id: idObjectConnected,
	};
	return function(context, dispatch) {
		context.then(response => dispatch(_postBudget((response as IJson)?.data)));
		return axios().post(basePath, data);
	};
}

export function budget_get(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_postBudget((response as IJson)?.data)));
		return axios().get(`${basePath}/ByProject/${projectId}`);
	};
}

export function incomeLine_post(data: IIncomeLine): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_postBudget((response as IJson)?.data)));
		return axios().post(basePathIncome, data);
	};
}

export function outComeLine_post(data: IOutcomeLine): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_postBudget((response as IJson)?.data)));
		return axios().post(basePathOutCome, data);
	};
}

let source: CancelTokenSource | undefined;
export function chartCustomer_get(id: number): AsyncAction {
	if (source) source?.cancel();
	source = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(response => {
			source = undefined;
			return dispatch(_getCustomerChart((response as IJson)?.data));
		});
		return axios().get(`${basePath}/Chart/Customer/${id}`, { cancelToken: source?.token });
	};
}

export function chartCustomerId_set(id: number): Action {
	return _setChartCustomerId(id);
}

export function chartData_set(data: Partial<ICustomerChartData>): Action {
	return _setChartData(data);
}

export function chartTaskerUserProject_get(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getTaskerUserProjectChart((response as IJson)?.data)));
		return axios().get(`${basePath}/Chart/TaskerUsers/Project/${id}`);
	};
}

export function chartProject_get(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getProjectChart((response as IJson)?.data)));
		return axios().get(`${basePath}/Chart/Project/${id}`);
	};
}

export function chartTaskerUserCostsByYear_get(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getTaskerUserCostsByYearChart((response as IJson)?.data)));
		return axios().get(`${basePath}/Chart/TaskerUser/Project/ByYear/${id}`);
	};
}

export function getBudgetTenant(): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getBudgetTenant((response as IJson)?.data)));
		return axios().get(`${basePath}/tenant`);
	};
}

export function getBudgetById(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getBudgetById((response as IJson)?.data)));
		return axios().get(`${basePath}/Id/${id}`);
	};
}

export function getBudgetByCustomerId(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getBudgetByCustomerId((response as IJson)?.data)));
		return axios().get(`${basePath}/CustomerId/${id}`);
	};
}

export function getBudgetByProjectId(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getBudgetByProjectId((response as IJson)?.data)));
		return axios().get(`${basePath}/ProjectId/${id}`);
	};
}

export function getBudgetByIssueId(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getBudgetByIssueId((response as IJson)?.data)));
		return axios().get(`${basePath}/IssueId/${id}`);
	};
}

export function moveBudget(
	from: number,
	to: number,
	budgetObject: EBudgetObject,
	object: IBudgetDataOptions
): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_moveBudget((response as IJson)?.data)));
		return axios().post(`${basePath}/move/from/${from}/to/${to}`, { type: budgetObject, object: object });
	};
}

export function deleteBudgetOption(id: number, budgetOption: EBudgetObject): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_deleteBudgetOption(id, budgetOption)));
		return axios().delete(`${basePath}/${id}/type/${budgetOption}`);
	};
}

export function linePeriodical_Create_OR_Update(line: Partial<ILinePeriodical>, type: ETypeLine): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_postBudget((json as IJson)?.data)));
		return axios().patch(`${basePath}/LinePeriodical`, {
			type,
			object: line,
		});
	};
}
