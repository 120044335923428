import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	xAxis: 'x-axis' as const,
	greenBright: `rgba(75, 196, 12, 0.68)`,
	greenBrighter: `rgba(79, 208, 15, 0.85)`,
	greenDark: `rgba(88, 243, 9, 0.3)`,
	grayDark: `rgba(78, 227, 57, 0)`,
	singleStep: 60,

	chartWidth: '100%',
	chartHeight: '90%',
	worklogsFinished: 'worklogsFinished',
	dayOfWeek: 'dayOfWeek',
	worklogActive: 'worklogActive',
	worklogSum: 'worklogSum',
	stackId: '1',
	one: '1',
	zero: '0',
	colorActive1: '#259449',
	colorActive2: '#33b35c',
	colorActive3: '#5ace7e',
	colorInactive1: '#1e5d33',
	colorInactive2: '#20753c',
	colorInactive3: '#259449',
	start: '0%',
	center: '50%',
	end: '100%',
	activeGradientId: 'gradient',
	inactiveGradientId: 'inactive_gradient',
	strokeDasharray: '4 1',
	xAxisTick: { fontSize: '12px' },
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectCharts: {
					paperTitle: 'Zalogowany czas',
					paperSubtitle: 'Czas zalogowany na zadania w tym projekcie',
					thisWeek: 'Ten tydzień',
					lastWeek: 'Ostatni tydzień',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectCharts: {
					paperTitle: 'Logged time',
					paperSubtitle: 'Time logged on issues in this project',
					thisWeek: 'This week',
					lastWeek: 'Last week',
				},
			},
		},
	},
};
