import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Resources } from './resources';
import { useStyles } from './styles';

export const HeightPreservingItem = (props: any): any => {
	const [size, setSize] = useState(0);
	const classes = useStyles({ size });

	const knownSize = props[Resources.dataKnownSize];

	useEffect(() => {
		setSize(prevSize => {
			return knownSize == 0 ? prevSize : knownSize;
		});
	}, [knownSize]);

	return (
		<div {...props} className={clsx(classes.placeholderHeightSetter, Resources.heightPreservingContainer)}>
			{props?.children}
		</div>
	);
};
