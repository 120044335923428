export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								CustomDictionaryDefaultToggle: {
									defaultTooltip: 'Ustaw jako domyślny',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								CustomDictionaryDefaultToggle: {
									defaultTooltip: 'Set as default',
								},
							},
						},
					},
				},
			},
		},
	},
};
