import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: 'issueForm_Main_Section',
	dash: '_',
	path: '/issueForms',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							form: 'Formularz',
							title: 'Konfiguracja zakończenia dnia pracy',
							description:
								'Edytuj formularz, powiadomienia oraz użytkowników w konfiguracji zakończenia dnia pracy',
							editConfiguration: 'Edytuj konfigurację',
							notifications: 'Powiadomienia',
							users: 'Użytkownicy',
							placeholder: 'Szukaj...',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							form: 'Form',
							title: 'End work day configuration',
							description: 'Edit the form, notifications, and users in the end-of-day configuration',
							editConfiguration: 'Edit configuration',
							notifications: 'Notifications',
							users: 'Users',
							placeholder: 'Search...',
						},
					},
				},
			},
		},
	},
};
