import {
	EFieldDefinitionType,
	IAttachment,
	IDictionaryItem,
	IFieldValue,
	IIssue,
	ITag,
	ITaskerUser,
} from '@skillandchill/tasker-types';

export enum EIssueModalDetailsPageFields {
	title = 'Title',
	projectId = 'ProjectId',
	projectIssueTypeId = 'ProjectIssueTypeId',
	description = 'Description',
	descriptionHtml = 'DescriptionHtml',
	assigned = 'Assigned',
	observer = 'Observer',
	reporter = 'Reporter',
	auditor = 'Auditor',
	properties = 'Properties',
	attachment = 'Attachment',
	subTasks = 'SubTasks',
	parentTasks = 'ParentTasks',
	equivalentTasks = 'EquivalentTasks',
}

export interface IFieldValuesChange {
	fieldsValueToCreate?: Partial<IFieldValue>[];
	fieldsValueToUpdate?: Partial<IFieldValue>[];
	fieldsValueToDelete?: number[];
}

export enum EInputTypes {
	text = 'text',
	number = 'number',
}

export interface IIssueModalData {
	Id?: number;
	Title?: string;
	ProjectId?: number | null;
	DescriptionHtml?: string;
	Description?: string;
	Assigned?: Partial<ITaskerUser>[];
	Observer?: Partial<ITaskerUser>[];
	Reporter?: Partial<ITaskerUser>[];
	Auditor?: Partial<ITaskerUser>[];
	ProjectIssueTypeId?: number | null;
	Attachment?: IAttachmentOnCreate[];
	// SubTasks?: Partial<IIssueToIssueRelationship> & { uId: string };
	SubTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	ParentTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	EquivalentTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	Properties: {
		[EFieldDefinitionType.Text]?: Record<number, string>;
		[EFieldDefinitionType.TextArea]?: Record<number, string>;
		[EFieldDefinitionType.Integer]?: Record<number, number>;
		[EFieldDefinitionType.Decimal]?: Record<number, number>;
		[EFieldDefinitionType.TagGroup]?: Record<number, ITag[]>;
		[EFieldDefinitionType.Boolean]?: Record<number, boolean>;
		[EFieldDefinitionType.Date]?: Record<number, Date>;
		[EFieldDefinitionType.DateTime]?: Record<number, Date>;
		[EFieldDefinitionType.Dictionary]?: Record<number, IDictionaryItem[]>;
	};
}

export interface IAttachmentOnCreate extends Partial<IAttachment> {
	File?: File;
	uId: string;
}

// export enum EIssueRoleString {
// 	assigned = 'Assigned',
// }
