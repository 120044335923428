import uuid from 'uuid';

import { IDelegationDictionaryItem } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IModalData } from '@/views/DelegationsList/DelegationModal/model';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
	getTransportTypeKey: (item: IDelegationDictionaryItem) => `Transport_${item?.Id}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							OriginRoute: {
								legend: 'Początek delegacji',
								dateTimePickerLabel: 'Data wyjazdu',
								locationInputLabel: 'Miejsce wyjazdu',
								distanceInputLabel: 'Dystans (km)',
								transportSelectLabel: 'Środek transportu',
								carPlateNumberLabel: 'Numer rejestracyjny pojazdu',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							OriginRoute: {
								legend: 'Origin',
								dateTimePickerLabel: 'Departure date',
								locationInputLabel: 'Departure location',
								distanceInputLabel: 'Distance (km)',
								transportSelectLabel: 'Mean of transport',
								carPlateNumberLabel: 'Car plate number',
							},
						},
					},
				},
			},
		},
	},
};

export const getLegendSuffix = (modalData: IModalData) => {
	const location = modalData?.delegationDetails?.origin?.location?.displayName;
	return location ? ` - ${location}` : Resources.GlobalResources.empty;
};
