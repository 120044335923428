import { calculateTableRows } from '../utils';

import { InvoiceTemplateBase } from './base';
import { IInvoiceTemplateHeading, InvoiceTemplateHeading } from './heading';
import { InvoiceTemplateStyles } from './styles';
import { IInvoiceTemplateSummary, InvoiceTemplateSummary } from './summary';
import { IInvoiceTemplateTable, InvoiceTemplateTable } from './table';

export interface IInvoiceTemplate {
	heading: IInvoiceTemplateHeading;
	table: IInvoiceTemplateTable;
	summary: IInvoiceTemplateSummary;
}

export const InvoiceTemplate = (props: IInvoiceTemplate): string[] => {
	let remainingInvoiceItems = props.table.data;
	const pages = [];
	let pageIdx = 1;

	const prepInvoiceHeadingPage = InvoiceTemplateBase(
		{
			title: `test`,
			styles: InvoiceTemplateStyles(),
			children: `
      ${InvoiceTemplateHeading(props.heading)}
      ${InvoiceTemplateTable({ data: remainingInvoiceItems })}
    `,
		},
		pageIdx
	);
	const invoiceHeadingPageRows = calculateTableRows(prepInvoiceHeadingPage, '#items-table', 250);
	let invoiceHeadingPage;

	if (invoiceHeadingPageRows.rowsCount === remainingInvoiceItems.length) {
		// Modify the number value is summary is being cropped
		if (invoiceHeadingPageRows.sumHeight <= 200) {
			invoiceHeadingPage = InvoiceTemplateBase(
				{
					title: `test`,
					styles: InvoiceTemplateStyles(),
					children: `
					${InvoiceTemplateHeading(props.heading)}
          ${InvoiceTemplateTable({ data: remainingInvoiceItems })}
          ${InvoiceTemplateSummary(props.summary)}
				`,
				},
				pageIdx
			);
			remainingInvoiceItems = remainingInvoiceItems.slice(invoiceHeadingPageRows.rowsCount);
		} else {
			invoiceHeadingPage = InvoiceTemplateBase(
				{
					title: `test`,
					styles: InvoiceTemplateStyles(),
					children: `
            ${InvoiceTemplateHeading(props.heading)}
            ${InvoiceTemplateTable({ data: remainingInvoiceItems.slice(0, -1) })}
          `,
				},
				pageIdx
			);
			remainingInvoiceItems = remainingInvoiceItems.slice(invoiceHeadingPageRows.rowsCount - 1);
		}
	} else {
		invoiceHeadingPage = InvoiceTemplateBase(
			{
				title: `test`,
				styles: InvoiceTemplateStyles(),
				children: `
				${InvoiceTemplateHeading(props.heading)}
				${InvoiceTemplateTable({
					data: remainingInvoiceItems,
					from: 0,
					to: invoiceHeadingPageRows.rowsCount,
				})}
			`,
			},
			pageIdx
		);
		remainingInvoiceItems = remainingInvoiceItems.slice(invoiceHeadingPageRows.rowsCount);
	}
	pages.push(invoiceHeadingPage);
	pageIdx++;

	while (remainingInvoiceItems.length > 0) {
		const prepInvoiceItemsPage = InvoiceTemplateBase(
			{
				title: `test`,
				styles: InvoiceTemplateStyles(),
				children: `
        ${InvoiceTemplateTable({ data: remainingInvoiceItems })}
      `,
			},
			pageIdx
		);
		const invoiceItemsPageRows = calculateTableRows(prepInvoiceItemsPage, '#items-table', 800);
		let invoiceItemsPage;

		if (invoiceItemsPageRows.rowsCount === remainingInvoiceItems.length) {
			if (invoiceItemsPageRows.sumHeight <= 750) {
				invoiceItemsPage = InvoiceTemplateBase(
					{
						title: `test`,
						styles: InvoiceTemplateStyles(),
						children: `
              ${InvoiceTemplateTable({ data: remainingInvoiceItems })}
              ${InvoiceTemplateSummary(props.summary)}
            `,
					},
					pageIdx
				);
				remainingInvoiceItems = remainingInvoiceItems.slice(invoiceItemsPageRows.rowsCount);
			} else {
				invoiceItemsPage = InvoiceTemplateBase(
					{
						title: `test`,
						styles: InvoiceTemplateStyles(),
						children: `
              ${InvoiceTemplateTable({ data: remainingInvoiceItems.slice(0, -1) })}
            `,
					},
					pageIdx
				);
				remainingInvoiceItems = remainingInvoiceItems.slice(invoiceItemsPageRows.rowsCount - 1);
			}
		} else {
			invoiceItemsPage = InvoiceTemplateBase(
				{
					title: `test`,
					styles: InvoiceTemplateStyles(),
					children: `
            ${InvoiceTemplateTable({
				data: remainingInvoiceItems,
				from: 0,
				to: invoiceItemsPageRows.rowsCount,
			})}
          `,
				},
				pageIdx
			);
			remainingInvoiceItems = remainingInvoiceItems.slice(invoiceItemsPageRows.rowsCount);
		}

		pages.push(invoiceItemsPage);
		pageIdx++;
	}

	return pages;
};
