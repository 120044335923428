import { Translation as HeaderSuffix } from './HeaderSuffix/resources';
import { Translation as ProjectList } from './resources';
import { Translation as AvatarAndCreator } from './TileComponents/AvatarAndCreator/resources';
import { Translation as DetailsButton } from './TileComponents/DetailsButton/resources';
import { Translation as ProjectManager } from './TileComponents/ProjectManager/resources';
import { Translation as ProjectOwner } from './TileComponents/ProjectOwner/resources';
import { Translation as UsersInProject } from './TileComponents/UsersInProject/resources';

export const ProjectListTranslationEN = [
	HeaderSuffix.en,
	ProjectList.en,
	AvatarAndCreator.en,
	DetailsButton.en,
	ProjectManager.en,
	ProjectOwner.en,
	UsersInProject.en,
];

export const ProjectListTranslationPL = [
	HeaderSuffix.pl,
	ProjectList.pl,
	AvatarAndCreator.pl,
	DetailsButton.pl,
	ProjectManager.pl,
	ProjectOwner.pl,
	UsersInProject.pl,
];
