import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			container: { margin: `${theme.spacing(2)} 0` },
			leftSideContainer: {
				gridTemplateColumns: '1fr',
				width: '70%',
				'& .select__multi-value': {
					maxWidth: '130px',
					display: 'flex',
					justifyContent: 'space-between',
				},
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage` }
);
