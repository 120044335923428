import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		invisible: {
			width: 0,
			height: 0,
			overflow: 'hidden',
			border: 'none',
			position: 'absolute',
			top: '-9999px',
			visibility: 'hidden',
			display: 'none',
		},
	}),
	{ name: 'Sound' }
);
