import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),

		tag: {
			borderRadius: '20px',
			padding: '3px 10px',
			fontSize: '12px',
			marginLeft: theme.spacing(0.5),
			width: 'fit-content',
			maxWidth: '100%',
			backgroundColor: props => props.tagColor,
			cursor: 'pointer',
		},
		tagText: {
			// color: props => (chroma(props.tagColor ?? 'rgba(75,208,66,0.8)').luminance() < 0.33 ? 'white' : 'black'),
			color: props => props.tagColor,
			filter: 'invert(100%)',
			maxWidth: '100%',
			width: 'fit-content',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			margin: '0 auto',
		},

		menu: {
			'& .MuiMenu-list': {
				padding: theme.spacing(0.5),
			},
		},

		menuItem: {
			borderRadius: '20px',
			padding: '3px 10px',
			fontSize: '12px',
			width: '100%',
			cursor: 'pointer',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'&>span>div': {
				textAlign: 'center',
				width: '100%',
			},
		},

		menuItemWrapper: {
			width: '100%',
			padding: theme.spacing(1, 0.5),
			borderRadius: theme.spacing(0.75),
			'&:hover': {
				backgroundColor: theme.palette.action.hover,
			},
			cursor: 'pointer',
		},
	}),
	{ name: `NotificationListMoreOptions` }
);
