import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	projectId: 'projects/',
	overview: 'overview',
	customers: 'customers/',
	summary: 'summary',

	...GlobalResources,
};
