import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueList: {
			IssueListPagination: {
				filterButton: {
					button: 'Filtry',
					tooltip: 'Filtruj',
				},
				radioButtons: {
					portal: 'Portal',
					issues: 'Zgłoszenia',
					all: 'Wszystkie',
					favourites: 'Ulubione',
				},
			},
		},
	},
	en: {
		IssueList: {
			IssueListPagination: {
				filterButton: {
					button: 'Filter',
					tooltip: 'Filter',
				},
				radioButtons: {
					portal: 'Portal',
					issues: 'Issues',
					all: 'All',
					favourites: 'Favourites',
				},
			},
		},
	},
};

export enum EPortalFilter {
	All = 1,
	Portal = 2,
	Issues = 3,
	Favourites = 4,
}
