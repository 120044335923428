import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	initialErrorState: { labelError: false, colorError: false },
	defaultTagColor: '#00ff30',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					TagsCreation: {
						addNewTag: 'Dodaj nowy tag',
						or: 'Lub',
						chooseExistingTagGroup: 'Wybierz grupę tagów aby dodać tagi z istniejącej już grupy',
						Errors: {
							labelEmptyErrorMessage: '^Podaj nazwę kolumny!',
							labelTooLongErrorMessage: '^Nazwa kolumny jest za długa!',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					TagsCreation: {
						addNewTag: 'Add new tag',
						or: 'or',
						chooseExistingTagGroup: 'Choose a tag group to add tags from already existing tag group',
						Errors: {
							labelEmptyErrorMessage: '^Enter name of the column!',
							labelTooLongErrorMessage: '^The column name is too long!',
						},
					},
				},
			},
		},
	},
};

export const validationTagSchema = {
	Label: {
		presence: {
			allowEmpty: false,
			message: t('Kanban.KanbanBoard.KanbanCreator.TagsCreation.Errors.labelEmptyErrorMessage'),
		},
		length: {
			maximum: 200,
			message: t('Kanban.KanbanBoard.KanbanCreator.TagsCreation.Errors.labelTooLongErrorMessage'),
		},
	},
};
