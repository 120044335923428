import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: 'issueForm_Main_Section',
	dash: '_',
	path: '/issueForms',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							InfoComponentFormPart: {
								formLabel: 'Nazwa formularza',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							InfoComponentFormPart: {
								formLabel: 'Form name',
							},
						},
					},
				},
			},
		},
	},
};
