import { AsyncAction } from '@skillchill/redux-promisify';

import {
	EIssueDirection,
	FullFilters,
	IAttachment,
	IComment,
	IControllerIssueDataInput,
	IFieldValue,
	IIssue,
	IIssueForm,
	IIssueIssueModal,
	IIssueKanbanDetails,
	IIssueModal,
	IIssueToIssueRelationship,
	IPage,
} from '@skillandchill/tasker-types';
import { IMapTagToFieldDefinition } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/mapTagToTag';
import { Reckon } from '@skillandchill/tasker-widgets2/dist/utils/types/reckon';

import { ISSUE_FETCH_NEW } from '@/store/actions/issueActions';
import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { ISSUE_FETCH_UPDATE } from './issueActions';

const basePath = 'IssueModal';
export const ISSUE_MODAL_FETCH_BASIC: ActionType = 'ISSUE_MODAL_FETCH_BASIC';
export const ISSUE_MODAL_FETCH_COMMENTS: ActionType = 'ISSUE_MODAL_FETCH_COMMENTS';
export const ISSUE_MODAL_PATCH_COMMENT: ActionType = 'ISSUE_MODAL_PATCH_COMMENT';
export const ISSUE_MODAL_ADD_CONNECTION: ActionType = 'ISSUE_MODAL_ADD_CONNECTION';
export const ISSUE_MODAL_ISSUE_OPTIONS: ActionType = 'ISSUE_MODAL_ISSUE_OPTIONS';
export const ISSUE_MODAL_PASS_DATA: ActionType = 'ISSUE_MODAL_PASS_DATA';
export const ISSUE_MODAL_REMOVE_CONNECTION: ActionType = 'ISSUE_MODAL_REMOVE_CONNECTION';
export const ISSUE_MODAL_GET_FORMS: ActionType = 'ISSUE_MODAL_GET_FORMS';
export const ISSUE_MODAL_GET_FORMS_IN_KANBAN: ActionType = 'ISSUE_MODAL_GET_FORMS_IN_KANBAN';
export const ISSUE_MODAL_UPDATE_FORM_VALUES_LOCAL: ActionType = 'ISSUE_MODAL_UPDATE_FORM_VALUES_LOCAL';
export const ISSUE_MODAL_RESET_FORM_VALUES_LOCAL: ActionType = 'ISSUE_MODAL_RESET_FORM_VALUES_LOCAL';
export const ISSUE_MODAL_RESET: ActionType = 'ISSUE_MODAL_RESET';
export const SET_COMMENT: ActionType = 'SET_COMMENT';
export const ISSUE_ADD_NEW: ActionType = 'ISSUE_ADD_NEW';
export const KANBAN_ISSUE_UPDATE: ActionType = 'KANBAN_ISSUE_UPDATE';
export const SET_ISSUE_SUBTASKS: ActionType = 'SET_ISSUE_SUBTASKS';
export const SET_ISSUE_HIGHERORDERTASKS: ActionType = 'SET_ISSUE_HIGHERORDERTASKS';
export const SET_ISSUE_EQUIVALENTTASKS: ActionType = 'SET_ISSUE_EQUIVALENTTASKS';
export const RESET_ISSUEMODAL: ActionType = 'RESET_ISSUEMODAL';
export const ISSUE_FETCH_NEXT: ActionType = 'ISSUE_FETCH_NEXT';

export function setComment(comment: string, commentHtml: string): Action {
	return {
		type: SET_COMMENT,
		data: comment,
		dataHTML: commentHtml,
	};
}

function _fetchIssueModalBasic(modal: IIssueModal): Action {
	return {
		type: ISSUE_MODAL_FETCH_BASIC,
		modal,
	};
}

export function issue_modal_reset(): Action {
	return {
		type: ISSUE_MODAL_RESET,
	};
}

export function _fetchIssueModalComments(comments: IComment[], commentId?: number): Action {
	return {
		type: ISSUE_MODAL_FETCH_COMMENTS,
		comments,
		commentId,
	};
}

export function _patchIssueModalComment(comment: IComment): Action {
	return {
		type: ISSUE_MODAL_PATCH_COMMENT,
		comment,
	};
}

// export function resetIssueModal

function _createIssues(issues: { count: number; issue: IIssue[] }): Action {
	return {
		type: ISSUE_FETCH_NEW,
		issues,
	};
}

function _createKanbanIssue(issue: Partial<IIssue>, kanbanColumnId: number): Action {
	return {
		type: ISSUE_ADD_NEW,
		issue,
		kanbanColumnId,
	};
}

function _updateIssues(issues: { count: number; issue: IIssue[] }): Action {
	return {
		type: ISSUE_FETCH_UPDATE,
		issues,
	};
}

function _updateKanbanIssue(issue: Partial<IIssue>): Action {
	return {
		type: KANBAN_ISSUE_UPDATE,
		issue,
	};
}

function _getIssueForms(data: IIssueForm): Action {
	return {
		type: ISSUE_MODAL_GET_FORMS,
		data,
	};
}

function _getIssueFormsInKanban(data: { form: IIssueForm; mappings: IMapTagToFieldDefinition[] }): Action {
	return {
		type: ISSUE_MODAL_GET_FORMS_IN_KANBAN,
		data,
	};
}

export function resetFormValuesLocal(): Action {
	return {
		type: ISSUE_MODAL_RESET_FORM_VALUES_LOCAL,
	};
}

function _getOptions(data: IIssue[]): Action {
	return {
		type: ISSUE_MODAL_ISSUE_OPTIONS,
		data,
	};
}

function setIssueSubtasks(data: IPage<IIssue[]>): Action {
	return {
		type: SET_ISSUE_SUBTASKS,
		issueSubtasks: data.Data,
		issueSubtasksCount: data.Count,
	};
}

function setIssueHigherOrderTasks(data: IPage<IIssue[]>): Action {
	return {
		type: SET_ISSUE_HIGHERORDERTASKS,
		issueHigherOrderTasks: data.Data,
		issueHigherOrderTasksCount: data.Count,
	};
}

function setIssueEquivalentTasks(data: IPage<IIssue[]>): Action {
	return {
		type: SET_ISSUE_EQUIVALENTTASKS,
		issueEquivalentTasks: data.Data,
		issueEquivalentTasksCount: data.Count,
	};
}

export function _fetchNext(data: IIssueIssueModal): Action {
	return {
		type: ISSUE_FETCH_NEXT,
		data,
	};
}

export function issue_modal_update(
	filter: FullFilters,
	from: number,
	count: number,
	issue: Partial<IIssue>,
	fieldsValueToCreate?: Partial<IFieldValue>[],
	fieldsValueToUpdate?: Partial<IFieldValue>[],
	fieldsValueToDelete?: Partial<IFieldValue>[],
	kanbanDetails?: Partial<IIssueKanbanDetails>,
	comment?: string,
	commentHtml?: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json =>
			dispatch(
				kanbanDetails?.isKanban
					? _updateKanbanIssue((json as IJson)?.data)
					: _updateIssues((json as IJson)?.data)
			)
		);

		return kanbanDetails?.isKanban
			? axios().patch(`${basePath}/single`, {
					issue,
					filter,
					from,
					count,
					fieldValue: {
						create: fieldsValueToCreate,
						update: fieldsValueToUpdate,
						delete: fieldsValueToDelete,
					},
					kanbanDetails,
					comment,
					commentHtml,
			  } as IControllerIssueDataInput)
			: axios().patch(basePath, {
					issue,
					filter,
					from,
					count,
					fieldValue: {
						create: fieldsValueToCreate,
						update: fieldsValueToUpdate,
						delete: fieldsValueToDelete,
					},
					kanbanDetails,
					comment,
					commentHtml,
			  } as IControllerIssueDataInput);
	};
}

export function _passKanbanData(data: Partial<IIssueKanbanDetails>): Action {
	return {
		type: ISSUE_MODAL_PASS_DATA,
		data,
	};
}

export function updateFormValuesLocal(
	create: Partial<IFieldValue>[],
	update: Partial<IFieldValue>[],
	toDelete: Partial<IFieldValue>[]
): Action {
	return {
		type: ISSUE_MODAL_UPDATE_FORM_VALUES_LOCAL,
		create,
		update,
		toDelete,
	};
}

export function issueModal_fetchBasic(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchIssueModalBasic((data as IJson)?.data as IIssueModal)));
		return axios().get(basePath);
	};
}

export function issue_modal_create(
	filter: FullFilters,
	from: number,
	count: number,
	issue: Partial<IIssue>,
	reckon?: Partial<Reckon>,
	tags?: number[],
	files?: File[],
	attachment?: Partial<IAttachment>[],
	subTasks?: number[],
	parentTasks?: number[],
	fieldsValueToCreate?: Partial<IFieldValue>[],
	fieldsValueToUpdate?: Partial<IFieldValue>[],
	kanbanDetails?: Partial<IIssueKanbanDetails>
): AsyncAction {
	const fd = new FormData();
	if (files) {
		for (let i = 0; i < files?.length; i++) {
			const file = files[i];
			fd.append('files', file);
		}
	}
	if (attachment) fd.append('attachment', JSON.stringify(attachment));
	fd.append('issue', JSON.stringify(issue));
	fd.append('filter', JSON.stringify(filter));
	fd.append('from', JSON.stringify(from));
	fd.append('count', JSON.stringify(count));
	if (subTasks) fd.append('relationChild', JSON.stringify(subTasks?.join(',')));
	if (parentTasks) fd.append('relationParent', JSON.stringify(parentTasks?.join(',')));
	if (kanbanDetails?.kanbanId) {
		delete kanbanDetails?.mappedTags;
		fd.append('kanbanDetails', JSON.stringify(kanbanDetails));
	}
	fd.append(
		'fieldValue',
		JSON.stringify({
			create: fieldsValueToCreate,
			update: fieldsValueToUpdate,
		})
	);
	return function(context, dispatch) {
		context.then(json =>
			dispatch(
				kanbanDetails?.isKanban && kanbanDetails?.columnTagId
					? _createKanbanIssue((json as IJson)?.data, kanbanDetails?.columnTagId)
					: _createIssues((json as IJson)?.data)
			)
		);
		return axios().post(kanbanDetails?.columnTagId ? `${basePath}/single` : basePath, fd);
	};
}

export function fetch_IssueModalComments(issueId: number, deleted = false): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssueModalComments((json as IJson).data)));
		return axios().get(`${basePath}/comments/${deleted ? 'deleted/' : ''}issue/${issueId}`);
	};
}

export function add_SubTaskIssueConnection(issueId: number, data: Partial<IIssueToIssueRelationship>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueSubtasks((json as IJson).data)));
		return axios().post(`${basePath}/SubTaskConnection/${issueId}`, data);
	};
}

export function add_HigherOrderIssueConnection(issueId: number, data: Partial<IIssueToIssueRelationship>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueHigherOrderTasks((json as IJson).data)));
		return axios().post(`${basePath}/HigherOrderTaskConnection/${issueId}`, data);
	};
}

export function add_EquivalentIssueConnection(issueId: number, data: Partial<IIssueToIssueRelationship>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueEquivalentTasks((json as IJson).data)));
		return axios().post(`${basePath}/EquivalentTaskConnection/${issueId}`, data);
	};
}

export function remove_subTask_Connection(issueId: number, connectionId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueSubtasks((json as IJson).data)));
		return axios().delete(`${basePath}/Issue/${issueId}/SubTaskConnection/${connectionId}`);
	};
}

export function remove_higherOrderTask_Connection(issueId: number, connectionId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueHigherOrderTasks((json as IJson).data)));
		return axios().delete(`${basePath}/Issue/${issueId}/HigherOrderTaskConnection/${connectionId}`);
	};
}

export function remove_equivalentTask_Connection(issueId: number, connectionId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueEquivalentTasks((json as IJson).data)));
		return axios().delete(`${basePath}/Issue/${issueId}/EquivalentTaskConnection/${connectionId}`);
	};
}

export function get_IssueModalOptions(name = 'empty', count: number, issueId?: number): AsyncAction {
	const idPath = issueId ? `/id/${issueId}` : '';
	return function(context, dispatch) {
		context.then(json => dispatch(_getOptions((json as IJson).data)));
		return axios().get(`${basePath}/Issues/Options/ByName/${name}/count/${count}${idPath}`);
	};
}

export function get_IssueFormByIssueProjectTypeId(projectIssueTypeId: number, issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getIssueForms((json as IJson)?.data)));
		return axios().get(`${basePath}/Form/ProjectIssueType/${projectIssueTypeId}/issueId/${issueId}`);
	};
}

export function get_IssueFormInKanbanByIssueProjectTypeId(projectIssueTypeId: number, kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getIssueFormsInKanban((json as IJson)?.data)));
		return axios().get(`${basePath}/FormInKanban/ProjectIssueType/${projectIssueTypeId}/${kanbanId}`);
	};
}

export function get_Issue_Subtasks(issueId: number, from: number, count: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueSubtasks((json as IJson)?.data)));
		return axios().get(`${basePath}/${issueId}/subtasks/from/${from}/count/${count}`);
	};
}

export function get_Issue_HigherOrderTasks(issueId: number, from: number, count: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueHigherOrderTasks((json as IJson)?.data)));
		return axios().get(`${basePath}/${issueId}/higherOrderTasks/from/${from}/count/${count}`);
	};
}

export function get_Issue_EquivalentTasks(issueId: number, from: number, count: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueEquivalentTasks((json as IJson)?.data)));
		return axios().get(`${basePath}/${issueId}/equivalentTasks/from/${from}/count/${count}`);
	};
}

export function issue_fetchNext(id: number, direction: EIssueDirection): AsyncAction {
	if (!id || id < 1 || !direction) throw new Error('System error');
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchNext((data as IJson).data)));
		return axios().get(`${basePath}/${id}/${direction}`);
	};
}
