import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	defaultPLN: ' PLN',
	full: '100%',
	value: 'value',
	strokeDasharray: '4 1',
	xAxisTick: { fontSize: '12px' },
	img: 'image/png',
	chartId: 'chart',
	png: '.png',
	number: 'number' as const,
	log: 'log' as const,
	name: 'name',
	category: 'category' as const,
};
export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerCharts: {
						customerTitlePercent: 'Podział budżetu na projekty w procentach',
						customerTitleTotal: 'Podział budżetu na projekty',
						customerDesc:
							'Przedstawienie graficzne podziału budżetów na poszczególne projekty należące do wybranego klienta.',
						totalBudget: 'Całkowity budżet klienta',
						customerLineDesc:
							'Przedstawienie graficzne podziału budżetów na poszczególne projekty w formie wykresu liniowego.',
						heading: 'Twój wykres się ładuje',
						description: 'Proszę, bądź cierpliwy, twoje dane są obliczane.',
						log: 'Skala logarytmiczna',
						linear: 'Skala liniowa',
						download: 'Pobierz',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerCharts: {
						customerTitlePercent: 'Percentage breakdown of the budget',
						customerTitleTotal: 'Breakdown of the budget',
						customerDesc: `Chart shows allocation of the client's budget.`,
						totalBudget: 'Total client budget',
						customerLineDesc: "Line chart shows allocation of the client's budget.",
						heading: 'Your chart is loading',
						description: 'Please be patient while your data is being calculated.',
						log: 'Log scale',
						linear: 'Linear scale',
						download: 'Download',
					},
				},
			},
		},
	},
};
