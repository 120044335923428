import { floor } from 'lodash';

export interface IInvoiceTemplateSummary {
	payed: number;
	leftToPay: number;
	currency: string;
	paymentOnAccount: string;
}

export const InvoiceTemplateSummary = (props: IInvoiceTemplateSummary): string => {
	return `
    <div class="invoice-summary">
      <p>
        Wystawca nie jest płatnikiem podatku VAT
        Art 113, ust. 1 lub 9
        USTAWY O PODATKU OD TOWARÓW I USŁUG
        DZ. U. 2011 nr 177 poz. 1054
      </p>
      <div>
        <div class="summary-table-wrapper">
          <table class="summary-table">
            <thead>
              <tr>
                <th>Payed</th>
                <th>Left to pay</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${floor(props.payed, 2).toFixed(2)}</td>
                <td>${floor(props.leftToPay, 2).toFixed(2)}</td>
                <td>${props.currency}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>Payment on account: ${props.paymentOnAccount}</p>
      </div>
    </div>
  `;
};
