import { Translation as DetailCalendarHeader } from './Event/Detail/CustomHeader/resources';
import { Translation as Detail } from './Event/Detail/resources';
import { Translation as List } from './Event/List/resources';
import { Translation as Main } from './resources';
import { Translation as DetailType } from './Type/Detail/resources';
import { Translation as ListType } from './Type/List/resources';

export const HolidayTranslationPL = [Main.pl, Detail.pl, List.pl, DetailType.pl, ListType.pl, DetailCalendarHeader.pl];

export const HolidayTranslationEN = [Main.en, Detail.en, List.en, DetailType.en, ListType.en, DetailCalendarHeader.en];
