import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModal: {
			IssueModalContent: {
				Tabs: {
					detailsTab: 'Szczegóły',
					subTasksTab: 'Zadania podrzędne',
					higherTasksTab: 'Zadania nadrzędne',
					equivalentTab: 'Zadania równorzędne',
					worklogsTab: 'Dziennik pracy',
					issueName: 'Nazwa zgłoszenia',
					project: 'Projekt',
					issueType: 'Typ zgłoszenia',
					issueProperties: 'Właściwości zgłoszenia',
				},
				IssueDetailsPage: {
					TabsContent: {
						Attachment: {
							deleteSuccess: 'Usunięto pomyślnie',
							uploadSuccess: 'Pomyślnie wrzucono pliki',
						},
						IssueComments: {
							Comments: {
								post: 'Wyślij',
								commentEditFailed: 'Dodanie komentarza zakończona niepowodzeniem, ponieważ: ',
								displayAllComments: 'Pokaż usunięte komentarze',
								advancedComment: 'Zaawansowany komentarz',
								portalPost: 'Wyślij na portal',
								comment: 'Komentarz',
								textIsToLong: 'Zbyt długi komentarz',
								commentAddSuccess: 'Pomyślnie dodano komentarz',
								commentDeleteSuccess: 'Usunięto komentarz',
								commentDeleteError: 'Błąd podczas usuwania',
								cancel: 'Anuluj',
								editTitle: 'Edytuj komentarz',
								edit: 'Edytuj',
								commentEditSuccess: 'Pomyślnie edytowano komentarz',
								noComments: 'Brak komentarzy',
								CommentCard: {
									commentEditFailed: 'Edycja komentarza zakończona niepowodzeniem, ponieważ: ',
									pinned: '(Przypięty)',
									Footer: {
										edit: 'Edytuj',
										delete: 'Usuń',
									},
								},
							},
						},
					},
					Tabs: {
						description: 'Opis zgłoszenia',
						comments: 'Komentarze',
						attachments: 'Załączniki',
					},
					RightSideIssueData: {
						ExtraIssueInfo: {
							creator: 'Twórca',
							time: 'Twój czas pracy',
							creationDate: 'Data stworzenia',
							assigned: 'Osoby wykonujące',
							observer: 'Obserwatorzy',
							reporter: 'Osoby zgłaszające',
							auditor: 'Osoby weryfikujące',
							projectChangeTooltip:
								'Zmiana projektu spowoduje zrestowanie wartośći w: typie formularza zgłoszeniowego oraz we właściowościach zgłoszenia',
							issueTypeChangeTooltip:
								'Zmiana typu zgłoszenia spowoduje zrestowanie wartośći we właściowościach zgłoszenia',
							issueType: 'Typ zgłoszenia',
							project: 'Projekt',
						},
					},
					MainIssueData: {
						BasicIssueDate: {
							issueNo: 'Numer zadania',
							issueName: 'Nazwa zgłoszenia',
							assigned: 'Osoby wykonujące',
							observer: 'Obserwatorzy',
							reporter: 'Osoby zgłaszające',
							auditor: 'Osoby weryfikujące',
							project: 'Projekt',
							issueType: 'Typ zgłoszenia',
						},
						DependsInput: {
							noDataInFields: 'Brak wartości w wymaganych polach',
							noType: 'Brak typu zgłoszenia',
							issueProperties: 'Właściwości zgłoszenia',
						},
					},
				},
				Worklogs: {
					date: 'Data',
					from: 'Czas rozpoczęcia',
					to: 'Czas zakończenia',
					user: 'Użytkownik',
					issue: 'Zgłoszenie',
					description: 'Opis',
					logged: 'Zalogowano',
					delete: 'Usuń',
					edit: 'Modyfikuj',
					workLogDelete: 'Usunięto wpis czasu pracy',
					errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
					worklogStillActive: 'Aktywne',
					worklogEdited: 'Wpis czasu pracy został zaktualizowany',
					worklogDeleted: 'Czas pracy został usunięty',
					worklogCreated: 'Dodano nowy czas pracy',
					worklogEditted: 'Edytowano czas pracy',
					error: 'Wystąpił błąd',
					onlyMine: 'Pokaż tylko mój czas pracy',
					addNewWorklog: 'Dodaj nowy czas pracy',
					CardHeader: {
						date: 'Data',
						from: 'Czas rozpoczęcia',
						to: 'Czas zakończenia',
						user: 'Użytkownik',
						issue: 'Zgłoszenie',
						description: 'Opis',
						logged: 'Zalogowano',
						actions: 'Akcje',
					},
					CardContent: {
						worklogStillActive: 'Aktywne',
						delete: 'Usuń',
						edit: 'Edytuj',
						workLogDelete: 'Usunięto wpis czasu pracy',
						errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
					},
					Form: {
						titleNewWorklog: 'Nowy czas pracy',
						titleUpdateWorklog: 'Edycja czasu pracy',
						issueName: 'Nazwa zgłoszenia',
						startDate: 'Data rozpoczęcia',
						endDate: 'Data zakończenia',
						time: 'Czas pracy w minutach',
						wantToSetEndDateUsingMinutes: 'Chcę podać czas pracy w minutach',
						desc: 'Opis',
						cancel: 'Anuluj',
						delete: 'Usuń',
						save: 'Zapisz',
						alert: 'Wprowadź poprawne dane formularza.',
					},
				},
				Relation: {
					children: 'Podzadanie',
					parent: 'Zadanie nadrzędne',
					equivalent: 'Zadanie równorzędne',
					cancel: 'Anuluj',
					link: 'Dodaj powiązanie',
					tile: {
						project: 'Projekt',
						customer: 'Klient',
					},
					error: 'Niewystarczające dane do stworzenia relacji',
					successSave: 'Zapisano relacje',
					moreRecords: 'Istnieje więcej rekordów aby je zobaczyć wpisz więcej znaków',
					addSubTaks: 'Dodaj pod-zadanie',
					relationDelete: 'Zadanie usunięte z listy',
					relationDeleteFail: 'Zadanie nie zostało usunięte z powodu błędu.',
					noIssues: 'Brak zadań',
					placeholder: 'Nazwa zgłoszenia',
					add: 'Dodaj',
					addIssue: 'Dodaj zadanie',
					noOptionsText: 'Brak zadań',
					useTableData: {
						name: 'Nazwa',
						project: 'Projekt',
						creationDate: 'Data stworzenia',
						action: 'Akcje',
						status: 'Status',
					},
				},
			},
			IssueModalTitle: {
				MoreOptions: {
					DeleteIssue: {
						delete: 'Usuń zgłoszenie',
						description: 'Wraz z usunięciem zgłoszenia zostaną usunięte wszystkie powiązane z nim dane.',
					},
					Archiv: {
						deleteArchive: 'Usuń archiwizacje zadania.',
						description:
							'Archiwizuj zgłoszenie na tym kanbanie ( Będzie ono niewidoczne chyba, że w filtrach zostanie włączony filtr pokazujący zgłoszenia)',
						archive: 'Archiwizuj',
						description2: 'Anuluj archiwizacje zgłoszenia na tym kanbanie. Bedzie ono ponownie widoczne',
					},
					SocialMediaLinks: {
						Form: {
							url: 'Adres URL',
							name: 'Nazwa',
							type: 'Typ',
							save: 'Zapisz',
							cancel: 'Anuluj',
						},
						SocialMediaLink: {
							delete: 'Usuń link',
							edit: 'Edytuj link',
							openInNewTab: 'Otwórz link w nowej karcie',
						},
						add: 'Dodaj nowy link',
						addAsFavourite: 'Zapisz jako ulubione zgłoszenie',
					},
					links: 'Linki',
					archive: 'Archiwizacja',
					archiveDescription: 'Zakładka dostępna z poziomu ekranu kanbanów.',
					deleteIssue: 'Usuń zgłoszenie',
				},
				newIssue: 'Nowe zgłoszenie',
				archive:
					'Archiwizuj zgłoszenie na tym kanbanie(Będzie ono niewidoczne chyba, że w filtrach zostanie włączony filtr pokazujący zgłoszenia)',
			},
			IssueModalActions: {
				FieldsRequiredIndicator: {
					fieldsRequired: 'Pola wymagane',
				},
				issueCreated: 'Zgłoszenie utworzone przez {{userName}} dnia {{createdAt}}',
				save: 'Zapisz',
				close: 'Zamknij',
				delete: 'Usuń',
				saveAndClose: 'Zapisz i zamknij',
			},
			Hooks: {
				UseInitialLoad: {
					error: 'Brak zgłoszenia lub brak uprawnień do wyświetlenia zgłoszenia.',
				},
			},
			dictionaryValueNull: 'Brak prawidłowej wartości',
			valueTrue: 'Prawda',
			valueFalse: 'Fałsz',
			deleteIssueSnackbarSuccess: 'Zgłoszenie usunięte',
			updateIssueSnackbarSuccess: 'Zgłoszenie zaktualizowane',
			confirmationTitle: 'Czy na pewno chcesz usunąć zgłoszenie ?',
			confirmationSubmitText: 'Tak, chcę usunąć',
			confirmationCancelText: 'Nie, anuluj',
			reqFields: 'Musisz dodać wymagane pole dla tego formularza',
			kanbanCreationError: 'Brak skonfigurowanych mapowań w kanbanie',
			kanbanCreationErrorSubtitle1: 'Zgłoszenie wyświetli się w kanbanie po skonfigurowaniu mapowań',
			kanbanCreationErrorSubtitle2:
				'Przejdź do edycji kanbana i skonfiguruj mapowania aby móc dodawać zadania do kolumny',
			limit: 'Obecne zgłoszenie jest najnowsze lub najstarsze.',
		},
	},
	en: {
		IssueModal: {
			IssueModalContent: {
				Tabs: {
					detailsTab: 'Details',
					subTasksTab: 'Sub-Tasks',
					higherTasksTab: 'Overarching tasks',
					equivalentTab: 'Equivalent tasks',
					worklogsTab: 'Worklogs',
					issueName: 'Issue name',
					project: 'Project',
					issueType: 'Issue type',
					issueProperties: 'Issue Properties',
				},
				IssueDetailsPage: {
					TabsContent: {
						Attachment: {
							deleteSuccess: 'Delete success',
							uploadSuccess: 'Upload success',
						},
						IssueComments: {
							Comments: {
								post: 'Post',
								commentEditFailed: 'Add of comment failure cause: ',
								displayAllComments: 'Show deleted comments',
								advancedComment: 'Advanced comment',
								portalPost: 'Post on portal',
								comment: 'Comment',
								textIsToLong: 'Comment is to long',
								commentAddSuccess: 'Comment successfully added',
								commentDeleteSuccess: 'Comment has been deleted',
								commentDeleteError: 'Error while deleting comment',
								cancel: 'Cancel',
								editTitle: 'Edit a comment',
								edit: 'Edit',
								commentEditSuccess: 'Comment succesfully edited',
								noComments: 'No comments available',
								CommentCard: {
									commentEditFailed: 'Edition of comment failure cause: ',
									pinned: '(Pinned)',
								},
								Footer: {
									edit: 'Edit',
									delete: 'Delete',
								},
							},
						},
					},
					Tabs: {
						description: 'Description',
						comments: 'Comments',
						attachments: 'Attachments',
					},
					RightSideIssueData: {
						ExtraIssueInfo: {
							creator: 'Creator',
							time: 'Your working time',
							creationDate: 'Creation Date',
							assigned: 'Assigned',
							observer: 'Observer',
							reporter: 'Reporter',
							auditor: 'Auditor',
							projectChangeTooltip:
								'Changing project will reset the values in: issue type and in the properties of the issue',
							issueTypeChangeTooltip:
								'Changing issue type will reset the values in properties of the issue',
							issueType: 'Issue type',
							project: 'Project',
						},
					},
					MainIssueData: {
						BasicIssueDate: {
							issueNo: 'Issue number',
							issueName: 'Issue name',
							assigned: 'Assigned',
							observer: 'Observer',
							reporter: 'Reporter',
							auditor: 'Auditor',
							project: 'Project',
							issueType: 'Issue type',
						},
						DependsInput: {
							noDataInFields: 'There are no values ​​in required fields',
							noType: 'There is no issue type',
							issueProperties: 'Issue Properties',
						},
					},
				},
				Worklogs: {
					onlyMine: 'Show only my time work',
					date: 'Date',
					from: 'From',
					to: 'To',
					user: 'User',
					issue: 'Issue',
					description: 'Description',
					logged: 'Logged',
					addNewWorklog: 'Add new worklog',
					delete: 'Delete',
					edit: 'Edit',
					workLogDelete: 'Worklog deleted',
					errorWorkLogDelete: 'Error while deleting worklog',
					worklogStillActive: 'Active',
					worklogEdited: 'Worklog has been updated',
					worklogDeleted: 'Worklog has been deleted',
					worklogCreated: 'New worklog has been added',
					worklogEditted: 'Worklogh has been editted',
					error: 'Error occured',
					CardHeader: {
						date: 'Date',
						from: 'From',
						to: 'To',
						user: 'User',
						issue: 'Issue',
						description: 'Description',
						logged: 'Logged',
						actions: 'Actions',
					},
					CardContent: {
						worklogStillActive: 'Active',
						delete: 'Delete',
						edit: 'Edit',
						workLogDelete: 'Worklog deleted',
						errorWorkLogDelete: 'Error while deleting worklog',
					},
					Form: {
						titleNewWorklog: 'New worklog',
						titleUpdateWorklog: 'Editing worklog',
						issueName: 'Issue name',
						startDate: 'Start date',
						endDate: 'End date',
						time: 'Time',
						wantToSetEndDateUsingMinutes: 'I want to specify working time in minutes',
						desc: 'Description',
						cancel: 'Cancel',
						delete: 'Delete',
						save: 'Save',
						alert: 'Please enter correct values.',
					},
				},
				Relation: {
					children: 'Subtask',
					parent: 'Parent issue',
					equivalent: 'Equivalent issue',
					cancel: 'Cancel',
					link: 'Link issue',
					tile: {
						project: 'Project',
						customer: 'Klient',
					},
					error: 'Not enough data to create connection',
					successSave: 'Relation saved',
					moreRecords: 'There is more records to find your please type more characters',
					relationDelete: 'Issue has been deleted from the list',
					relationDeleteFail: 'Issue has not been deleted because error occur.',
					noIssues: 'No issues',
					placeholder: 'Issue name',
					add: 'Add',
					addIssue: 'Add issue',
					noOptionsText: 'No issues',
					useTableData: {
						name: 'Name',
						project: 'Project',
						creationDate: 'Creation date',
						action: 'Actions',
						status: 'Status',
					},
				},
			},
			IssueModalTitle: {
				MoreOptions: {
					DeleteIssue: {
						delete: 'Delete issue',
						description: 'Along with removing issue all data related will be also deleted.',
					},
					Archiv: {
						deleteArchive: 'Delete issue archives.',
						description:
							'Archive the issue on this kanban (It will be invisible unless the filter showing is enabled in the filters)',
						archive: 'Archive',
						description2: 'Cancel archiving the issue on this kanban. This task will be visible again.',
					},
					SocialMediaLinks: {
						Form: {
							url: 'URL address',
							name: 'Name',
							type: 'Type',
							save: 'Save',
							cancel: 'Cancel',
						},
						SocialMediaLink: {
							delete: 'Delete link',
							edit: 'Edit link',
							openInNewTab: 'Open link in a new tab',
						},
						add: 'Add new link',
					},
					links: 'Links',
					archive: 'Archive',
					archiveDescription: 'Tab available from the kanban screen.',
					deleteIssue: 'Delete issue',
				},
				newIssue: 'New Issue',
				archive: 'Archive issue on this kanban(It will be invisible unless filter show archived will be on)',
				addAsFavourite: 'Save as favourite issue',
			},
			IssueModalActions: {
				FieldsRequiredIndicator: {
					fieldsRequired: 'Fields required',
				},
				issueCreated: 'Issue created by {{userName}} at {{createdAt}}',
				save: 'Save',
				close: 'Close',
				delete: 'Delete',
				saveAndClose: 'Save and close',
			},
			Hooks: {
				UseInitialLoad: {
					error: "You don't have permissions to this issue or there is no issue with this id.",
				},
			},
			dictionaryValueNull: 'There is lack of correct value.',
			valueTrue: 'True',
			valueFalse: 'False',
			deleteIssueSnackbarSuccess: 'Issue has been deleted',
			updateIssueSnackbarSuccess: 'Issue has been updated',
			confirmationTitle: 'Are you sure you want to delete this issue',
			confirmationSubmitText: 'Yes, I want to delete',
			confirmationCancelText: 'No, cancel',
			reqFields: 'You need to add required fields',
			kanbanCreationError: 'No mappings configured in kanban',
			kanbanCreationErrorSubtitle1: 'The issue will appear in kanban after configuring the mappings',
			kanbanCreationErrorSubtitle2:
				'Go to the kanban edition mode and configure the mappings to be able to add issues to the column',
			limit: 'You already opened new newest or the oldest issue.',
		},
	},
};

export const defaultConfirmationMessageData = {
	title: Resources.GlobalResources.empty,
};
