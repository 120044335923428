import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Checkbox, FormControlLabel } from '@mui/material';

import { IValidationErrorV2, IExtendedWorkLog, ITaskerUser, IWorkLog, Permission } from '@skillandchill/tasker-types';
import { Button, TaskerTable } from '@skillandchill/tasker-widgets2';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { work_log_create_new, work_log_delete, work_log_update_new, worklog_fetch_by_issue_NEW } from '@/store/actions';
import { IErrorV3 } from '@/store/ITypes';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { CheckRoles } from '@/utils/permissions';
import { errorVariant, successVariant } from '@/utils/snackBar';

import { Form } from './Form';
import { EWorklogInputName } from './Form/model';
import { WorklogDataMapper } from './Form/utils';
import { Resources } from './resources';
import { useStyles } from './styles';
import { useTableData } from './useTableData';

export const Worklogs = (): JSX.Element => {
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const snackBar = useSnackbar();
	const { t, tString } = useTrans('IssueModal.IssueModalContent.Worklogs');
	const { data, columns } = useTableData(changeWorkLog, handleDelete);

	const currentUserPermissions = useSelector((state: IReducerState) => state.Session?.permissions);
	const workLogsCountSelector: number = useSelector(
		(state: IReducerState) => state.IssueModalEditor.workLogByIssueCount
	);

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const darkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);
	const isLoading = useSelector((state: IReducerState) => state.Session.isLoading);

	const [displayMyIssues, setDisplayMyIssues] = useState<boolean>(true);
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage] = useState<number>(5);
	const [worklogInEdit, setWorklogInEdit] = useState<IWorkLog | null>(null);
	const [showFormToCreateNewWorklog, setShowFormToCreateNewWorklog] = useState(false);
	const [formErrors, setFormErrors] = useState<IValidationErrorV2[]>([]);
	const [isWorklogInEdit, setIsWorklogInEdit] = useState(false);

	const isFormVisible = useMemo(() => {
		return showFormToCreateNewWorklog || worklogInEdit;
	}, [showFormToCreateNewWorklog, worklogInEdit]);

	const havePermissionToUsers = useMemo(() => {
		return CheckRoles(currentUserPermissions, Permission.ChangeUserInTimeReport);
	}, []);

	useEffect(() => {
		const from = page * rowsPerPage;
		if (issue?.Id) {
			dispatch(worklog_fetch_by_issue_NEW(issue?.Id, from, rowsPerPage, displayMyIssues));
		}
	}, [page, displayMyIssues, isFormVisible, activeWorklogs]);

	function changeWorkLog(workLog: IWorkLog) {
		setWorklogInEdit(workLog);
		setIsWorklogInEdit(true);
	}

	function onDeleteSuccess() {
		setPage(0);
		handleHideForm();
		snackBar.enqueueSnackbar(t('worklogDeleted'), successVariant);
	}

	function onDeleteFails() {
		snackBar.enqueueSnackbar(t('error'), errorVariant);
	}

	function handleDelete(workLogId: number) {
		const data = {
			count: rowsPerPage,
			from: 0,
			searchValue: Resources.GlobalResources.empty,
			showOnlyUserWorklogs: displayMyIssues,
			issueId: issue.Id,
		};

		dispatch(work_log_delete(workLogId, data))
			.then(onDeleteSuccess)
			.catch(onDeleteFails);
	}

	const handleHideForm = () => {
		setShowFormToCreateNewWorklog(false);
		setWorklogInEdit(null);
		setFormErrors([]);
		setIsWorklogInEdit(false);
	};

	const handleOpenForm = () => {
		setShowFormToCreateNewWorklog(true);
	};

	const onAddWorklogSuccess = () => {
		snackBar.enqueueSnackbar(t('worklogCreated'), successVariant);
		handleHideForm();
	};

	const onAddWorklogFails = (error: IErrorV3) => {
		setFormErrors(WorklogDataMapper.toWorklogFormErrors(error?.response?.data?.message));
		snackBar.enqueueSnackbar(t('error'), errorVariant);
	};

	const handleCreateWorklog = (workLog: Partial<IExtendedWorkLog>) => {
		const data = {
			...workLog,
			TaskerUserId: taskerUser.Id,
			IssueId: issue.Id,
		};

		dispatch(work_log_create_new(data))
			.then(onAddWorklogSuccess)
			.catch(onAddWorklogFails);
	};

	const onUpdateWorklogSuccess = () => {
		snackBar.enqueueSnackbar(t('worklogEdited'), successVariant);
		handleHideForm();
	};

	const onUpdateWorklogFails = (error: IErrorV3) => {
		setFormErrors(WorklogDataMapper.toWorklogFormErrors(error?.response?.data?.message));
	};

	const handleUpdateWorklog = (workLog: Partial<IExtendedWorkLog>) => {
		const data = { ...workLog, TaskerUserId: taskerUser.Id, IssueId: issue.Id };
		dispatch(work_log_update_new(workLog.Id as number, data))
			.then(onUpdateWorklogSuccess)
			.catch(onUpdateWorklogFails);
	};

	const handleHideFormError = (inputName: EWorklogInputName) => {
		setFormErrors(prevState => prevState.filter(error => error.inputName !== inputName));
	};

	return (
		<div className={classes.root}>
			<VisibilityControl
				condition={!isFormVisible}
				alternative={
					<Form
						handleHideForm={handleHideForm}
						handleSave={worklogInEdit ? handleUpdateWorklog : handleCreateWorklog}
						initialWorklogFormValues={WorklogDataMapper.toWorklogForm(worklogInEdit)}
						formErrors={formErrors}
						handleHideFormError={handleHideFormError}
						handleDelete={handleDelete}
						isWorklogInEdit={isWorklogInEdit}
					/>
				}
			>
				<div className={classes.buttonsContainer}>
					<Button
						variant={Resources.GlobalResources.contained}
						size={Resources.GlobalResources.small}
						className={classes.addWorklogButton}
						onClick={handleOpenForm}
					>
						<AddCircleOutlineRoundedIcon />
						{t('addNewWorklog')}
					</Button>
				</div>
				<VisibilityControl condition={havePermissionToUsers}>
					<FormControlLabel
						label={tString('onlyMine')}
						className={classes.checkbox}
						control={
							<Checkbox
								color={Resources.GlobalResources.primary}
								onChange={(event, checked) => setDisplayMyIssues(checked)}
								checked={displayMyIssues}
							/>
						}
					/>
				</VisibilityControl>
				<div className={classes.tableContainer}>
					<TaskerTable<IWorkLog>
						data={data}
						columns={columns}
						isLoading={isLoading}
						paginationProps={{
							listCount: Number.isNaN(workLogsCountSelector) ? 0 : workLogsCountSelector,
							currentPage: page + 1,
							rowsPerPage,
							onPageChangeCallBack: selected => setPage(selected - 1),
						}}
						isDarkMode={darkMode}
					/>
				</div>
			</VisibilityControl>
		</div>
	);
};
