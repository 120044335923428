import clsx from 'clsx';
import { addHours, format } from 'date-fns';
import { useContext } from 'react';

import { Typography } from '@mui/material';

import { EFieldDefinitionType, IFieldValue } from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { CustomDatePicker } from '@skillandchill/tasker-widgets2';
import { EDateFNS } from '@skillandchill/tasker-widgets2/dist/utils';

import { EValueToUse, IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const DateIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;
	const initialFieldValue = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const initialInputValue = initialFieldValue?.length ? (initialFieldValue[0]?.ValueDate as Date) : null;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialValue = initial?.length ? (initial[0]?.ValueDate as Date) : undefined;

	const newValue = state && (state[EFieldDefinitionType.Date][AttributeDefinitionId] as Date | undefined);

	const value = newValue ?? initialValue ?? null;

	function getNonEditValue() {
		if (initialInputValue) return format(new Date(initialInputValue), EDateFNS.DateWithoutTime);
		else return '-';
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.dateContainer)}>
				<CustomDatePicker
					label=""
					value={value}
					handleChange={val =>
						handleChange && handleChange(addHours(val as Date, 1), issueFormSectionToIssueField)
					}
					inEdit={inEdit}
					canSetInputInNonEdit
					nonEditComponent={<Typography className={classes.valueContainer}>{getNonEditValue()}</Typography>}
					hideErrorMessage
				/>
			</div>
		</div>
	);
};
