import { addDays, endOfMonth, format } from 'date-fns';
import { enUS, pl } from 'date-fns/locale';
import _ from 'lodash';

import { DateInput } from '@fullcalendar/core';

import { EFormats } from '@skillandchill/tasker-widgets2/dist/utils/date';

export interface IDateRange {
	start: string;
	end: string;
}

export interface IDateRangeDate {
	start: Date;
	end: Date;
}

const SEVEN_DAYS_DIFFERENCE = 7;
const THIRTY_DAYS_DIFFERENCE = 30;

export const calculateDateForCustomRanges = (
	date: IDateRange,
	dateDiff: number,
	setDiff: (number: number) => void
): IDateRange => {
	const dateRangeInDate = {
		start: new Date(date.start),
		end: new Date(date.end),
	};

	const dateToConditionOne = endOfMonth(_.cloneDeep(dateRangeInDate.end));
	const dateToConditionTwo = addDays(dateToConditionOne, -1);

	if (
		format(dateRangeInDate.end, EFormats.EDateFNS.FormatDash) ==
			format(dateToConditionOne, EFormats.EDateFNS.FormatDash) ||
		format(dateRangeInDate.end, EFormats.EDateFNS.FormatDash) ==
			format(dateToConditionTwo, EFormats.EDateFNS.FormatDash)
	) {
		return calculateDateForCustomRanges(
			{
				start: date.start,
				end: format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.FormatDash),
			},
			dateDiff + 1,
			setDiff
		);
	}

	if (dateDiff % 7 == 0) {
		return calculateDateForCustomRanges(
			{
				start: date.start,
				end: format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.FormatDash),
			},
			dateDiff + 1,
			setDiff
		);
	}

	if (
		format(dateRangeInDate.start, EFormats.EDateFNS.OnlyDay) ==
		format(dateRangeInDate.end, EFormats.EDateFNS.OnlyDay)
	) {
		return calculateDateForCustomRanges(
			{
				start: date.start,
				end: format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.FormatDash),
			},
			dateDiff + 1,
			setDiff
		);
	}

	if (
		format(dateRangeInDate.start, EFormats.EDateFNS.OnlyDay) ==
			format(dateRangeInDate.end, EFormats.EDateFNS.OnlyDay) ||
		format(dateRangeInDate.start, EFormats.EDateFNS.OnlyDay) ==
			format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.OnlyDay) ||
		format(addDays(dateRangeInDate.start, -1), EFormats.EDateFNS.OnlyDay) ==
			format(dateRangeInDate.end, EFormats.EDateFNS.OnlyDay)
	) {
		return calculateDateForCustomRanges(
			{
				start: date.start,
				end: format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.FormatDash),
			},
			dateDiff + 1,
			setDiff
		);
	}

	if ((dateDiff - 34) % 7 === 0) {
		return calculateDateForCustomRanges(
			{
				start: date.start,
				end: format(addDays(dateRangeInDate.end, 1), EFormats.EDateFNS.FormatDash),
			},
			dateDiff + 1,
			setDiff
		);
	}

	setDiff(dateDiff);
	return date;
};

export const dayGridCustomColumnHeaderHTML = (date: { date: Date }, dateDiff: number): string => {
	const currentDate = new Date(date.date);
	if (dateDiff >= THIRTY_DAYS_DIFFERENCE) {
		return Number.parseInt(format(currentDate, EFormats.EDateFNS.OnlyDay)) == 1
			? getSpanAsText(format(currentDate, EFormats.EDateFNS.GantMonthDayFormat))
			: '';
	} else if (dateDiff > SEVEN_DAYS_DIFFERENCE)
		return getSpanAsText(format(currentDate, EFormats.EDateFNS.GantMonthDayFormat));
	else if (dateDiff <= SEVEN_DAYS_DIFFERENCE)
		return getSpanAsText(format(currentDate, EFormats.EDateFNS.DayNameFormat));
	return '';
};

export const getSpanAsText = (value: string): string => {
	return `<span class="column-header-week">${value}</span>`;
};

export const calendarChangeHTMLHeaderBasic = (date: DateInput): string => {
	const language: Locale = navigator.language == 'PL-PL' || navigator.language == 'pl' ? pl : enUS;
	return getSpanAsText(format(new Date(date.toString()), EFormats.EDateFNS.DayNameFormat, { locale: language }));
};
