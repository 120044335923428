import uuid from 'uuid';

import { IDelegationDictionaryItem } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IModalData } from '@/views/DelegationsList/DelegationModal/model';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
	getTransportTypeKey: (item: IDelegationDictionaryItem) => `Transport_${item?.Id}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							OriginEndRoute: {
								legend: 'Koniec delegacji',
								dateTimePickerLabel: 'Data przyjazdu',
								locationInputLabel: 'Miejsce przyjazdu',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							OriginEndRoute: {
								legend: 'Origin return',
								dateTimePickerLabel: 'Arrival date',
								locationInputLabel: 'Arrival location',
							},
						},
					},
				},
			},
		},
	},
};

export const getLegendSuffix = (modalData: IModalData) => {
	const location = modalData?.delegationDetails?.originEnd?.location?.displayName;
	return location ? ` - ${location}` : Resources.GlobalResources.empty;
};
