import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	reorderColumns: 'reorderColumns',
	droppable1: 'droppable1',
	selectProps: {
		menuPosition: 'fixed',
		isSearchable: true,
		closeMenuOnSelect: false,
		closeMenuOnLoseFocus: true,
		valuesLimit: 5,
	},
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								nameLabel: 'Nazwa kanbana',
								kanban: 'Konfiguracja kanbana',
								save: 'Zapisz',
								projects: 'Projekty',
								columns: 'Kolumny',
								deleteColumnConfirmation: 'Czy na pewno chcesz usunąć kolumnę kanbana?',
								addColumn: 'Dodaj kolumnę',
								kanbanSubtext: 'Tutaj możesz edytować nazwę kanbana, projekty oraz kolumny kanbana',
								general: 'Ogólne',
								Errors: {
									Name: 'Nazwa musi zawierać od 1 do 100 znaków',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								nameLabel: 'Kanban name',
								kanban: 'Kanban configuration',
								projects: 'Projects',
								columns: 'Columns',
								deleteColumnConfirmation: 'Are you sure you want to delete this kanban column?',
								addColumn: 'Add column',
								kanbanSubtext: 'Here You can edit kanban name, projects and columns of the kanban',
								general: 'General',
								Errors: {
									Name: 'Name should be between 1 to 100 characters',
								},
							},
						},
					},
				},
			},
		},
	},
};

export const getValidationRules = () => {
	return {
		Name: {
			presence: {
				maximum: 100,
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.TabsContent.GeneralTab.Errors.Name'),
			},
		},
	};
};
