import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	swimlineSpeedDial: {
		transform: 'rotate(0deg)',
		width: '24px',
		height: '24px',
		display: 'flex',
		padding: '3px',
	},
	swimlinesContainer: 'swimlinesContainer',
	swimlinesId: 'swimline:id:',
	jumpToSwimline: 'jumpToSwimline',
	smoothBehavior: 'smooth' as const,
	menuId: 'account-menu',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlineConfiguration: {
							swimlineMenu: 'Menu swimeline',
							swimlineConfiguration: 'Konfiguracja swimline',
							swimlineCollapseIn: 'Zwiń wszystkie swimeliny',
							swimlineCollapseOut: 'Rozwiń wszystkie swimeliny',
							swimlineBarCollapseIn: 'Zwiń pasek swimline',
							swimlineBarCollapseOut: 'Rozwiń pasek swimline',
							scrollToSwimeline: 'Przewiń do swimelina',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlineConfiguration: {
							swimlineMenu: 'Swimeline menu ',
							swimlineConfiguration: 'Swimeline configuration',
							swimlineCollapseIn: 'Collapse all swimelines',
							swimlineCollapseOut: 'Expand all swimelines',
							swimlineBarCollapseIn: 'Shrink swimline bar',
							swimlineBarCollapseOut: 'Expand swimline bar',
							scrollToSwimeline: 'Scroll to swimeline',
						},
					},
				},
			},
		},
	},
};
