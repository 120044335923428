import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	emptyEndWorkDayForm: {
		FormName: '',
		IssueFormSection: [],
		DiscordMessage: undefined,
		TaskerUsers: undefined,
	},
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					keyLabel: 'Klucz',
					firstStep: 'Konfiguracja formularza',
					secondStep: 'Konfiguracja powiadomień',
					thirdStep: 'Przypisywanie użytkowników',
					finish: 'Zakończ',
					next: 'Dalej',
					back: 'Wróć',
					formCardTitle: 'Formularz zgłoszeniowy',
					pageTitle: 'Formularz',
					Header: {
						formPropertiesTitle: 'Konfiguracja właściwości formularza',
						subTitleNew: 'Nowy formularz',
					},
					createForm: 'Stwórz formularz',
					saveForm: 'Zapisz',
					cancel: 'Anuluj',
					addGroupIcon: 'Dodaj grupę',
					addRecord: 'Dodaj rekord',
					youShallNotPass: 'Aby przejść dalej musisz uzupełnić ten krok',
					youShallNotPassSame: 'Niemożna przejść na ten sam krok',
					Info: {
						optional: 'Krok opcjonalny',
						form: 'Skonfiguruj pytania na które osoba będzie musiała odpowiedzieć.',
						notification: `Skonfiguruj zapytanie, które powiadomi serwer zewnętrzny.`,
						users: 'Wybierz użytkowników, którzy będą musieli wypełniać formularz.',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					keyLabel: 'Key',
					firstStep: 'Configure form',
					secondStep: 'Configure notification',
					thirdStep: 'Assign users',
					finish: 'Finish',
					next: 'Next',
					back: 'Back',
					formCardTitle: 'Issue form',
					pageTitle: 'Form',
					Header: {
						formPropertiesTitle: 'Configuration form properties',
						subTitleNew: 'New form',
					},
					createForm: 'Create form',
					saveForm: 'Save',
					cancel: 'Cancel',
					addGroupIcon: 'Dodaj grupę',
					addRecord: 'Add record',
					youShallNotPass: 'In case of going forward you have to complete this step.',
					youShallNotPassSame: 'Can not go to same step as is current on',
					Info: {
						optional: 'Optional step',
						form: 'Configure question which users will have to answer.',
						notification: 'Configure request to outsource server.',
						users: 'Choose users who will have fill the form.',
					},
				},
			},
		},
	},
};
