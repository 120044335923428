import { Translation as GeneralCardContentCustom } from './General/GeneralSettings/CardContentCustom/resources';
import { Translation as GeneralSettings } from './General/GeneralSettings/resources';
import { Translation as Actions } from './General/ProfileDetails/Actions/resources';
import { Translation as SocialMediaActions } from './General/ProfileDetails/AddSocialMediaCard/Actions/resources';
import { Translation as AddSocialMediaCard } from './General/ProfileDetails/AddSocialMediaCard/resources';
import { Translation as CardContentCustom } from './General/ProfileDetails/CardContentCustom/resources';
import { Translation as ProfileDetails } from './General/ProfileDetails/resources';
import { Translation as General } from './General/resources';
import { Translation as WorkingDetails } from './General/WorkingDetails/resources';
import { Translation as PreferencesOrg } from './Organization/resources';
import { Translation as Preferences } from './Preferences/resources';
import { Translation as Tile } from './Preferences/Tile/resources';
import { Translation as Settings } from './resources';
import { Translation as CollapseChangePasswordContent } from './Security/CollapseChangePasswordContent/resources';
import { Translation as CollapseContent } from './Security/CollapseContent/resources';
import { Translation as Security } from './Security/resources';
import { Translation as NewTeamButton } from './WidgetsConfigurationMain/ProfileSelectorConfiguration/NewTeamButton/resources';
import { Translation as PopupContentTeam } from './WidgetsConfigurationMain/ProfileSelectorConfiguration/PopupContent/resources';
import { Translation as ProfileSelectorConfiguration } from './WidgetsConfigurationMain/ProfileSelectorConfiguration/resources';
import { Translation as Team } from './WidgetsConfigurationMain/ProfileSelectorConfiguration/Team/resources';
import { Translation as TeamOptionsButton } from './WidgetsConfigurationMain/ProfileSelectorConfiguration/Team/TeamOptionsButton/resources';
import { Translation as WidgetConfiguration } from './WidgetsConfigurationMain/resources';
import { Translation as ProfileSelectorConfigurationTabs } from './WidgetsConfigurationMain/Tabs/resources';

export const SettingsTranslationEN = [
	Preferences.en,
	Settings.en,
	Security.en,
	CollapseChangePasswordContent.en,
	CollapseContent.en,
	GeneralCardContentCustom.en,
	GeneralSettings.en,
	Actions.en,
	SocialMediaActions.en,
	AddSocialMediaCard.en,
	CardContentCustom.en,
	ProfileDetails.en,
	General.en,
	WorkingDetails.en,
	NewTeamButton.en,
	PopupContentTeam.en,
	TeamOptionsButton.en,
	Team.en,
	ProfileSelectorConfiguration.en,
	ProfileSelectorConfigurationTabs.en,
	PreferencesOrg.en,
	Tile.en,
	WidgetConfiguration.en,
];

export const SettingsTranslationPL = [
	Preferences.pl,
	Settings.pl,
	Security.pl,
	CollapseChangePasswordContent.pl,
	CollapseContent.pl,
	GeneralCardContentCustom.pl,
	GeneralSettings.pl,
	Actions.pl,
	SocialMediaActions.pl,
	AddSocialMediaCard.pl,
	CardContentCustom.pl,
	ProfileDetails.pl,
	General.pl,
	WorkingDetails.pl,
	NewTeamButton.pl,
	PopupContentTeam.pl,
	TeamOptionsButton.pl,
	Team.pl,
	ProfileSelectorConfiguration.pl,
	ProfileSelectorConfigurationTabs.pl,
	PreferencesOrg.pl,
	Tile.pl,
	WidgetConfiguration.pl,
];
