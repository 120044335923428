import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: 'issueForm_Main_Section',
	dash: '_',
	path: '/issueForms',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						EditComponent: {
							form: 'Formularz',
							title: 'Konfiguracja zakończenia dnia pracy',
							description:
								'Edytuj formularz, powiadomienia oraz użytkowników w konfiguracji zakończenia dnia pracy',
							editConfiguration: 'Edytuj konfigurację',
							notifications: 'Powiadomienia',
							users: 'Użytkownicy',
							saveChanges: 'Zapisz zmiany',
							back: 'Wstecz',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						EditComponent: {
							form: 'Form',
							title: 'End work day configuration',
							description: 'Edit the form, notifications, and users in the end-of-day configuration',
							editConfiguration: 'Edit configuration',
							notifications: 'Notifications',
							users: 'Users',
							saveChanges: 'Save changes',
							back: 'Go back',
						},
					},
				},
			},
		},
	},
};
