import { Translation as CommentConfiguration } from './CommentConfiguration/resources';
import { Translation as AddGroup } from './Form/IssueForm/CommonComponents/AddGroup/resources';
import { Translation as Record } from './Form/IssueForm/CommonComponents/IssueFormField/DictionaryModal/Record/resources';
import { Translation as DictionaryModal } from './Form/IssueForm/CommonComponents/IssueFormField/DictionaryModal/resources';
import { Translation as FieldDefinitionClockConfiguration } from './Form/IssueForm/CommonComponents/IssueFormField/FieldDefinitionClockConfiguration/resources';
import { Translation as IssueFormField } from './Form/IssueForm/CommonComponents/IssueFormField/resources';
import { Translation as SubHeader } from './Form/IssueForm/CommonComponents/SubHeader/resources';
import { Translation as TextFieldWithLabel } from './Form/IssueForm/CommonComponents/TextFieldWithLabel/resources';
import { Translation as EditIssueFormGroup } from './Form/IssueForm/Edit/IssueFormGroup/resources';
import { Translation as Edit } from './Form/IssueForm/Edit/resources';
import { Translation as IssueFormGroup } from './Form/IssueForm/New/IssueFormGroup/resources';
import { Translation as New } from './Form/IssueForm/New/resources';
import { Translation as ChooseProject } from './Form/IssueFormList/ChooseProject/resources';
import { Translation as IssueFormList } from './Form/IssueFormList/resources';
import { Translation as ProjectCharts } from './Overview/ProjectCharts/resources';
import { Translation as ProjectCreatedLabel } from './Overview/ProjectInfo/GeneralLeft/ProjectCreatedLabel/resources';
import { Translation as GeneralLeft } from './Overview/ProjectInfo/GeneralLeft/resources';
import { Translation as GeneralRight } from './Overview/ProjectInfo/GeneralRight/resources';
import { Translation as MultiValueContainer } from './Overview/ProjectInfo/ProjectOwnerManager/MultiValueContainer/resources';
import { Translation as ProjectOwnerManager } from './Overview/ProjectInfo/ProjectOwnerManager/resources';
import { Translation as ProjectInfo } from './Overview/ProjectInfo/resources';
import { Translation as ColumnHeaderAvatar } from './Overview/ProjectMembers/ColumnHeaderAvatar/resources';
import { Translation as ProjectMembers } from './Overview/ProjectMembers/resources';
import { Translation as CardHeaderTitle } from './Overview/UsersInProject/CardHeaderTitle/resources';
import { Translation as ProjectBudgetShow } from './ProjectBudgetShow/resources';
import { Translation as CustomConfirmationMessage } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/CustomConfirmationMessage/resources';
import { Translation as PopupActions } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/PopupActions/resources';
import { Translation as Filters } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/ProjectRolesSelection/Filters/resources';
import { Translation as ProjectRolesSelection } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/ProjectRolesSelection/resources';
import { Translation as RolesActionsCell } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/ProjectRolesSelection/SingleUser/ActionsCell/resources';
import { Translation as AddUsersToProjectPopupContent } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/resources';
import { Translation as StepperContainer } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/StepperContainer/resources';
import { Translation as SelectionActionsCell } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/UsersToAddSelection/ActionsCell/resources';
import { Translation as UsersToAddSelection } from './ProjectMembersConfiguration/AddUsersToProjectPopupContent/UsersToAddSelection/resources';
import { Translation as SingleProject } from './ProjectMembersConfiguration/CommonCells/UserActiveProjectsCell/SingleProject/resources';
import { Translation as UserCell } from './ProjectMembersConfiguration/CommonCells/UserCell/resources';
import { Translation as Placeholder } from './ProjectMembersConfiguration/CommonCells/UserProjectRoles/Placeholder/resources';
import { Translation as ProjectMembersUserProjectRoles } from './ProjectMembersConfiguration/CommonCells/UserProjectRoles/resources';
import { Translation as ValueContainer } from './ProjectMembersConfiguration/CommonCells/UserProjectRoles/ValueContainer/resources';
import { Translation as UserTaskerRolesCell } from './ProjectMembersConfiguration/CommonCells/UserTaskerRolesCell/resources';
import { Translation as ActionsCell } from './ProjectMembersConfiguration/ProjectUsers/ProjectUser/ActionsCell/resources';
import { Translation as ProjectUsers } from './ProjectMembersConfiguration/ProjectUsers/resources';
import { Translation as TableOptions } from './ProjectMembersConfiguration/ProjectUsers/TableOptions/resources';
import { Translation as ProjectMembersConfiguration } from './ProjectMembersConfiguration/resources';
import { Translation as HeaderSuffix } from './ProjectNesting/HeaderSuffix/resources';
import { Translation as RemoveIcon } from './ProjectNesting/ProjectList/RemoveIcon/resources';
import { Translation as ProjectList } from './ProjectNesting/ProjectList/resources';
import { Translation as AvatarAndCreator } from './ProjectNesting/ProjectList/TileComponents/AvatarAndCreator/resources';
import { Translation as DetailsButton } from './ProjectNesting/ProjectList/TileComponents/DetailsButton/resources';
import { Translation as ProjectManager } from './ProjectNesting/ProjectList/TileComponents/ProjectManager/resources';
import { Translation as UsersInProject } from './ProjectNesting/ProjectList/TileComponents/UsersInProject/resources';
import { Translation as ProjectNesting } from './ProjectNesting/resources';
import { Translation as ProjectUsersActiveTasksList } from './ProjectUsersActiveTasksList/resources';
import { Translation as UserActiveTask } from './ProjectUsersActiveTasksList/UserActiveTask/resources';
import { Translation as ProjectDetails } from './resources';
import { Translation as TabsContent } from './TabsContent/resources';

export const ProjectDetailsTranslationEN = [
	ProjectBudgetShow.en,
	UserCell.en,
	ProjectCharts.en,
	Filters.en,
	ProjectRolesSelection.en,
	RolesActionsCell.en,
	AddUsersToProjectPopupContent.en,
	StepperContainer.en,
	UsersToAddSelection.en,
	SelectionActionsCell.en,
	Placeholder.en,
	SingleProject.en,
	ValueContainer.en,
	ActionsCell.en,
	ProjectUsers.en,
	TableOptions.en,
	ProjectMembersConfiguration.en,
	PopupActions.en,
	CustomConfirmationMessage.en,
	ProjectDetails.en,
	TabsContent.en,
	ProjectUsersActiveTasksList.en,
	UserActiveTask.en,
	ProjectCreatedLabel.en,
	GeneralLeft.en,
	GeneralRight.en,
	ProjectOwnerManager.en,
	ProjectInfo.en,
	ColumnHeaderAvatar.en,
	ProjectMembers.en,
	CardHeaderTitle.en,
	IssueFormGroup.en,
	New.en,
	ChooseProject.en,
	IssueFormList.en,
	Edit.en,
	EditIssueFormGroup.en,
	TextFieldWithLabel.en,
	SubHeader.en,
	IssueFormList.en,
	DictionaryModal.en,
	Record.en,
	AddGroup.en,
	IssueFormField.en,
	CommentConfiguration.en,
	HeaderSuffix.en,
	RemoveIcon.en,
	ProjectList.en,
	AvatarAndCreator.en,
	DetailsButton.en,
	ProjectManager.en,
	UsersInProject.en,
	ProjectNesting.en,
	ProjectMembersUserProjectRoles.en,
	MultiValueContainer.en,
	UserTaskerRolesCell.en,
	FieldDefinitionClockConfiguration.en,
];

export const ProjectDetailsTranslationPL = [
	ProjectBudgetShow.pl,
	UserCell.pl,
	ProjectCharts.pl,
	Filters.pl,
	ProjectRolesSelection.pl,
	RolesActionsCell.pl,
	AddUsersToProjectPopupContent.pl,
	StepperContainer.pl,
	UsersToAddSelection.pl,
	SelectionActionsCell.pl,
	Placeholder.pl,
	SingleProject.pl,
	ValueContainer.pl,
	ActionsCell.pl,
	ProjectUsers.pl,
	TableOptions.pl,
	ProjectMembersConfiguration.pl,
	PopupActions.pl,
	CustomConfirmationMessage.pl,
	ProjectDetails.pl,
	TabsContent.pl,
	ProjectUsersActiveTasksList.pl,
	UserActiveTask.pl,
	ProjectCreatedLabel.pl,
	GeneralLeft.pl,
	GeneralRight.pl,
	ProjectOwnerManager.pl,
	ProjectInfo.pl,
	ColumnHeaderAvatar.pl,
	ProjectMembers.pl,
	CardHeaderTitle.pl,
	IssueFormGroup.pl,
	New.pl,
	ChooseProject.pl,
	IssueFormList.pl,
	Edit.pl,
	EditIssueFormGroup.pl,
	TextFieldWithLabel.pl,
	SubHeader.pl,
	IssueFormList.pl,
	DictionaryModal.pl,
	Record.pl,
	AddGroup.pl,
	IssueFormField.pl,
	CommentConfiguration.pl,
	HeaderSuffix.pl,
	RemoveIcon.pl,
	ProjectList.pl,
	AvatarAndCreator.pl,
	DetailsButton.pl,
	ProjectManager.pl,
	UsersInProject.pl,
	ProjectNesting.pl,
	ProjectMembersUserProjectRoles.pl,
	MultiValueContainer.pl,
	UserTaskerRolesCell.pl,
	FieldDefinitionClockConfiguration.pl,
];
