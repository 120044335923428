import { IDelegationAttachment } from '@/views/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/model';

import { calculateTableRows } from '../utils';

import { DelegationTemplateBase } from './base';
import {
	DelegationTemplateExpensesSummary,
	DelegationTemplateExpensesTable,
	IDelegationTemplateExpensesRow,
	IDelegationTemplateExpensesSummary,
} from './expenses';
import {
	DelegationTemplateExpensesHeading,
	DelegationTemplatePerdiemHeading,
	IDelegationTemplateExpensesHeading,
	IDelegationTemplatePerdiemHeading,
} from './heading';
import {
	DelegationTemplatePerdiemSummary,
	DelegationTemplatePerdiemTable,
	IDelegationTemplatePerdiemRow,
	IDelegationTemplatePerdiemSummary,
} from './perdiem';
import { DelegationTemplateStyles } from './styles';

export interface IDelegationTemplate {
	expensesHeading: IDelegationTemplateExpensesHeading;
	expenses: IDelegationTemplateExpensesRow[];
	expensesSummary: IDelegationTemplateExpensesSummary;
	perDiemsHeading: IDelegationTemplatePerdiemHeading;
	perDiems: IDelegationTemplatePerdiemRow[];
	perDiemsSummary: IDelegationTemplatePerdiemSummary;
}

export const DelegationTemplate = (props: IDelegationTemplate): string[] => {
	let remainingExpenses = props.expenses;
	let remainingPerDiems = props.perDiems;
	const pages = [];
	let pageIdx = 1;

	const prepExpensesHeadingPage = DelegationTemplateBase(
		{
			title: `Raport PDF ${props.expensesHeading.Name}`,
			styles: DelegationTemplateStyles(),
			children: `
      ${DelegationTemplateExpensesHeading(props.expensesHeading)}
      ${DelegationTemplateExpensesTable({ data: remainingExpenses })}
    `,
		},
		pageIdx
	);
	const expensesHeadingPageRows = calculateTableRows(prepExpensesHeadingPage, '#report-table', 450);
	let expensesHeadingPage;

	if (expensesHeadingPageRows.rowsCount === remainingExpenses.length) {
		// Modify the number value is summary is being cropped
		if (expensesHeadingPageRows.sumHeight <= 400) {
			expensesHeadingPage = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.expensesHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
					${DelegationTemplateExpensesHeading(props.expensesHeading)}
					${DelegationTemplateExpensesTable({ data: remainingExpenses })}
					${DelegationTemplateExpensesSummary(props.expensesSummary)}
				`,
				},
				pageIdx
			);
			remainingExpenses = remainingExpenses.slice(expensesHeadingPageRows.rowsCount);
		} else {
			expensesHeadingPage = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.expensesHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
        ${DelegationTemplateExpensesHeading(props.expensesHeading)}
        ${DelegationTemplateExpensesTable({ data: remainingExpenses.slice(0, -1) })}
    `,
				},
				pageIdx
			);
			remainingExpenses = remainingExpenses.slice(expensesHeadingPageRows.rowsCount - 1);
		}
	} else {
		expensesHeadingPage = DelegationTemplateBase(
			{
				title: `Raport PDF ${props.expensesHeading.Name}`,
				styles: DelegationTemplateStyles(),
				children: `
				${DelegationTemplateExpensesHeading(props.expensesHeading)}
				${DelegationTemplateExpensesTable({
					data: remainingExpenses,
					from: 0,
					to: expensesHeadingPageRows.rowsCount,
				})}
			`,
			},
			pageIdx
		);
		remainingExpenses = remainingExpenses.slice(expensesHeadingPageRows.rowsCount);
	}
	pages.push(expensesHeadingPage);
	pageIdx++;

	while (remainingExpenses.length > 0) {
		const prepPage = DelegationTemplateBase(
			{
				title: `Raport PDF ${props.expensesHeading.Name}`,
				styles: DelegationTemplateStyles(),
				children: `
				${DelegationTemplateExpensesTable({ data: remainingExpenses })}
			`,
			},
			pageIdx
		);

		const pageRows = calculateTableRows(prepPage, '#report-table', 800);
		let page;

		if (pageRows.rowsCount === remainingExpenses.length) {
			if (pageRows.sumHeight <= 750) {
				page = DelegationTemplateBase(
					{
						title: `Raport PDF ${props.expensesHeading.Name}`,
						styles: DelegationTemplateStyles(),
						children: `
						${DelegationTemplateExpensesTable({ data: remainingExpenses })}
						${DelegationTemplateExpensesSummary(props.expensesSummary)}
					`,
					},
					pageIdx
				);
				remainingExpenses = remainingExpenses.slice(pageRows.rowsCount);
			} else {
				page = DelegationTemplateBase(
					{
						title: `Raport PDF ${props.expensesHeading.Name}`,
						styles: DelegationTemplateStyles(),
						children: `
						${DelegationTemplateExpensesTable({ data: remainingExpenses.slice(0, -1) })}
					`,
					},
					pageIdx
				);
				remainingExpenses = remainingExpenses.slice(pageRows.rowsCount - 1);
			}
		} else {
			page = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.expensesHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
					${DelegationTemplateExpensesTable({
						data: remainingExpenses,
						from: 0,
						to: pageRows.rowsCount,
					})}
				`,
				},
				pageIdx
			);
			remainingExpenses = remainingExpenses.slice(pageRows.rowsCount);
		}
		pages.push(page);
		pageIdx++;
	}

	const prepPerDiemsHeadingPage = DelegationTemplateBase(
		{
			title: `Raport PDF ${props.perDiemsHeading.Name}`,
			styles: DelegationTemplateStyles(),
			children: `
			${DelegationTemplatePerdiemHeading(props.perDiemsHeading)}
			${DelegationTemplatePerdiemTable({ data: remainingPerDiems })}
		`,
		},
		pageIdx
	);
	const perDiemHeadingPageRows = calculateTableRows(prepPerDiemsHeadingPage, '#perdiem-table', 500);
	let perDiemHeadingPage;

	if (perDiemHeadingPageRows.rowsCount === remainingPerDiems.length) {
		if (perDiemHeadingPageRows.sumHeight <= 450) {
			perDiemHeadingPage = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.expensesHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
					${DelegationTemplatePerdiemHeading(props.perDiemsHeading)}
					${DelegationTemplatePerdiemTable({ data: remainingPerDiems })}
					${DelegationTemplatePerdiemSummary(props.perDiemsSummary)}
				`,
				},
				pageIdx
			);
			remainingPerDiems = remainingPerDiems.slice(perDiemHeadingPageRows.rowsCount);
		} else {
			perDiemHeadingPage = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.expensesHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
					${DelegationTemplatePerdiemHeading(props.perDiemsHeading)}
					${DelegationTemplatePerdiemTable({ data: remainingPerDiems.slice(0, -1) })}
				`,
				},
				pageIdx
			);
			remainingPerDiems = remainingPerDiems.slice(perDiemHeadingPageRows.rowsCount - 1);
		}
	} else {
		perDiemHeadingPage = DelegationTemplateBase(
			{
				title: `Raport PDF ${props.expensesHeading.Name}`,
				styles: DelegationTemplateStyles(),
				children: `
				${DelegationTemplatePerdiemHeading(props.perDiemsHeading)}
				${DelegationTemplatePerdiemTable({
					data: remainingPerDiems,
					from: 0,
					to: perDiemHeadingPageRows.rowsCount,
				})}
			`,
			},
			pageIdx
		);
		remainingPerDiems = remainingPerDiems.slice(perDiemHeadingPageRows.rowsCount);
	}
	pages.push(perDiemHeadingPage);
	pageIdx++;

	while (remainingPerDiems.length > 0) {
		const prepPage = DelegationTemplateBase(
			{
				title: `Raport PDF ${props.perDiemsHeading.Name}`,
				styles: DelegationTemplateStyles(),
				children: `
				${DelegationTemplatePerdiemTable({ data: remainingPerDiems })}
			`,
			},
			pageIdx
		);

		const pageRows = calculateTableRows(prepPage, '#perdiem-table', 800);
		let page;

		if (pageRows.rowsCount === remainingPerDiems.length) {
			if (pageRows.sumHeight <= 750) {
				page = DelegationTemplateBase(
					{
						title: `Raport PDF ${props.perDiemsHeading.Name}`,
						styles: DelegationTemplateStyles(),
						children: `
						${DelegationTemplatePerdiemTable({ data: remainingPerDiems })}
						${DelegationTemplatePerdiemSummary(props.perDiemsSummary)}
					`,
					},
					pageIdx
				);
				remainingPerDiems = remainingPerDiems.slice(pageRows.rowsCount);
			} else {
				page = DelegationTemplateBase(
					{
						title: `Raport PDF ${props.perDiemsHeading.Name}`,
						styles: DelegationTemplateStyles(),
						children: `
						${DelegationTemplatePerdiemTable({ data: remainingPerDiems.slice(0, -1) })}
					`,
					},
					pageIdx
				);
				remainingPerDiems = remainingPerDiems.slice(pageRows.rowsCount - 1);
			}
		} else {
			page = DelegationTemplateBase(
				{
					title: `Raport PDF ${props.perDiemsHeading.Name}`,
					styles: DelegationTemplateStyles(),
					children: `
					${DelegationTemplatePerdiemTable({
						data: remainingPerDiems,
						from: 0,
						to: pageRows.rowsCount,
					})}
				`,
				},
				pageIdx
			);
			remainingPerDiems = remainingPerDiems.slice(pageRows.rowsCount);
		}
		pages.push(page);
		pageIdx++;
	}

	return pages;
};
