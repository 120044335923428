import clsx from 'clsx';
import parse from 'html-react-parser';
import React, { useMemo } from 'react';

import StarIcon from '@mui/icons-material/Star';
import { Tooltip, Typography } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { Resources } from '../DictionaryIssueProperty/resources';

import { Props } from './model';
import { useStyles } from './styles';

export const IssuePropertyTitle = (props: Props) => {
	const { issueFormSectionToIssueField } = props;
	const classes = useStyles();

	const IsMandatory = useMemo(() => {
		return !!issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory;
	}, [issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory]);

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<VisibilityControl condition={!!IsMandatory}>
				<StarIcon className={classes.starIcon} />
			</VisibilityControl>
			<Tooltip
				title={
					<div>
						<Typography className={classes.tooltipText}>
							{issueFormSectionToIssueField?.AttributeDefinition?.Name ?? Resources.GlobalResources.empty}
						</Typography>
						<Typography variant={Resources.GlobalResources.body2} className={classes.tooltipText}>
							{parse(
								issueFormSectionToIssueField?.AttributeDefinition?.DescriptionHtml ??
									Resources.GlobalResources.empty
							)}
						</Typography>
					</div>
				}
			>
				<Typography className={classes.text}>
					{issueFormSectionToIssueField?.AttributeDefinition?.Name ?? Resources.GlobalResources.empty}
				</Typography>
			</Tooltip>
		</div>
	);
};
