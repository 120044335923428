import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			dropzoneInfo: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '1.5rem 0',
				marginBottom: '0.75rem',
				marginTop: '0.75rem',
			},
			top: {
				marginBottom: '0.75rem',
				marginTop: '0rem',
			},
			bottom: {
				marginBottom: '0rem',
				marginTop: '0.75rem',
			},
			dropzoneInfoIcon: {
				height: '2.5rem',
				width: '2.5rem',
			},

			dropzoneInfoText: {
				color: theme.palette.lightGray.main,
			},
		};
	},
	{
		name: `DelegationList_DelegationModal_DelegationModalContent_DelegationDetails_DelegationAttachments_Dropzone_DropzoneInfo`,
	}
);
