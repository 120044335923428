import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@skillchill/redux-promisify';

import {
	FullFilters,
	IKanban,
	IKanbanData,
	IKanbanIssueList,
	IKanbanWithClock,
	IProject,
	ITaskerUser,
} from '@skillandchill/tasker-types';
import {
	IIssueToSwimlineToOrder,
	IKanbanSwimline,
	IMapTagToFieldDefinition,
	ISwimlineConfiguration,
} from '@skillandchill/tasker-types/dist/types';
import { IKanbanCreate, IKanbanUpdate } from '@skillandchill/tasker-types/dist/types/endPoints/kanban';

import { IJson } from '@/store/ITypes';
import { KanbanTileDropData } from '@/store/reducers/New/kanbanReducer';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IOldColumnsCounts } from '../../../temporaryInterfaces';

export const FETCH_ALL_KANBANS: ActionType = 'FETCH_ALL_KANBANS';
export const FETCH_ONE_KANBAN: ActionType = 'FETCH_ONE_KANBAN';
export const FETCH_KANBAN_ISSUES: ActionType = 'FETCH_KANBAN_ISSUES';
export const SET_KANBAN_ISSUES: ActionType = 'SET_KANBAN_ISSUES';
export const SET_KANBAN_TAGS: ActionType = 'SET_KANBAN_TAGS';
export const DELETE_KANBAN: ActionType = 'DELETE_KANBAN';
export const DELETE_ISSUE_FROM_KANBAN: ActionType = 'DELETE_ISSUE_FROM_KANBAN';
export const SET_KANBAN_COLLAPSE: ActionType = 'SET_KANBAN_COLLAPSE';
export const RESET_CLOCK_LISTENER: ActionType = 'RESET_CLOCK_LISTENER';
export const KANBAN_ISSUE_DID_CHANGE: ActionType = 'KANBAN_ISSUE_DID_CHANGE';
export const KANBAN_USERS_GET: ActionType = 'KANBAN_USERS_GET';
export const FETCH_PROJECTS_WITH_FORMS: ActionType = 'FETCH_PROJECTS_WITH_FORMS';
export const FETCH_FORM_MAPPINGS: ActionType = 'FETCH_FORM_MAPPINGS';
export const SET_KANBAN_EDIT_MAPPINGS: ActionType = 'SET_KANBAN_EDIT_MAPPINGS';
export const FETCH_SWIMLINES_FOR_KANBAN: ActionType = 'FETCH_SWIMLINES_FOR_KANBAN';
export const DELETE_SWIMLINE: ActionType = 'DELETE_SWIMLINE';
export const CLEAR_ISSUES: ActionType = 'CLEAR_ISSUES';
export const SET_KANBAN_SWIMLINE_ISSUE_COUNTS: ActionType = 'SET_KANBAN_SWIMLINE_ISSUE_COUNTS';
export const SET_ISSUE_IDS_WAITING_FOR: ActionType = 'SET_ISSUE_IDS_WAITING_FOR';
export const SET_DROP_RESULT: ActionType = 'SET_DROP_RESULT';
export const FETCH_SWIMLINES_WITH_CONFIGURATIONS: ActionType = 'FETCH_SWIMLINES_WITH_CONFIGURATIONS';
export const SET_NEW_USER_CONFIGURATION: ActionType = 'SET_NEW_USER_CONFIGURATION';
export const KANBAN_LITE_MODE_CHANGE: ActionType = 'KANBAN_LITE_MODE_CHANGE';
export const SET_SWIMLINE_COLLAPSE: ActionType = 'SET_SWIMLINE_COLLAPSE';

const baseKanbanPath = 'Kanban';
const baseSwimlineConfigurationPath = 'swimlineConfiguration';
const baseMapPath = 'mapTagToFieldDefinition';
const baseSwimlinePath = 'Swimline';

export function resetClockListener(): Action {
	return { type: RESET_CLOCK_LISTENER };
}
function _fetchAllKanbans(kanbans: IKanban[]): Action {
	return {
		type: FETCH_ALL_KANBANS,
		kanbans,
	};
}

function _fetchOneKanban(kanban: IKanban): Action {
	return {
		type: FETCH_ONE_KANBAN,
		kanban,
	};
}
function _deleteKanban(kanbans: IKanban[]): Action {
	return {
		type: DELETE_KANBAN,
		kanbans,
	};
}

function _fetchKanbanIssues(data: IKanbanData, from: number, swimlineId: number): Action {
	return {
		type: FETCH_KANBAN_ISSUES,
		data,
		from,
		swimlineId,
	};
}

export function _setKanbanIssues(data: { [x: number]: IKanbanIssueList[] }): Action {
	return {
		type: SET_KANBAN_ISSUES,
		data,
	};
}

export function deleteIssueFromKanban(issueId: number, swimlineId: number, columnTagId: number): Action {
	return {
		type: DELETE_ISSUE_FROM_KANBAN,
		issueId,
		swimlineId,
		columnTagId,
	};
}

export function _kanbanIssueDidChange(data: IKanbanWithClock): Action {
	return {
		type: KANBAN_ISSUE_DID_CHANGE,
		data,
	};
}

export function _setKanbanSwimlineIssueCounts(data: { [x: number]: { [x: number]: number } }): Action {
	return {
		type: SET_KANBAN_SWIMLINE_ISSUE_COUNTS,
		data,
	};
}
export function _setIssueIdsWaitingFor(data: number, isAdd: boolean): Action {
	return {
		type: SET_ISSUE_IDS_WAITING_FOR,
		data,
		isAdd,
	};
}
export function _setDropResult(result: KanbanTileDropData | undefined): Action {
	return {
		type: SET_DROP_RESULT,
		result,
	};
}

export function _getProjectsWithForms(projectsWithForms: IProject[]): Action {
	return {
		type: FETCH_PROJECTS_WITH_FORMS,
		projectsWithForms,
	};
}

export function _getFormMappings(mappings: Partial<IMapTagToFieldDefinition>[]): Action {
	return {
		type: FETCH_FORM_MAPPINGS,
		mappings,
	};
}

export function _deleteSwimline(swimlineId: number): Action {
	return {
		type: DELETE_SWIMLINE,
		swimlineId,
	};
}

export function _clearIssues(): Action {
	return {
		type: CLEAR_ISSUES,
	};
}

export function fetchAllKanbans(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllKanbans((json as IJson)?.data)));
		return axios().get(baseKanbanPath);
	};
}

export function _fetchSwimlinesWithConfigurations(swimlines: IKanbanSwimline[]): Action {
	return {
		type: FETCH_SWIMLINES_WITH_CONFIGURATIONS,
		swimlines,
	};
}

export function _setSwimlineCollapse(kanbanId: number, swimlineId: number, value: boolean): Action {
	return {
		type: SET_SWIMLINE_COLLAPSE,
		kanbanId,
		swimlineId,
		value,
	};
}
export function _setNewUserConfiguration(user: Partial<ITaskerUser>): Action {
	return {
		type: SET_NEW_USER_CONFIGURATION,
		user,
	};
}

function _changeKanbanLiteMode(configuration: Partial<ISwimlineConfiguration>): Action {
	return {
		type: KANBAN_LITE_MODE_CHANGE,
		configuration,
	};
}

const kanbanRequestTokens = {} as { [x: number]: { from: number; cancelToken: CancelTokenSource } };

export function fetchKanbanIssues(
	kanbanId: number,
	swimlineId: number,
	from: number,
	count: number,
	oldColumnCounts: IOldColumnsCounts[] | undefined,
	filters: FullFilters
): AsyncAction {
	if (kanbanRequestTokens[swimlineId]?.from == from && kanbanRequestTokens[swimlineId]?.cancelToken) {
		kanbanRequestTokens[swimlineId]?.cancelToken?.cancel();
	}
	return function(context, dispatch) {
		const cancelToken = Axios.CancelToken.source();
		kanbanRequestTokens[swimlineId] = { from, cancelToken };

		context.then(json => {
			dispatch(_fetchKanbanIssues((json as IJson)?.data, from, swimlineId));
		});
		return axios().post(`${baseKanbanPath}/getKanbanIssues/${kanbanId}/${swimlineId}/${from}/${count}`, {
			oldColumnCounts,
			filters,
		});
	};
}

export function fetchOneKanban(id: number): AsyncAction {
	if (id === undefined) throw new Error('Kanban has no Id');
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneKanban((json as IJson).data)));
		return axios().get(`${baseKanbanPath}/${id}`);
	};
}

export function createKanban(kanban: IKanbanCreate, swimlines: Partial<IKanbanSwimline>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneKanban((json as IJson).data)));
		return axios().post(baseKanbanPath, { kanban, swimlines });
	};
}

export function updateKanbanMappings(
	mappings: Partial<IMapTagToFieldDefinition>[],
	kanbanId: number,
	formId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_getFormMappings((json as IJson)?.data?.mappings));
		});
		return axios().patch(`${baseKanbanPath}/mappings/${kanbanId}/${formId}`, { mappings });
	};
}

export function updateKanbanGeneral(kanban: IKanbanUpdate, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchOneKanban((json as IJson)?.data?.kanban));
		});
		return axios().patch(`${baseKanbanPath}/general/${id}`, { kanban });
	};
}

export function dragIssue(
	issueId: number,
	columnTagId: number,
	issueToSwimlineToOrder: Partial<IIssueToSwimlineToOrder>[],
	isSameColumn?: boolean,
	mappingIds?: number[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_kanbanIssueDidChange((json as IJson).data)));
		return axios().post(`${baseKanbanPath}/changeIssueFieldValue/${columnTagId}/${issueId}/${isSameColumn}`, {
			issueToSwimlineToOrder,
			mappingIds,
		});
	};
}

export function deleteKanban(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteKanban((json as IJson).data)));
		return axios().delete(`${baseKanbanPath}/${id}`);
	};
}

export function getProjectsWithForms(projectIds: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getProjectsWithForms((json as IJson).data)));
		return axios().post(`Project/includeForms`, projectIds);
	};
}

export function getMappingsByFormInKanban(formId: number, kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getFormMappings((json as IJson).data)));
		return axios().get(`mapTagToFieldDefinition/byFormInKanban/${formId}/${kanbanId}`);
	};
}

export function fetchKanbanCounters(kanbanId: number, filters: FullFilters): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setKanbanSwimlineIssueCounts((json as IJson).data)));
		return axios().post(`${baseKanbanPath}/counters/${kanbanId}`, filters);
	};
}

export function update_Swimlines(data: Partial<IKanbanSwimline>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSwimlinesWithConfigurations((json as IJson).data)));
		return axios().patch(baseSwimlinePath, data);
	};
}

export function delete_Swimline(swimlineId: number, kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteSwimline(swimlineId)));
		return axios().delete(`${baseSwimlinePath}/${swimlineId}/${kanbanId}`);
	};
}

export function generateMappingsByName(
	kanbanId: number,
	forAll: boolean,
	onlyMissing: boolean,
	formId?: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getFormMappings((json as IJson).data)));
		return axios().post(`${baseMapPath}/generateMappingsByName`, { kanbanId, forAll, onlyMissing, formId });
	};
}

export function createOrUpdateSwimlineConfiguration(
	swimlineConfiguration: Partial<ISwimlineConfiguration>,
	kanbanId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchSwimlinesWithConfigurations((json as IJson).data));
		});
		return axios().post(`${baseSwimlineConfigurationPath}/${kanbanId}`, { ...swimlineConfiguration });
	};
}

export function controlSwimlineCollapse(kanbanId: number, swimlineId: number, value: boolean): AsyncAction {
	return function(context, dispatch) {
		context.then(() => {
			dispatch(_setSwimlineCollapse(kanbanId, swimlineId, value));
		});
		return axios().post(`${baseSwimlineConfigurationPath}/${kanbanId}/${swimlineId}`, { value });
	};
}

export function changeKanbanLiteMode(value: boolean): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_changeKanbanLiteMode((json as IJson).data));
		});
		return axios().patch(`${baseSwimlineConfigurationPath}/${value}`);
	};
}

export function fetchSwimlinesForConfiguration(kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchSwimlinesWithConfigurations((json as IJson).data));
		});
		return axios().get(`${baseSwimlinePath}/configuration/${kanbanId}`);
	};
}
