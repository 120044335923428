import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		PortalList: {
			Filters: {
				filterButton: { button: 'Filter', tooltip: 'Filtruj' },
			},
		},
	},
	en: {
		PortalList: {
			Filters: { filterButton: { button: 'Filter', tooltip: 'Filter' } },
		},
	},
};
