import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		icon: { fontSize: '15px' },
		counter: {
			padding: theme.spacing(0.3),
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			borderRadius: theme.spacing(0.666666666666667),
		},
		counterText: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			fontSize: theme.spacing(0.9375),
		},
	}),
	{ name: `ProjectDetails_ProjectMembersConfiguration_CommonCells_UserProjectRoles_ValueContainer` }
);
