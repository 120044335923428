import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		container: {
			alignItems: 'center',
			marginTop: theme.spacing(1),
		},

		subText: {
			textAlign: 'center',
			marginTop: theme.spacing(1),
		},

		deleteArchiv: {
			backgroundColor: theme.palette.red.main,
		},
	}),
	{ name: `IssueModal.IssueModalTitle.MoreOptions.DeleteIssue` }
);
