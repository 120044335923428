import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Typography } from '@mui/material';

import { ITag } from '@skillandchill/tasker-types';

import { get_singleTagGroupWithTags } from '@/store/actions';
import { update_FieldValue } from '@/store/actions/issueListActions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { getInitialFilterNew } from 'view/SearchBar/resources';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Tag = (props: Props) => {
	const { label, fieldValue, color, filters = getInitialFilterNew(), from, count, issueId } = props;
	const classes = useStyles({ tagColor: color });
	const dispatch: Dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const tags: ITag[] = useSelector((state: IReducerState) => state?.IssueList?.tags);

	useEffect(() => {
		if (open === true) {
			dispatch(get_singleTagGroupWithTags(fieldValue?.ValueTag?.TagGroupId as number));
		}
	}, [open]);

	const onClick = (tag: ITag) => {
		dispatch(update_FieldValue({ ...fieldValue, ValueTagId: tag?.Id }, issueId, filters, from, count)).finally(() =>
			handleClose()
		);
	};

	const onClickStatusTag: MouseEventHandler<HTMLDivElement> = e => {
		if (e) setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<div onClick={onClickStatusTag} className={classes.fullWidth}>
				<div className={classes.tag} style={{ backgroundColor: color }}>
					<Typography className={classes.tagText}>{label}</Typography>
				</div>
			</div>
			<Menu
				id={Resources.menuId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				MenuListProps={{
					[Resources.ariaLabelledby]: Resources.basicButton,
				}}
				className={classes.menu}
				anchorOrigin={{
					vertical: Resources.GlobalResources.bottom,
					horizontal: Resources.GlobalResources.right,
				}}
				transformOrigin={{
					vertical: Resources.GlobalResources.top,
					horizontal: Resources.GlobalResources.right,
				}}
			>
				{tags?.map(x => {
					return (
						<MenuItem
							key={Resources.key(x?.Id)}
							onClick={() => onClick(x)}
							className={classes.menuItemWrapper}
						>
							<div style={{ backgroundColor: x?.TagColor }} className={classes.menuItem}>
								<Typography
									className={classes.tagText}
									style={{ color: x?.TagColor, filter: 'invert(100%)' }}
								>
									{x?.Label}
								</Typography>
							</div>
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};
