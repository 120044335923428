import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		popover: {
			padding: theme.spacing(2),
		},
		margin: {
			marginTop: theme.spacing(2),
		},
		border: {
			backgroundColor: theme.palette.background.default,
			borderRadius: '3px',
			border: '1px solid #b4b4b4',
			boxShadow: '0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset',
			color: theme.palette.darkGray.dark,
			display: 'inline-block',
			fontWeight: 700,
			lineHeight: 1,
			padding: theme.spacing(0.25, 0.5),
			margin: theme.spacing(0.5),
			whiteSpace: 'nowrap',
		},
	}),
	{ name: `Dashboard_HotkeysPopover` }
);
