import { AsyncAction } from '@skillchill/redux-promisify';

import { IOrganizationParameter, IOrganizationSetting } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'OrganizationSetting';

export const EDIT_ORGANIZATION_SETTING: ActionType = 'EDIT_ORGANIZATION_SETTING';
export const GET_ORGANIZATION_SETTING: ActionType = 'GET_ORGANIZATION_SETTING';
export const GET_ORGANIZATION_SETTING_FOR_DELEGATION: ActionType = 'GET_ORGANIZATION_SETTING_FOR_DELEGATION';
export const GET_DISCORD: ActionType = 'GET_DISCORD';
export const SET_DISCORD_SETTINGS: ActionType = 'SET_DISCORD_SETTINGS';

function edit_OrganizationSetting(data: IOrganizationSetting[]): Action {
	return {
		type: EDIT_ORGANIZATION_SETTING,
		data,
	};
}
function get_OrganizationSetting(data: IOrganizationSetting[]): Action {
	return {
		type: GET_ORGANIZATION_SETTING,
		data,
	};
}

function get_OrganizationSettingForDelegation(data: IOrganizationParameter[]): Action {
	return {
		type: GET_ORGANIZATION_SETTING_FOR_DELEGATION,
		data,
	};
}

function _get_Discord(data: IOrganizationParameter[]): Action {
	return {
		type: GET_DISCORD,
		data,
	};
}

function _setDiscordSettings(): Action {
	return {
		type: SET_DISCORD_SETTINGS,
	};
}

export function editOrganizationSetting(setting: Partial<IOrganizationSetting>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(edit_OrganizationSetting((json as IJson)?.data)));
		return axios().post(basePath, setting);
	};
}

export function getOrganizationSetting(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(get_OrganizationSetting((json as IJson)?.data)));
		return axios().get(basePath);
	};
}

export function getOrganizationSettingForDelegation(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(get_OrganizationSettingForDelegation((json as IJson)?.data)));
		return axios().get(`${basePath}/delegations`);
	};
}

export function getDiscord(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_get_Discord((json as IJson)?.data)));
		return axios().get(`${basePath}/Discord/all`);
	};
}

export function saveDiscordSettings(settings: IOrganizationParameter[]): AsyncAction {
	return function(context, dispatch) {
		context.then(_ => dispatch(_setDiscordSettings()));
		return axios().post(`${basePath}/Discord/Many`, settings);
	};
}
