import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	discordPath: 'integrations#discord',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						EditComponent: {
							EditComponentNotificationPart: {
								channelId: 'Id kanału',
								message: 'Wiadomość',
								header: 'Nagłówek',
								useUserAsHeader: 'Użyj użytkownika jako nagłówek',
								noConfiguration: 'Brak integracji discorda',
								goToConfiguration: 'Przejdź do konfiguracji',
								noConfigurationSubtitle:
									'Skonfiguruj  integracje z serwerem discord aby móc wysyłać tam powiadomienia',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						EditComponent: {
							EditComponentNotificationPart: {
								channelId: 'Channel id',
								message: 'Message',
								header: 'Header',
								useUserAsHeader: 'Use user as header',
								noConfiguration: 'Discord configuration missing',
								goToConfiguration: 'Go to configuration',
								noConfigurationSubtitle:
									'Configure the integration with the Discord server to be able to send notifications there',
							},
						},
					},
				},
			},
		},
	},
};
