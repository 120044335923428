import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultColor: '#33b35c' as const,
	startPosition: 'start' as const,
	propertyField: {
		label: 'Label' as const,
		imageLink: 'ImageLink' as const,
		color: 'TagColor' as const,
	},
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								AddColumn: {
									toLongLink: 'Link do zdjęcia jest za długi',
									labelName: 'Etykieta',
									emptyLabel: 'Etykieta nie może być pusta',
									imageLink: 'Link do zdjęcia',
									color: 'Kolor',
									wrongColorFormat: 'Niepoprawny format koloru',
									save: 'Zapisz',
									cancel: 'Anuluj',
									labelErrorMessage: '^Podaj nazwę kolumny!',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								AddColumn: {
									toLongLink: 'Image link is to long',
									labelName: 'Label',
									emptyLabel: 'Label cannot be empty',
									imageLink: 'Image link',
									color: 'Color',
									wrongColorFormat: 'Invalid color format',
									save: 'Save',
									cancel: 'Cancel',
									labelErrorMessage: '^Enter name of the column!',
								},
							},
						},
					},
				},
			},
		},
	},
};

export const validationRules = {
	Label: { presence: { allowEmpty: false, message: t('Kanban.AddColumn.labelErrorMessages') } },
};
