import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@skillandchill/tasker-widgets2/dist/utils/theme';

export const useStyles = makeStyles(
	(theme: CustomTheme) => {
		return {
			...getGlobalStyles(),
			innerWrapper: { '&:not(:first-child)': { marginTop: theme.spacing(3) } },
			issuePropertiesInnerContainer: { marginLeft: theme.spacing(0) },
			container: { marginLeft: theme.spacing(3) },
			errorText: { color: theme.palette.red.main },
			noIssueTypeText: {
				fontWeight: '500',
				color: theme.palette.grey[400],
			},
			noIssueType: {
				alignItems: 'center',
				marginTop: theme.spacing(3),
			},
			noIssueTypeIcon: {
				color: theme.palette.grey[500],
				fontSize: theme.spacing(3.75),
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_MainIssueData_DependsInput` }
);
