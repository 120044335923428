import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Hidden } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { LogOutButton } from '@/layouts/Dashboard/NavBar/DrawerContent/LogOutButton';
import { IReducerState } from '@/store/reducers/types';
import { NavBarWidth, NavBarWidthOnlyIcon } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';

import { NavBarSection as NavBarSectionFull } from '../../NavBarSection';

import { Props, StyleProps } from './model';
import { NavBarSection } from './NavBarSection';
import { Resources } from './resources';
import { useStyles } from './styles';

export const DrawerContent = (props: Props): JSX.Element => {
	const { className, sections, onLogOut, ...rest } = props;
	const { t } = useTrans('Dashboard.NavBar.DrawerContentResized');

	const navBarState = useSelector((state: IReducerState) => state.Common.sideBarOpen);

	const styleProps: StyleProps = { maxWidth: navBarState ? NavBarWidthOnlyIcon : NavBarWidth };
	const classes = useStyles(styleProps);

	return (
		<>
			<div
				{...rest}
				className={clsx(
					classes.root,
					className,
					classes.fullHeight,
					classes.flexColumn,
					classes.overflowHidden
				)}
			>
				<nav className={clsx(classes.navigation, classes.overflowXHidden, classes.flexGrow)}>
					{sections.map(item => (
						<VisibilityControl
							condition={navBarState}
							alternative={<NavBarSectionFull section={item} key={item.name} />}
							key={`${item.name}${Resources.VC}`}
						>
							<NavBarSection section={item} key={item.name} />
						</VisibilityControl>
					))}
				</nav>
				<Hidden mdUp>
					<LogOutButton onLogOut={onLogOut} logOutText={t('signOut')} />
				</Hidden>
			</div>
		</>
	);
};
