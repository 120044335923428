import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		timeBold: { fontWeight: 400 },
		header: { height: theme.spacing(2.5) },
		avatar: {
			margin: theme.spacing(0.625),
			height: theme.spacing(3.75),
			width: theme.spacing(3.75),
		},
		nickname: {
			margin: theme.spacing(0, 0.5),
			maxWidth: theme.spacing(40),
		},
	}),
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_TabsContent_IssueComments_CommendCard_Header` }
);
