import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		card: { overflow: 'unset' },
		buttonsContainer: { marginTop: theme.spacing(1) },
		noIssuesText: { marginTop: theme.spacing(2) },
		addRelationContainer: { marginTop: theme.spacing(2) },
		listContainer: { marginTop: theme.spacing(2) },
	}),
	{ name: `IssueModal_IssueModalContent_Relation` }
);
