export interface IDelegationTemplateBase {
	title: string;
	styles: string;
	children: string;
}

export const DelegationTemplateBase = (props: IDelegationTemplateBase, pageIdx: number): string => {
	return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet">
        <title>${props.title}</title>
        ${props.styles}
      </head>
      <body class="body">
        <div class="container">
          ${props.children}
          <div class="page-number">Page ${pageIdx}</div>
        </div>
      </body>
    </html>
  `;
};
