import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			container: {
				position: 'relative',
				width: '100%',
			},

			richEditor: {
				width: '100%',
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),

				height: '400px',

				'& .DraftEditor-editorContainer': {
					zIndex: 0,
				},

				'& .rdw-dropdown-wrapper': {
					zIndex: 2,
				},

				'& .rdw-editor-main': {
					backgroundColor: theme.palette.background.paper,
				},
				'& .rdw-image-alignment-options-popup': {
					backgroundColor: 'white',
					color: 'black',
				},
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_TabsContent_IssueDescription` }
);
