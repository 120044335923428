import clsx from 'clsx';
import React from 'react';
import { components } from 'react-select';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useStyles } from './styles';

export const ValueContainer = (props: any) => {
	const { children, hasValue, ...rest } = props;
	const valuesLimit = rest?.selectProps?.valuesLimit ?? 3;
	const classes = useStyles();

	if (!hasValue) {
		return (
			<components.ValueContainer hasValue={hasValue} {...rest}>
				{children}
			</components.ValueContainer>
		);
	}

	const [chips, otherChildren] = children;

	const firstThreeElements = Array.from(children?.[0])?.slice(0, valuesLimit);

	return (
		<components.ValueContainer {...rest}>
			{firstThreeElements}

			{otherChildren}
			<VisibilityControl condition={children?.[0]?.length > valuesLimit}>
				<div className={clsx(classes.flexYCenter, classes.counter)}>
					<AddIcon className={classes.icon} />
					<Typography className={classes.counterText}>{children?.[0]?.length - valuesLimit}</Typography>
				</div>
			</VisibilityControl>
		</components.ValueContainer>
	);
};
