import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	basicButtonId: 'basic-button' as const,
	defaultColor: '#FFFFFF',

	smoothBehavior: 'smooth' as const,
	droppableId: 'swimloneConfigurationDroppableId',
	virtual: 'virtual' as const,
	swimlineConfigurationList: 'swimlineConfigurationList',
	xSmall: 'xSmall' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlineConfiguration: {
							SwimlineConfigurationDrawer: {
								title: 'Konfiguracja swimline',
								subtext:
									'W tym miejscu możesz zarządzać swimlinami - ich widocznością oraz kolejnością wyświetlania',
								ifYouWantToConfigure: 'Jeśli chcesz konfigurować swimliny, ',
								useYourOwnConfiguration: 'użyj swojej konfiguracji',
								name: 'Nazwa',
								color: 'Kolor',
								creationDate: 'Data stworzenia',
								createdBy: 'Stworzone przez',
								youAreUsingConfiguration: 'Używasz konfiguracji użytkownika:',
								addSwimline: 'Dodaj Swimline',
								exit: 'Zamknij',
								save: 'Zapisz zmiany',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlineConfiguration: {
							SwimlineConfigurationDrawer: {
								title: 'Swimeline configuration',
								subtext: 'In this place, you can manage swimlines - their visibility and display order',
								ifYouWantToConfigure: 'If you want to configure the swimlines, ',
								useYourOwnConfiguration: 'use your own configuration',
								name: 'Name',
								color: 'Color',
								creationDate: 'Creation date',
								createdBy: 'Created by',
								youAreUsingConfiguration: 'You are using a configuration of user:',
								addSwimline: 'Add new swimline',
								save: 'Save changes',
								exit: 'Exit',
							},
						},
					},
				},
			},
		},
	},
};
