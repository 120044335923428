import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Tooltip } from '@mui/material';

import { LogOutButtonProps } from './model';
import { useStyles } from './styles';

export const LogOutButton = (props: LogOutButtonProps): JSX.Element => {
	const { onLogOut, logOutText } = props;
	const classes = useStyles();

	return (
		<Tooltip title={logOutText}>
			<Button onClick={onLogOut} className={classes.button}>
				<LogoutIcon />
			</Button>
		</Tooltip>
	);
};
