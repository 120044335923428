import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: '100%',
		},
		divider: {
			margin: '5px 0px',
		},

		headerDivider: {
			borderWidth: '1px',
			borderColor: theme.palette.grey[400],
		},

		tableContainer: {
			marginTop: theme.spacing(0),
		},

		buttonsContainer: {
			marginTop: theme.spacing(3),
		},

		addWorklogButton: {
			marginRight: theme.spacing(2),
		},

		checkbox: {
			marginTop: theme.spacing(3),
		},
		typographyCustom: {
			fontSize: '12px',
			alignSelf: 'center',
		},
		stillActiveText: {
			color: theme.palette.green.light,
		},
		avatar: {
			height: '30px',
			width: '30px',
			alignSelf: 'center',
		},
		desc: {
			fontSize: '12px',
			paddingRight: theme.spacing(3),
		},
	}),
	{ name: 'IssueModal_IssueModalContent_Worklogs' }
);
