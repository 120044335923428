import { addMinutes } from 'date-fns';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { EInputTypes, IIssue } from '@skillandchill/tasker-types';
import {
	Button,
	CustomDateTimePicker,
	CustomInput,
	CustomSelect,
	VisibilityControl,
} from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { EWorklogInputName, IWorklogForm, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';
import { WorklogDataMapper } from './utils';

export const Form = (props: Props) => {
	const {
		handleHideForm,
		handleSave,
		initialWorklogFormValues,
		formErrors,
		handleHideFormError,
		handleDelete,
		isWorklogInEdit,
	} = props;
	const classes = useStyles();
	const { t, tString } = useTrans('IssueModal.IssueModalContent.Worklogs.Form');

	const [wantToSetEndDateWithMinutes, setWantTosetEndDateWithMinutes] = useState(false);
	const [formState, setFormState] = useState<Partial<IWorklogForm>>({});
	const [disableInputs, setDisableInputs] = useState<boolean>(false);

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const isDarkMode = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const handleChange = (inputName: EWorklogInputName, value: Date | number | string | null | IIssue) => {
		setFormState(prevState => ({
			...prevState,
			[inputName]: value,
		}));

		handleHideFormError(inputName);
	};

	useEffect(() => {
		if (!initialWorklogFormValues) return;
	}, [initialWorklogFormValues]);

	const dateDidChangeBasedOnMinutes = (minutes: number | string) => {
		if (!minutes) return;
		const minutesInNumber: number = Number.parseInt(minutes.toString());

		setFormState(prevState => {
			const oldMinutes = prevState?.Minutes ?? 0;
			const deferentMinutes = minutesInNumber - oldMinutes;
			const newDated: Date = addMinutes(prevState?.DateTimeFrom ?? new Date(), -deferentMinutes);

			return {
				...prevState,
				DateTimeFrom: newDated,
				Minutes: minutesInNumber,
			};
		});
	};

	const dateDidChange = (value: Date | null) => {
		setFormState(prevState => ({
			...prevState,
			Minutes: 0,
			DateTimeTo: value ? value : undefined,
		}));
		handleHideFormError(EWorklogInputName?.DateTimeTo);
		handleHideFormError(EWorklogInputName?.DateTimeFrom);
		handleHideFormError(EWorklogInputName?.Minutes);
	};

	const dateFromDidChange = (value: Date | null) => {
		setFormState(prevState => ({
			...prevState,
			DateTimeFrom: value ? value : undefined,
		}));
		handleHideFormError(EWorklogInputName?.DateTimeTo);
		handleHideFormError(EWorklogInputName?.DateTimeFrom);
		handleHideFormError(EWorklogInputName?.Minutes);
	};

	const getName = (x: IIssue) => {
		return `${x?.DisplayId} ${x?.Title}`;
	};

	const getValue = (inputName: EWorklogInputName) => {
		if (formState[inputName]) return formState[inputName];
		else if (isWorklogInEdit) return initialWorklogFormValues[inputName];
		else return null;
	};

	const getError = (inputName: EWorklogInputName) => {
		return formErrors.find(error => error.inputName === inputName)?.errorMessage;
	};

	const handleEndDateCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setWantTosetEndDateWithMinutes(checked);
		handleHideFormError(EWorklogInputName.Minutes);
		handleHideFormError(EWorklogInputName.DateTimeTo);
	};

	const handleDeleteClick = (id: number) => {
		setDisableInputs(true);
		handleDelete(id);
	};

	const handleSaveClick = () => {
		if (isWorklogInEdit) {
			const schema = Joi.object({
				DateTimeFrom: Joi.when('WantToSetEndDateWithMinutes', {
					is: false,
					then: Joi.date().max(Joi.ref('DateTimeTo')),
					otherwise: Joi.date(),
				}),
				WantToSetEndDateWithMinutes: Joi.boolean(),
				DateTimeTo: Joi.when('WantToSetEndDateWithMinutes', {
					is: false,
					then: Joi.date(),
					otherwise: Joi.optional(),
				}),
				Minutes: Joi.when('WantToSetEndDateWithMinutes', {
					is: true,
					then: Joi.number(),
					otherwise: Joi.optional(),
				}),
				Description: Joi.string(),
			});
			const { error } = schema.validate(
				{ ...formState, WantToSetEndDateWithMinutes: wantToSetEndDateWithMinutes },
				{ abortEarly: false }
			);
			if (error) return alert(t('alert'));
			setDisableInputs(true);
			handleSave(WorklogDataMapper.toWorklogFromUpdateForm(formState, initialWorklogFormValues));
		} else {
			const schema = Joi.object({
				DateTimeFrom: Joi.when('WantToSetEndDateWithMinutes', {
					is: false,
					then: Joi.date()
						.required()
						.max(Joi.ref('DateTimeTo')),
					otherwise: Joi.date().required(),
				}),
				WantToSetEndDateWithMinutes: Joi.boolean(),
				DateTimeTo: Joi.when('WantToSetEndDateWithMinutes', {
					is: false,
					then: Joi.date().required(),
				}),
				Minutes: Joi.when('WantToSetEndDateWithMinutes', {
					is: true,
					then: Joi.number().required(),
				}),
				Description: Joi.string().optional(),
			});
			const { error } = schema.validate(
				{ ...formState, WantToSetEndDateWithMinutes: wantToSetEndDateWithMinutes },
				{ abortEarly: false }
			);
			if (error) return alert(t('alert'));
			setDisableInputs(true);
			handleSave(WorklogDataMapper.toWorklogFromCreateForm(formState, wantToSetEndDateWithMinutes));
		}
	};

	return (
		<div className={classes.root}>
			<Typography className={classes.titleArea} variant={Resources.GlobalResources.h5}>
				<VisibilityControl condition={!!initialWorklogFormValues} alternative={t('titleNewWorklog')}>
					{t('titleUpdateWorklog')}
				</VisibilityControl>
			</Typography>
			<div className={classes.issueNameArea}>
				<CustomSelect<IIssue>
					isRequired
					disabled
					isDarkMode={isDarkMode}
					onChange={() => {}}
					getKey={c => c?.Id ?? Resources.GlobalResources.empty}
					getName={getName}
					label={t('issueName')}
					args={{ isDisabled: true }}
					values={issue}
					items={[]}
				/>
			</div>
			<div>
				<CustomDateTimePicker
					label={t('startDate')}
					handleChange={dateFromDidChange}
					value={getValue(EWorklogInputName.DateTimeFrom) as Date | null}
					isRequired
					errorMessage={getError(EWorklogInputName.DateTimeFrom)}
				/>
			</div>
			<div>
				<VisibilityControl
					condition={!wantToSetEndDateWithMinutes}
					alternative={
						<CustomInput
							type={EInputTypes.number}
							label={t('time')}
							isRequired
							value={getValue(EWorklogInputName.Minutes) as number | null}
							handleChange={dateDidChangeBasedOnMinutes}
							errorMessage={getError(EWorklogInputName.Minutes)}
						/>
					}
				>
					<CustomDateTimePicker
						label={t('endDate')}
						handleChange={dateDidChange}
						value={getValue(EWorklogInputName.DateTimeTo) as Date | null}
						isRequired
						errorMessage={getError(EWorklogInputName.DateTimeTo)}
					/>
				</VisibilityControl>
				<FormControlLabel
					className={classes.checkbox}
					control={<Checkbox checked={wantToSetEndDateWithMinutes} onChange={handleEndDateCheckboxChange} />}
					label={tString('wantToSetEndDateUsingMinutes')}
				/>
			</div>
			<CustomInput
				type={EInputTypes.text}
				label={t('desc')}
				value={getValue(EWorklogInputName.Description) as string | null}
				multiline
				rows={3}
				className={classes.descArea}
				handleChange={value => handleChange(EWorklogInputName.Description, value as string)}
				errorMessage={getError(EWorklogInputName.Description)}
			/>
			<div className={classes.buttonsContainer}>
				<Button
					variant={Resources.GlobalResources.contained}
					size={Resources.GlobalResources.small}
					onClick={handleSaveClick}
					disabled={disableInputs}
				>
					<SaveAsIcon />
					{t('save')}
				</Button>
				<VisibilityControl condition={isWorklogInEdit}>
					<Button
						variant={Resources.GlobalResources.contained}
						size={Resources.GlobalResources.small}
						onClick={() => handleDeleteClick(initialWorklogFormValues.Id as number)}
						className={classes.errorButton}
						disabled={disableInputs}
					>
						<DeleteForeverIcon />
						{t('delete')}
					</Button>
				</VisibilityControl>
				<Button size={Resources.GlobalResources.small} onClick={handleHideForm} disabled={disableInputs}>
					<CloseIcon />
					{t('cancel')}
				</Button>
			</div>
		</div>
	);
};
