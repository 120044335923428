import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Typography } from '@mui/material';

import { AsyncAction } from '@skillchill/redux-promisify';

import { EIssueModalTabs, IIssue, IIssueToIssueRelationship, IPage } from '@skillandchill/tasker-types';
import { AsyncSelect, Button, TaskerTable } from '@skillandchill/tasker-widgets2';
import { errorVariant, successVariant } from '@skillandchill/tasker-widgets2/dist/utils';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import {
	add_HigherOrderIssueConnection,
	add_EquivalentIssueConnection,
	add_SubTaskIssueConnection,
	get_Issue_HigherOrderTasks,
	get_Issue_EquivalentTasks,
	get_Issue_Subtasks,
	get_IssueModalOptions,
	remove_higherOrderTask_Connection,
	remove_subTask_Connection,
	remove_equivalentTask_Connection,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { rowsPerPage } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';

import { issueModalDataContext } from '../..';
import { EIssueModalDetailsPageFields } from '../IssueDetailsPage/model';

import { ERelation, IIssueAsync, IIssueRelationship, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';
import { useTableData } from './useTableData';

export const RelationTab = (props: Props): JSX.Element => {
	const { tab, issues, relation, isLoading, issueId, issuesCount, changeRelationToBasic } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();
	const { t } = useTrans('IssueModal.IssueModalContent.Relation');
	const { columns, data } = useTableData({
		onDelete: onDelete,
		issuesPage: {
			Count: issuesCount,
			Data: issues,
		},
		changeRelationToBasic: changeRelationToBasic,
	});

	const isDarkMode = useSelector((state: IReducerState) => state.Session.darkMode);

	const [countRowsPerPage] = useState<number>(rowsPerPage);
	const [page, setPage] = useState<number>(0);
	const [isNewAdded, setIsNewAdded] = useState<boolean>(false);
	const [chosenIssue, setChosenIssue] = useState<IIssue | null>(null);

	const [issueInputValue, setIssueInputValue] = useState<string>(Resources.GlobalResources.empty);

	const { isEdittingIssue, handleInputChange, issueState } = useContext(issueModalDataContext);

	useEffect(() => {
		if (!isEdittingIssue) return;

		if (tab === EIssueModalTabs.childTasks)
			dispatch(get_Issue_Subtasks(issueId, countRowsPerPage * page, countRowsPerPage));
		else if (tab === EIssueModalTabs.parentTasks)
			dispatch(get_Issue_HigherOrderTasks(issueId, countRowsPerPage * page, countRowsPerPage));
		else if (tab === EIssueModalTabs.equivalentTasks)
			dispatch(get_Issue_EquivalentTasks(issueId, countRowsPerPage * page, countRowsPerPage));
	}, [page, isEdittingIssue]);

	function handleResetChosenIssue() {
		setIsNewAdded(false);
		setChosenIssue(null);
	}

	const onAddSuccess = () => {
		handleResetChosenIssue();
		snackbar.enqueueSnackbar(t('successSave'), successVariant);
	};

	const saveNewConnectionDidClick = () => {
		const data: Partial<IIssueToIssueRelationship> = {};
		if (relation == ERelation.Children) {
			data.ToIssueId = issueId;
			data.FromIssueId = chosenIssue?.Id;
		} else if (relation == ERelation.Parent) {
			data.FromIssueId = issueId;
			data.ToIssueId = chosenIssue?.Id;
		} else if (relation == ERelation.Equivalent) {
			data.FromIssueId = issueId;
			data.ToIssueId = chosenIssue?.Id;
		}

		if (isEdittingIssue) {
			if (issueId && data.FromIssueId && data.ToIssueId) {
				if (tab === EIssueModalTabs.childTasks)
					dispatch(add_SubTaskIssueConnection(issueId, data)).then(onAddSuccess);
				else if (tab === EIssueModalTabs.parentTasks)
					dispatch(add_HigherOrderIssueConnection(issueId, data)).then(onAddSuccess);
				else if (tab === EIssueModalTabs.equivalentTasks)
					dispatch(add_EquivalentIssueConnection(issueId, data)).then(onAddSuccess);
				setPage(0);
			} else snackbar.enqueueSnackbar(t('error'), errorVariant);
		} else {
			if (tab === EIssueModalTabs.childTasks) {
				handleInputChange(EIssueModalDetailsPageFields.subTasks, [
					...(issueState?.SubTasks ?? []),
					{ ...data, ...chosenIssue, uId: uniqueId() },
				]);
			}

			if (tab === EIssueModalTabs.parentTasks) {
				handleInputChange(EIssueModalDetailsPageFields.parentTasks, [
					...(issueState?.ParentTasks ?? []),
					{ ...data, ...chosenIssue, uId: uniqueId() },
				]);
			}

			if (tab === EIssueModalTabs.equivalentTasks) {
				handleInputChange(EIssueModalDetailsPageFields.equivalentTasks, [
					...(issueState?.EquivalentTasks ?? []),
					{ ...data, ...chosenIssue, uId: uniqueId() },
				]);
			}
		}
	};

	const removeAction = (tab: EIssueModalTabs, issueId: number, connectionId: number): AsyncAction => {
		switch (tab) {
			case EIssueModalTabs.childTasks:
				return remove_subTask_Connection(issueId, connectionId);
			case EIssueModalTabs.parentTasks:
				return remove_higherOrderTask_Connection(issueId, connectionId);
			case EIssueModalTabs.equivalentTasks:
				return remove_equivalentTask_Connection(issueId, connectionId);
			default:
				throw new Error(`Unknown tab: ${tab}`);
		}
	};

	function onDelete(issueId: number, connectionId: number) {
		if (isEdittingIssue) {
			dispatch(removeAction(tab, issueId, connectionId))
				.then(() => snackbar.enqueueSnackbar(t('relationDelete'), successVariant))
				.catch(() => snackbar.enqueueSnackbar(t('relationDeleteFail'), errorVariant));

			setPage(0);
		}
	}

	const issueLoadOptions = async (inputValue = Resources.emptySpecialText): Promise<IPage<IIssueAsync[]>> => {
		if (inputValue == Resources.GlobalResources.empty || !inputValue) {
			inputValue = Resources.emptySpecialText;
		}
		const response = await dispatch(get_IssueModalOptions(inputValue, countRowsPerPage, issueId));
		return Promise.resolve(response?.data);
	};

	const selectedTab = (relation: ERelation) => {
		switch (relation) {
			case ERelation.Children:
				return EIssueModalTabs.childTasks;
			case ERelation.Parent:
				return EIssueModalTabs.parentTasks;
			case ERelation.Equivalent:
				return EIssueModalTabs.equivalentTasks;
			default:
				return EIssueModalTabs.details;
		}
	};

	const selectedLabel = (relation: ERelation) => {
		switch (relation) {
			case ERelation.Children:
				return t('children');
			case ERelation.Parent:
				return t('parent');
			case ERelation.Equivalent:
				return t('equivalent');
			default:
				return t('details');
		}
	};

	const issueSelectGetOptionName = (value: IIssueAsync): string => {
		return value?.IsDefault ? value?.Title : `[${value?.DisplayId}] ${value.Title}`;
	};

	const issueSelectGetOptionKey = (value: IIssueAsync): number => {
		return value.Id;
	};

	const selectCallBack = (inputValue = Resources.emptySpecialText) => {
		return issueLoadOptions(inputValue).then((x: IPage<IIssueAsync[]>) => {
			const currentIssueIds = issues.map(issue => issue.Id);
			const filteredIssues = x?.Data?.filter(issue => !currentIssueIds.includes(issue.Id));
			if (x?.Count > x?.Data?.length) {
				const title = t('moreRecords');
				filteredIssues.push({ Title: title, IsDefault: true } as IIssueAsync);
			}

			return filteredIssues;
		});
	};

	return (
		<VisibilityControl condition={tab == selectedTab(relation)}>
			<VisibilityControl
				condition={isNewAdded}
				alternative={
					<div className={clsx(classes.flexYCenter, classes.addRelationContainer)}>
						<Button onClick={() => setIsNewAdded(true)}>
							<AddCircleIcon />
							{t('addIssue')}
						</Button>
					</div>
				}
			>
				<div className={clsx(classes.flexColumn, classes.card, classes.addRelationContainer)}>
					<div>
						<AsyncSelect<IIssueAsync>
							getOptionKey={issueSelectGetOptionKey}
							getOptionName={issueSelectGetOptionName}
							label={selectedLabel(relation)}
							value={chosenIssue}
							setInputValue={setIssueInputValue}
							inputValue={issueInputValue}
							callback={selectCallBack}
							handleChange={setChosenIssue}
							noOptionsText={t('noOptionsText')}
						/>
					</div>
					<div className={classes.buttonsContainer}>
						<Button onClick={saveNewConnectionDidClick} variant={Resources.GlobalResources.contained}>
							<AddCircleOutlineRoundedIcon />
							{t('add')}
						</Button>
						<Button onClick={() => setIsNewAdded(false)}>
							<CloseIcon />
							{t('cancel')}
						</Button>
					</div>
				</div>
			</VisibilityControl>
			<div className={classes.listContainer}>
				<VisibilityControl
					condition={
						!!issuesCount ||
						!!issueState?.SubTasks?.length ||
						!!issueState?.ParentTasks?.length ||
						!!issueState?.EquivalentTasks?.length
					}
					alternative={
						<Typography variant={Resources.GlobalResources.h5} className={classes.noIssuesText}>
							<VisibilityControl condition={!isNewAdded}>{t('noIssues')}</VisibilityControl>
						</Typography>
					}
				>
					<Card>
						<TaskerTable<IIssueRelationship>
							isLoading={isLoading}
							isDarkMode={isDarkMode}
							data={data}
							columns={columns}
							paginationProps={{
								listCount: Number.isNaN(issuesCount) ? 0 : issuesCount,
								currentPage: page + 1,
								rowsPerPage,
								onPageChangeCallBack: selected => setPage(selected - 1),
							}}
						/>
					</Card>
				</VisibilityControl>
			</div>
		</VisibilityControl>
	);
};
