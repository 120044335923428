import { IHoliday } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	number: 'number' as const,
	keys: {
		type: 'HolidayTypeId',
		name: 'Name',
		startDate: 'StartDate',
		isRecurrent: 'IsRecurrent',
		validation: {
			validFrom: 'ValidFrom',
			validTo: 'ValidTo',
		},
	} as IKeys,
	holidayBasic: {
		Name: '',
		StartDate: new Date(),
		Duration: 1,
		IsRecurrent: true,
	} as Partial<IHoliday>,
	listPath: '/holiday',
	holidayExclude: 'HolidayExclude_',
	lowDash: '_',
	color: '#E72D41',
	customColor: '#333',
	mainColor: '#eee',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Holiday: {
			Event: {
				Detail: {
					from: 'Od: ',
					to: 'Do: ',
					fromLabel: 'od',
					toLabel: 'do',
					year: 'Rok',
					pageTitle: 'Dzień wolny',
					name: 'Nazwa',
					frame: 'Lata obowiązywania',
					type: 'Typ',
					isRecurrent: 'Cykliczny',
					save: 'Zapisz',
					cancel: 'Anuluj',
					edit: 'Edytuj',
					delete: 'Usuń',
					noId: 'Błąd systemu',
					startDate: 'Data rozpoczęcia',
					addExceptions: 'Dodaj wyjątek',
					addDateRange: 'Dodaj zakres obowiązywania dnia wolnego',
					addHoliday: 'Dodaj dzień wolny',
					add: 'Dodaj',
					always: 'Zawsze',
					holidayExcludeLabel: 'Lata w których dzień wolny nie obowiązuje',
					negativeYear: 'Rok musi być liczbą większą od zera',
					validationExcludedDay: {
						inValidationRange: 'Wyjątek musi być w zakresie obowiązywania święta',
					},
					validationError: {
						startDate: 'Proszę podać datę rozpoczęcia obowiązywania dnia wolnego',
						endDate: 'Proszę podać datę zakończenia obowiązywania dnia wolnego',
						inValid: 'Daty są niepoprawne',
					},
				},
			},
		},
	},
	en: {
		Holiday: {
			Event: {
				Detail: {
					from: 'from: ',
					to: 'to: ',
					fromLabel: 'From',
					toLabel: 'To',
					year: 'Year',
					pageTitle: 'Holiday',
					name: 'Name',
					frame: 'Valid time',
					type: 'Type',
					isRecurrent: 'Is recurrent',
					save: 'Save',
					cancel: 'Cancel',
					edit: 'Edit',
					delete: 'Delete',
					noId: 'System error',
					startDate: 'Start date',
					addExceptions: 'Add exceptions',
					addDateRange: 'Add a range of holiday',
					addHoliday: 'Add holiday',
					add: 'Add',
					always: 'Forever',
					holidayExcludeLabel: 'Years when holidays are excluded',
					negativeYear: 'Year can not be negative number',
					validationExcludedDay: {
						inValidationRange: 'Excluded year have to be in validation range',
					},
					validationError: {
						startDate: 'Please set up validation start date of holiday',
						endDate: 'Please set up validation end date of holiday',
						inValid: 'Dates of validation are invalid',
					},
				},
			},
		},
	},
};

interface IKeys {
	type: 'HolidayTypeId';
	name: 'Name';
	startDate: 'StartDate';
	isRecurrent: 'IsRecurrent';
	validation: {
		validFrom: 'ValidFrom';
		validTo: 'ValidTo';
	};
}
