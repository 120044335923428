import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { TextWidget } from '@skillandchill/tasker-widgets2/dist/widgets/TextWidget';

import { useTrans } from '@/utils/hooks/useTrans';

import { useStyles } from '../../IssueHeader/styles';

import { Props } from './model';
import { Resources } from './resources';

export const SingleTileElement = (props: Props): JSX.Element => {
	const { issue, isClient } = props;
	const classes = useStyles();
	const { tString } = useTrans('IssueList');

	return (
		<div className={clsx(classes.overflowAuto, classes.maxOfWidth, classes.flexRow, classes.header)}>
			<div className={clsx(classes.flexColumn, classes.overflowHidden, classes.maxOfWidth, classes.column)}>
				<div className={clsx(classes.flexColumn, classes.pointerCursor)}>
					<RouterLink
						target={Resources.GlobalResources.blank}
						to={
							isClient
								? `${Resources.customers}${issue?.Project?.CustomerId}/${Resources.summary}`
								: `${Resources.projectId}${issue.ProjectId}/${Resources.overview}`
						}
					>
						<TextWidget
							hover
							text={
								isClient
									? issue?.Project?.Customer?.Name ?? Resources.GlobalResources.empty
									: issue?.Project?.Name ?? Resources.GlobalResources.empty
							}
							variant={Resources.GlobalResources.h6}
							className={clsx(
								classes.pointerCursor,
								classes.overflowHidden,
								classes.maxOfWidth,
								classes.issueTitle
							)}
						/>
					</RouterLink>
					<TextWidget
						text={tString(isClient ? 'TileProject.customerName' : 'TileProject.projectName')}
						variant={Resources.GlobalResources.body2}
					/>
				</div>
			</div>
		</div>
	);
};
