import * as xlsx from 'exceljs';
import { TableColumnProperties } from 'exceljs';
import { saveAs } from 'file-saver';

import { EIssueRole, EValidationError, IRoleToIssueRole, IValidationError } from '@skillandchill/tasker-types';
import { EParam } from '@skillandchill/tasker-types/dist/types/params';
import { CommonResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IError } from '@/store/ITypes';

export const collapseEndListener = (): void => {};

const fallbackCopyTextToClipboard = (text: string): void => {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.select();
	textArea.focus();
	try {
		const successful = document.execCommand('copy');
		const msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}
	document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string, container: HTMLTextAreaElement | undefined): void => {
	if (!navigator.clipboard || !container) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	container.focus();
	container.select();
	navigator.clipboard.writeText(text).then(
		() => console.log('Async: Copying to clipboard was successful!'),
		err => console.error('Async: Could not copy text: ', err)
	);
};

export const getFirst = <T>(list: T[]): T => {
	if (list?.length > 0) return list[0];
	return {} as T;
};

export function showTimer(issueRoles: IRoleToIssueRole[] | undefined, loggedUserId: number): boolean {
	return (issueRoles?.filter(x => filterAtShowTimer(x, loggedUserId)) ?? [])?.length > 0;
}

function filterAtShowTimer(data: IRoleToIssueRole, loggedUserId: number): boolean {
	return (
		data?.TaskerUserId == loggedUserId &&
		(data?.IssueRoleId == EIssueRole.Assigned || data?.IssueRoleId == EIssueRole.Auditor)
	);
}

export const getFirstNotEmptyValue = (values: (string | undefined)[]): string => {
	return (
		values.find(x => x?.trim() !== CommonResources.GlobalResources.empty) ?? CommonResources.GlobalResources.empty
	);
};

export async function createXLSXReport(rowsInput: any[][], columns: TableColumnProperties[], fileName: string) {
	const rows = getItOrEmptyDoubleArrayText(rowsInput);
	const workbook = new xlsx.Workbook();
	const worksheet = workbook.addWorksheet();
	worksheet.addTable({
		name: 'Issues',
		ref: 'A1',
		headerRow: true,
		totalsRow: false,
		columns: columns,
		rows: rows,
		style: {
			theme: 'TableStyleLight2',
			showRowStripes: true,
		},
	});
	worksheet.eachRow(row => {
		row.eachCell(cell => {
			cell.alignment = { vertical: 'top', horizontal: 'left' };
		});
	});

	worksheet.columns.forEach(function(column) {
		let maxLength = 0;
		if (column && typeof column['eachCell'] === 'function') {
			column['eachCell']({ includeEmpty: true }, function(cell) {
				const columnLength = cell.value ? cell.value.toString().length : 10;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
			});
			column.width = maxLength < 10 ? 10 : maxLength;
		}
	});
	const buffer = await workbook.xlsx.writeBuffer();
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const blob = new Blob([buffer], { type: fileType });
	saveAs(blob, fileName);
}

export const getItOrEmptyDoubleArrayText = (x: any[][]) => x?.map(y => y?.map(z => (z ? z?.toString() : '')));

const forbiddenObject = {
	code: EValidationError.GotNoObject,
	param: EParam?.Tables.TaskerUser,
	message: 'Session error log in again',
};

export const redirectError = (e: IError) => {
	const list: IValidationError[] = (e?.response?.data?.message as IValidationError[]) ?? [];
	if (!list?.filter) return false;
	const filteredList = list?.filter((x: IValidationError) => filter(x));
	return filteredList?.length > 0;
};

const filter = (e: IValidationError) => {
	return (
		e.message === forbiddenObject.message && e.code === forbiddenObject.code && e.param === forbiddenObject.param
	);
};
