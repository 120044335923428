import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Login: {
			ChangePassword: {
				pageTitle: 'Zmiana hasła',
				passwordChanged: 'Hasło zostało zmienione',
				passwordChangeFailed: 'Hasło nie zostało zmienione',
				submit: 'Zapisz zmiany',
				logout: 'Wyloguj',
				cardHeaderTitle: 'Zmień hasło',
				info: 'Aby uzyskać pełny dostęp do aplikacji należy zmienić hasło',
				info2:
					'W zależności od wytycznych ustawionych przez administratora organizacji, hasło należy zmieniać co określony okres czasu',
				Error: {
					usedPassword: 'Podane nowe hasło było użyte w ciągu ostatnich 6 zmian. Proszę podaj inne.',
				},
			},
		},
	},
	en: {
		Login: {
			ChangePassword: {
				pageTitle: 'Change password',
				passwordChanged: 'Password changed',
				passwordChangeFailed: 'Password change failed',
				submit: 'Save changes',
				logout: 'Logout',
				cardHeaderTitle: 'Change password',
				info: 'To get full access to the application, you must change the password',
				info2:
					'Depending on the guidelines set by the administrator of the organization, the password should be changed every specified period of time',
				Error: {
					usedPassword:
						'The new password you entered was used within the last 6 changes, please enter a different password.',
				},
			},
		},
	},
};

export const defaultValues = {
	password: '',
	confirm: '',
	oldPassword: '',
};

export enum EErrorKind {
	noError = 0,
	newMatch = 1,
	oldMatch = 2,
	requirements = 3,
	newOldMatch = 4,
	newDuplicate = 5, //Hasło było wykorzystane w ostatnich 6 hasłach
}
