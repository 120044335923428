import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	() => {
		return {
			...getGlobalStyles(),

			container: {
				position: 'relative',
				top: '15px',
			},
		};
	},
	{ name: `KanbanBoard_KanbanSimpleFilters` }
);
