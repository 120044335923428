import { AsyncAction } from '@skillchill/redux-promisify';

import {
	DeepPartial,
	FullFilters,
	IComplexUpdate,
	ICreateProjectBaseOnTemplate,
	IPage,
	IPortalAnswer,
	IProject,
	IProjectIssueType,
	IProjectUpdate,
	IWorkingUserNEW,
} from '@skillandchill/tasker-types';
import { Customer, Profile, Project } from '@skillandchill/tasker-widgets2/dist/utils/types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const TURN_ON_SAVING: ActionType = 'TURN_ON_SAVING';
export const TURN_OFF_SAVING: ActionType = 'TURN_OFF_SAVING';
export const PROJECT_FETCH_TYPE: ActionType = 'PROJECT_FETCH';
export const PROJECT_FETCH_TYPE_NEW: ActionType = 'PROJECT_FETCH_TYPE_NEW';
export const PROJECT_FETCH_TYPE_BY_CUSTOMER: ActionType = 'PROJECT_FETCH_BY_CUSTOMER';
export const PROJECT_FETCH_LIST_TYPE: ActionType = 'PROJECT_FETCH_LIST_TYPE';
export const PROJECT_UPDATE_TYPE: ActionType = 'PROJECT_UPDATE';
export const PROJECT_FETCH_TYPE_PAGE_NEW: ActionType = 'PROJECT_FETCH_TYPE_PAGE_NEW';
export const PROJECT_FETCH_PAGE: ActionType = 'PROJECT_FETCH_PAGE';
export const PROJECT_CUSTOMER_PROJECT_PAGE: ActionType = 'PROJECT_CUSTOMER_PROJECT_PAGE';
export const PROJECT_FETCH_ONE: ActionType = 'PROJECT_FETCH_ONE';
export const PROJECT_UPDATE: ActionType = 'PROJECT_UPDATE';
export const PROJECT_USERS_ACTIVE_TASKS_FETCH: ActionType = 'PROJECTUSERS_FETCH';
export const PROJECT_ISSUE_TYPES_FETCH: ActionType = 'PROJECT_ISSUE_TYPES_FETCH';
export const PROJECT_CLEAR: ActionType = 'PROJECT_CLEAR';
export const FETCH_ALL_PROJECTS: ActionType = 'FETCH_ALL_PROJECTS';
export const CREATE_OR_UPDATE_PROJECT: ActionType = 'CREATE_OR_UPDATE_PROJECT';
export const SET_PORTAL_ANSWERS: ActionType = 'CREATE_OR_UPDATE_PROJECT';
export const COPY_PROJECT: ActionType = 'COPY_PROJECT';

const basePath = 'project';
const workingUsersPath = 'WorkingUsers';
const projectIssueTypePath = 'ProjectIssueType';

function _fetchProjectNew(projects: IProject[]): Action {
	return {
		type: PROJECT_FETCH_TYPE_NEW,
		projects,
	};
}
function _fetchProjectIssueTypes(projectIssueTypes: IProjectIssueType[]): Action {
	return {
		type: PROJECT_ISSUE_TYPES_FETCH,
		projectIssueTypes,
	};
}

function _fetchProject(projects: Project[]): Action {
	return {
		type: PROJECT_FETCH_TYPE,
		projects,
	};
}

export function setProjects(projects: IPage<IProject[]>): Action {
	return {
		type: PROJECT_FETCH_TYPE_PAGE_NEW,
		projects,
	};
}

export function _fetchAllProjects(data: IProject[]): Action {
	return {
		type: FETCH_ALL_PROJECTS,
		data,
	};
}

export function turnOnSaving(): Action {
	return {
		type: TURN_ON_SAVING,
	};
}

export function turnOffSaving(): Action {
	return {
		type: TURN_OFF_SAVING,
	};
}

export function setPortalAnswers(data: IPortalAnswer[]): Action {
	return {
		type: SET_PORTAL_ANSWERS,
		data,
	};
}

// export function project_fetchWithParams(params?: FullFilters, from = 0, count = 10): AsyncAction {
// 	return function(context, dispatch) {
// 		context.then(json => dispatch(_fetchProjectForDisplayList(mapProjects((json as IJson).data))));
// 		return axios().get(`project/from/${from}/count/${count}`, {
// 			params: {
// 				Issue: {},
// 				Project: {},
// 				Customer: {},
// 			},
// 		});
// 	};
// }

export function project_fetch(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchProject(mapProjects((json as IJson).data)));
		});
		return axios().get(`${basePath}/${id}`);
	};
}

export function project_fetchNew(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectNew((json as IJson).data)));
		return axios().get(basePath);
	};
}

interface IData {
	Project: Project;
	customer: Customer;
	manager: Profile;
	projectCreator: Profile;
}

export function mapProjects(data: IData[]): Project[] {
	return data.map(mapSingleRecord);
}

function mapSingleRecord(project: IData) {
	return project
		? {
				...project.Project,
				customer: project.customer,
				manager: project.manager,
				projectCreator: project.projectCreator,
		  }
		: ({} as Project);
}

function _fetchProjectOne(project: IProject): Action {
	return {
		type: PROJECT_FETCH_ONE,
		project,
	};
}

function _createOrUpdate(): Action {
	return {
		type: CREATE_OR_UPDATE_PROJECT,
	};
}

export function fetch_one_project(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectOne((json as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function fetch_projects_by_customer(customerId: number): AsyncAction {
	if (!customerId || customerId <= 0) throw new Error(`Cannot fetch projects of this Customer {${customerId}}`);
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectOne((json as IJson).data)));
		return axios().get(`${basePath}/customer/${customerId}`);
	};
}

export function create_project(data: ControllerProjectDataInput): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_createOrUpdate()));
		return axios().post(basePath, data);
	};
}

export function create_projectFromTemplate(data: ICreateProjectBaseOnTemplate): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_createOrUpdate()));
		return axios().post(`${basePath}/template`, data);
	};
}

export function project_fetchWithParamsNew(params?: FullFilters, from = 0, count = 10): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setProjects((json as IJson).data)));
		return axios().post(`${basePath}/from/${from}/count/${count}`, params);
	};
}

export function update_project(projectId: number, data: Partial<IProjectUpdate>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectOne((json as IJson).data)));
		return axios().patch(`${basePath}/${projectId}`, data);
	};
}

export function update_projectMembers(projectId: number, data: IComplexUpdate): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectOne((json as IJson).data)));
		return axios().patch(`${basePath}/members/${projectId}`, data);
	};
}

export function project_delete(id: number): AsyncAction {
	return function() {
		return axios().delete(`${basePath}/${id}`);
	};
}

export function project_clear(): Action {
	return {
		type: PROJECT_CLEAR,
	};
}

export function fetchProjectIssueTypes(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectIssueTypes((json as IJson).data)));
		return axios().get(`${projectIssueTypePath}/project/${projectId}`);
	};
}
export function fetchAllProjects(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllProjects((json as IJson).data)));
		return axios().get(basePath);
	};
}

function _fetchProjectUsersAndCurrentTasks(data: IPage<DeepPartial<IWorkingUserNEW>[]>): Action {
	return {
		type: PROJECT_USERS_ACTIVE_TASKS_FETCH,
		projectUsersAndIssues: data.Data,
		projectUsersCount: data.Count,
	};
}

function _copyProject(data: { IsCompleted: boolean }): Action {
	return {
		type: COPY_PROJECT,
		data,
	};
}

export function projectUsersAndCurrentTasks_fetch(
	projectId: number,
	page: number,
	rowsPerPage: number,
	searchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectUsersAndCurrentTasks((json as IJson)?.data)));
		return axios().post(`${workingUsersPath}/new/projects/${projectId}/from/${page}/count/${rowsPerPage}`, {
			filterBy: searchValue,
		});
	};
}

export function copyProject(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_copyProject((json as IJson)?.data)));
		return axios().post(`copyProject/${projectId}`);
	};
}

export interface ControllerProjectDataInput {
	project: Partial<IProject>;
	managerId?: number;
	ownerId?: number;
	memberIds?: number[];
}
