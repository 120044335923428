import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			urlInputContainer: {},
			nameSelect: {},
			buttonContainer: { marginTop: theme.spacing(2) },
			cancelButton: { marginLeft: theme.spacing(1) },
			bottomContainer: {
				marginTop: theme.spacing(2),
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gridGap: theme.spacing(2),
				alignItems: 'center',
			},
		};
	},
	{ name: `IssueModal_IssueModalTitle_MoreOptions_SocialMediaLinks_Form` }
);
