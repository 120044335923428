import { format } from 'date-fns';
import * as xlsx from 'exceljs';

import { EDateFNS } from '@skillandchill/tasker-widgets2/dist/utils';

export interface IDelegationExcelTemplateDetails {
	DelegationId: number;
	Name: string;
	Description: string;
	Payout: number;
	Company: string;
	Employee: string;
	Project: string;
	Budget: string;
	DateStart: Date;
	DateEnd: Date;
}

export const DelegationExcelTemplateDetails = (props: IDelegationExcelTemplateDetails, workbook: xlsx.Workbook) => {
	const sheet = workbook.addWorksheet('Details');

	sheet.addTable({
		name: 'Delegation details',
		ref: 'A1',
		headerRow: true,
		totalsRow: false,
		style: {
			theme: 'TableStyleLight2',
			showRowStripes: true,
		},
		columns: [
			{ name: 'Id' },
			{ name: 'Name' },
			{ name: 'Description' },
			{ name: 'Payout' },
			{ name: 'Company' },
			{ name: 'Employee' },
			{ name: 'Project' },
			{ name: 'Budget' },
			{ name: 'Date Start' },
			{ name: 'Date End' },
		],
		rows: [
			[
				props.DelegationId,
				props.Name,
				props.Description,
				`${props.Payout.toFixed(2)} zł`,
				props.Company,
				props.Employee,
				props.Project,
				props.Budget,
				format(props.DateStart, EDateFNS.DateWithoutTime),
				format(props.DateEnd, EDateFNS.DateWithoutTime),
			],
		],
	});

	sheet.eachRow(row => {
		row.eachCell(cell => {
			cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
		});
	});

	sheet.columns.forEach(function(column) {
		let maxLength = 0;
		if (column && typeof column['eachCell'] === 'function') {
			column['eachCell']({ includeEmpty: true }, function(cell) {
				const columnLength = cell.value ? cell.value.toString().length : 10;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
			});
			column.width = maxLength < 10 ? 10 : maxLength;
		}
	});
};
