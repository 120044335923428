import clsx from 'clsx';
import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HotelIcon from '@mui/icons-material/Hotel';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import { Badge, Button, Collapse, Divider, IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';

import { IIssue, ITaskerUser, IWorkLog } from '@skillandchill/tasker-types';
import { getDurationTime } from '@skillandchill/tasker-widgets2/dist/widgets/Charts/WeekChart/utilis';
import { TextFieldWrapper } from '@skillandchill/tasker-widgets2/dist/widgets/TextFieldWrapper';
import { TooltipTextBase } from '@skillandchill/tasker-widgets2/dist/widgets/TooltipTextBase';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { WorklogStarterProps } from '@/layouts/Dashboard/TopBar/model';
import { IConActions } from '@/layouts/Dashboard/WorklogStarter/IconActions';
import { MultiIssueInfo } from '@/layouts/Dashboard/WorklogStarter/MultiIssueInfo';
import { SingleIssueInfo } from '@/layouts/Dashboard/WorklogStarter/SingleIssioInfo';
import { useStyles } from '@/layouts/Dashboard/WorklogStarter/styles';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { MaxLengthText } from '@/utils/configuration';
import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';
import { collapseEndListener, getFirst } from '@/utils/utils';

import { AddToFavourites } from 'view/IssueList/TileComponents/AddToFavourites';

import {
	activity_work_logs_get,
	work_log_fetchActive_NEW,
	work_log_finish_NEW,
	work_log_pause_NEW,
	work_log_start_NEW,
} from '../../../store/actions';

import { EWorklogState, Resources } from './resources';
import { WorklogStarterPopover } from './WorklogStarterPopover';

export const WorklogStarter = (props: WorklogStarterProps): JSX.Element => {
	const {
		issue,
		active = false,
		onFinish,
		onPause,
		onStart,
		controlContext,
		showClock,
		handleFetchWorkableIssues,
		isLoading,
		className,
		dateTimeFrom,
		fullWidth = false,
		showFavourites = false,
	} = props;
	const { t, tString } = useTrans('Dashboard.WorklogStarter');
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();
	const workLogStarterRef = useRef(null);
	const classes = useStyles();

	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const isMobile: boolean = useSelector((state: IReducerState) => state?.Session?.isMobile);
	const forceClockClose: number = useSelector((state: IReducerState) => state?.DashBoard?.closeWorklogStarter) ?? 0;
	const kanbanChangedIssueClock: number =
		useSelector((state: IReducerState) => state?.DashBoard?.kanbanChangedIssueClock) ?? 0;
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);

	const [state, setState] = useState<EWorklogState>(EWorklogState.Normal);
	const [openWorkLogStarter, setOpenWorkLogStarter] = useState<boolean>(false);
	const [comment, setComment] = useState<string>(Resources.GlobalResources.empty);
	const [estimatedTime, setEstimatedTime] = useState<string>(dateTimeFrom ? getEstimatedTime() : '');
	const [lastId, setLastId] = useState<number>();
	const [isSubmitted, submitOn, submitOff] = useToggle(false);

	useEffect(() => {
		const worklogId = getFirst(activeWorklogs)?.Id;
		if (lastId != undefined && lastId != null) {
			if (lastId != worklogId) setComment(Resources.GlobalResources.empty);
			setLastId(worklogId);
		}
	}, [getFirst(activeWorklogs)?.Id]);

	useEffect(() => {
		if (dateTimeFrom) setEstimatedTime(getEstimatedTime());
	}, [dateTimeFrom]);

	useEffect(() => {
		if (openWorkLogStarter) setOpenWorkLogStarter(false);
	}, [forceClockClose]);

	useEffect(() => {
		if (kanbanChangedIssueClock > 0) dispatch(work_log_fetchActive_NEW());
	}, [kanbanChangedIssueClock]);

	const setOpenWorkLogStarterWrapper = (state: boolean) => {
		if (openWorkLogStarter != state) setOpenWorkLogStarter(state);
	};

	const refreshActivities = () => {
		if (history?.location?.pathname == Resources.activityPath && taskerUser?.Id)
			dispatch(activity_work_logs_get(taskerUser?.Id));
	};

	const handleStartWorkLog = (issueId: number): Promise<void> => {
		return dispatch(work_log_start_NEW(issueId)).then(() => setComment(Resources.GlobalResources.empty));
	};

	const handlePauseWorkLog = (issueId: number, comment?: string): Promise<void> => {
		return dispatch(work_log_pause_NEW(issueId, comment)).then(() => {
			if (taskerUser?.Id) refreshActivities();
			setComment(Resources.GlobalResources.empty);
		});
	};

	const handleFinishWorkLog = (issueId: number, comment?: string): Promise<void> => {
		return dispatch(work_log_finish_NEW(issueId, comment)).then(() => {
			refreshActivities();
			setComment(Resources.GlobalResources.empty);
		});
	};

	const handleSubmit = () => {
		if (isSubmitted) return;
		submitOn();
		const promises = [];
		if (onFinish && state === EWorklogState.Finish) promises.push(onFinish((issue as IIssue)?.Id, comment));
		if (onPause && state === EWorklogState.Pause) promises.push(onPause((issue as IIssue)?.Id, comment));
		controlContext?.emit(Resources.comment);
		setState(EWorklogState.Normal);
		return Promise.all(promises).finally(() => {
			refreshActivities();
			submitOff();
			setComment(Resources.GlobalResources.empty);
		});
	};

	const getWorkLogStarterTooltipBasedOnState: () => string = () => {
		const workLogStarterName = t('worklogTooltip.starterName');
		const action = openWorkLogStarter ? t('worklogTooltip.action.close') : t('worklogTooltip.action.open');
		return `${action} ${workLogStarterName}`;
	};

	const handleShowTasks = () => {
		if (showClock) setOpenWorkLogStarterWrapper(true);
	};

	function handleOnFinish() {
		setState(EWorklogState.Finish);
		return Promise.resolve();
	}

	function handleOnPause() {
		setState(EWorklogState.Pause);
		return Promise.resolve();
	}

	function handleOnStart() {
		if (onStart) onStart((issue as IIssue)?.Id);
		return Promise.resolve();
	}

	function getEstimatedTime() {
		if (dateTimeFrom) {
			const timeTmp = getDurationTime(differenceInMinutes(new Date(), new Date(dateTimeFrom as Date)));
			return ` ${t('estimatedTime')}:  ${timeTmp == Resources.GlobalResources.empty ? 0 : timeTmp}`;
		} else return '';
	}

	return (
		<ListItem
			className={clsx(
				className,
				classes.root,
				classes.flexColumn,
				classes.flexCrossAxisStretch,
				classes.defaultCursor
			)}
		>
			<Collapse in={state === Resources.normal} addEndListener={collapseEndListener}>
				<div
					className={
						showClock
							? clsx(
									classes.flexYCenter,
									classes.flexRow,
									classes.contentSpaceBetween,
									classes.pointerCursor,
									classes.flexActiveTaskWidth,
									fullWidth ? classes.fullWidth : classes.widthXL,
									(issue as IIssue)?.Id || (issue as IIssue[])?.length
										? classes.activeIssue
										: Resources.GlobalResources.empty
							  )
							: clsx(
									classes.flexYCenter,
									classes.flexRow,
									classes.contentSpaceBetween,
									classes.flexTask,
									fullWidth ? classes.fullWidth : Resources.GlobalResources.empty
							  )
					}
					ref={workLogStarterRef}
				>
					<VisibilityControl condition={issue === undefined}>
						<div className={clsx(classes.flexRow, classes.fullWidth)} onClick={handleShowTasks}>
							<HotelIcon className={classes.hotelIcon} />
							<ListItemText
								primary={
									<>
										<TooltipTextBase
											className={clsx(
												classes.overflowHidden,
												classes.titleWorkingOn,
												classes.justifyContentArabic
											)}
											text={tString('sleepIssue')}
										/>
									</>
								}
								primaryTypographyProps={{ variant: Resources.GlobalResources.body1 }}
							/>
						</div>
					</VisibilityControl>
					<VisibilityControl condition={!!(issue as IIssue)?.Id || (issue as IIssue[])?.length > 0}>
						<div
							className={clsx(
								showClock ? Resources.GlobalResources.empty : classes.defaultCursor,
								dateTimeFrom ? classes.titleContainerEstimatedTime : classes.titleContainer,
								classes.flexYCenter,
								isMobile ? classes.mobileText : Resources.GlobalResources.empty
							)}
							onClick={handleShowTasks}
						>
							<VisibilityControl condition={((issue as IIssue[])?.length ?? 0) == 0}>
								<SingleIssueInfo issue={issue as IIssue} />
							</VisibilityControl>
							<VisibilityControl condition={((issue as IIssue[])?.length ?? 0) > 0}>
								<MultiIssueInfo issues={issue as IIssue[]} />
							</VisibilityControl>
						</div>
						<VisibilityControl condition={!!dateTimeFrom}>
							<Tooltip title={estimatedTime}>
								<IconButton className={classes.defaultCursor} color={Resources.GlobalResources.inherit}>
									<TimerTwoToneIcon />
								</IconButton>
							</Tooltip>
						</VisibilityControl>
						<VisibilityControl condition={((issue as IIssue[])?.length ?? 0) == 0}>
							<IConActions
								issue={issue as IIssue}
								active={active}
								handleOnFinish={handleOnFinish}
								handleOnPause={handleOnPause}
								handleOnStart={handleOnStart}
								disabled={isSubmitted}
							/>
						</VisibilityControl>
						<VisibilityControl condition={showFavourites}>
							<AddToFavourites issue={issue as IIssue} />
						</VisibilityControl>
					</VisibilityControl>
					<VisibilityControl condition={showClock}>
						<Divider
							orientation={Resources.GlobalResources.vertical}
							flexItem
							className={classes.divider}
						/>
						<Tooltip title={getWorkLogStarterTooltipBasedOnState()} className={classes.clock}>
							<IconButton
								onClick={() => setOpenWorkLogStarterWrapper(true)}
								ref={workLogStarterRef}
								color={Resources.GlobalResources.primary}
							>
								<Badge
									max={9}
									badgeContent={activeWorklogs?.length > 1 ? activeWorklogs?.length : 0}
									classes={{ badge: classes.badge }}
								>
									<AccessTimeIcon color={Resources.GlobalResources.primary} />
								</Badge>
							</IconButton>
						</Tooltip>
					</VisibilityControl>
				</div>
			</Collapse>
			<Collapse in={state !== Resources.normal} addEndListener={collapseEndListener}>
				<div
					className={clsx(
						classes.flexActiveTask,
						classes.flexRow,
						classes.flexYCenter,
						classes.contentSpaceBetween,
						classes.pointerCursor
					)}
				>
					<TextFieldWrapper
						variant={Resources.standard}
						placeholder={t('comment')}
						fullWidth
						inputProps={{ maxLength: MaxLengthText }}
						value={comment}
						onChange={event => setComment(event?.target?.value)}
					/>
					<Button onClick={handleSubmit}>{t('save')}</Button>
				</div>
			</Collapse>
			<VisibilityControl condition={showClock}>
				<WorklogStarterPopover
					isLoading={isLoading}
					activeIssue={issue as IIssue}
					anchorEl={workLogStarterRef?.current}
					onClose={() => setOpenWorkLogStarterWrapper(false)}
					open={openWorkLogStarter}
					onStartWorklog={handleStartWorkLog}
					onPauseWorklog={handlePauseWorkLog}
					onFinishWorklog={handleFinishWorkLog}
					handleFetchWorkableIssues={handleFetchWorkableIssues}
					openWorkLogStarter={openWorkLogStarter}
				/>
			</VisibilityControl>
		</ListItem>
	);
};
