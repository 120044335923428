import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	slash: '/',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				add: 'Nowy formularz',
				tileTittle: 'Formularz',
				creationDate: 'Data stworzenia',
				useTableData: {
					name: 'Nazwa',
					users: 'Użytkownicy przypisani do formularza',
					creationDate: 'Data stworzenia',
					actions: 'Akcje',
					deleteTooltip: 'Usuń konfiguracje zakończenia dnia pracy',
					LabelAvatar: {
						multi: 'Przypisani użytkownicy do formularza',
						single: 'Osoba przypisana do formularza',
					},
				},
				noRecords: 'Brak formularzy',
				noRecordsInfo:
					'Dodaj formularze zakończenia dnia pracy, aby ułatwić podsumowanie codziennych aktywności',
				deleteConfirmation: 'Jesteś pewny że chcesz usunąć tą konfiguracje zakończenia dnia pracy?',
				pageTitle: 'Zakończenie dnia pracy',
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				add: 'New form',
				creationDate: 'Creation date',
				tileTittle: 'Form',
				useTableData: {
					name: 'Name',
					users: 'Users assigned to form',
					creationDate: 'Creation date',
					actions: 'Actions',
					deleteTooltip: 'Delete end-work-day configuration',
					LabelAvatar: {
						multi: 'Assigned to form',
						single: 'Assigned to form',
					},
				},
				noRecords: 'No forms yet',
				noRecordsInfo: 'Add end-of-day work forms to help summarize daily activities.',
				deleteConfirmation: 'Are you sure you want to delete this end-work-day configuration?',
				pageTitle: 'End work day',
			},
		},
	},
};
