import { Translation as Description } from './Content/GeneralTab/Description/resources';
import { Translation as Duration } from './Content/GeneralTab/Duration/resources';
import { Translation as IssueInfoOwnSelect } from './Content/GeneralTab/IssueInfo/OwnSelect/resources';
import { Translation as IssueInfo } from './Content/GeneralTab/IssueInfo/resources';
import { Translation as HistoryHeader } from './Content/HistoryTab/HistoryHeader/resources';
import { Translation as HistoryTab } from './Content/HistoryTab/resources';
import { Translation as Footer } from './Footer/resources';
import { Translation as TimeAssign } from './resources';

export const TimeAssignTranslationEN = [
	Description.en,
	Duration.en,
	IssueInfo.en,
	HistoryHeader.en,
	HistoryTab.en,
	Footer.en,
	TimeAssign.en,
	IssueInfoOwnSelect.en,
];

export const TimeAssignTranslationPL = [
	Description.pl,
	Duration.pl,
	IssueInfo.pl,
	HistoryHeader.pl,
	HistoryTab.pl,
	Footer.pl,
	TimeAssign.pl,
	IssueInfoOwnSelect.pl,
];
