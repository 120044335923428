import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	() => {
		return {
			...getGlobalStyles(),
			dropZoneArea: { padding: `0.7rem 0rem !important` },
			dropzoneContent: { padding: '0rem 1rem' },
			root: {
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				maxHeight: '450px',
				overflow: 'auto',
				paddingRight: '0.5rem',
			},
			tile: {
				padding: '0rem 0.5rem',
				marginBottom: '0.6rem',
				marginTop: '0.6rem',
			},
			iconButton: {
				width: '1.75rem',
				height: '1.75rem',
			},
			icon: {
				width: '1.3rem',
				height: '1.3rem',
			},
			dropZoneAreaIcon: {
				width: '2rem',
				height: '2rem',
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_TabsContent_Attachment` }
);
