import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	propertyField: {
		label: 'Label' as const,
		isImmutable: 'IsImmutable' as const,
		contextId: 'TagGroupContextId' as const,
	},
	menuPosition: 'menuPosition' as const,
	positionFixed: 'fixed' as const,
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					GroupContent: {
						groupName: 'Nazwa grupy',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					GroupContent: {
						groupName: 'Group name',
					},
				},
			},
		},
	},
};
