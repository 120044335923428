import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	GeneralTab: 'general',
	ActivitySite: '/activity',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				ManageUsers: {
					OrganizationTile: {
						TaskerUserTile: {
							logAs: 'Zaloguj się jako użytkownik',
							removeFromOrganization: 'Usuń z organizacji',
							removeSession: 'Zakończ sesję użytkownika',
							finishedSession: 'Sesja została zakończona',
							finishingSessionFailed: 'Nie udało się zakończyć sesji',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				ManageUsers: {
					OrganizationTile: {
						TaskerUserTile: {
							logAs: 'Log in as user',
							removeFromOrganization: 'Remove from organization',
							removeSession: 'End user session',
							finishedSession: 'Session finished',
							finishingSessionFailed: 'Failed to finish session',
						},
					},
				},
			},
		},
	},
};
