import { GenerateId } from 'jss';
import { SnackbarProvider } from 'notistack';
import React, { RefObject, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../utils/mixins/chartjs';
import '../../utils/mixins/moment';
import '../../utils/mixins/validate';
import '../../utils/mixins/prismjs';
import '../../utils/assets/scss/main.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { isRtl, registerLocales } from '@skillandchill/tasker-widgets2/dist/utils';
import overrides from '@skillandchill/tasker-widgets2/dist/utils/theme/overrides';
import overridesDark from '@skillandchill/tasker-widgets2/dist/utils/theme/overridesDark';
import { Palette, PaletteDark } from '@skillandchill/tasker-widgets2/dist/utils/theme/palette';
import { Transitions } from '@skillandchill/tasker-widgets2/dist/utils/theme/transition';
import { Typography } from '@skillandchill/tasker-widgets2/dist/utils/theme/typography';
import { Typography as TypographyDark } from '@skillandchill/tasker-widgets2/dist/utils/theme/typographyDark';
import { CustomStylesProvider } from '@skillandchill/tasker-widgets2/dist/widgets/StyleProvider';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { FilterLazyLoading } from 'view/FilterLazyLoading';
import { KeyListener, KeyListenerProps } from 'view/KeyListener';
import { SnackBarProviderWrapper } from 'view/SnackBarProviderWrapper';
import { Sound } from 'view/Sound';
import { Template } from 'view/Template';

import tp from './MuiTypography';

let ruleCounter = 0;

function ThemeProviderWrapper(): JSX.Element {
	registerLocales();
	const { i18n } = useTrans();
	const snackbarRef: RefObject<SnackbarProvider> = useRef() as RefObject<SnackbarProvider>;
	const keyListenerProps: KeyListenerProps = {
		snackbarRef: snackbarRef,
	};
	const darkMode = useSelector((state: IReducerState) => state.Session.darkMode);

	function createGenerateClassNameCustom(): GenerateId {
		return (rule, sheet) => {
			ruleCounter += 1;
			return `${sheet?.options?.meta ?? 'empty'}-${rule.key}-${ruleCounter}`;
		};
	}

	const getComponent = () => {
		const tmp = darkMode ? overridesDark : overrides;

		return isRtl()
			? {
					...tmp,
					MuiTypography: tp,
			  }
			: tmp;
	};

	const themeTest = useMemo(() => {
		const theme = {
			palette: darkMode ? PaletteDark : Palette,
			typography: darkMode ? TypographyDark : Typography,
			components: getComponent(),
			transitions: Transitions,
		};
		document.documentElement.style.setProperty(
			'--text-color',
			darkMode ? (PaletteDark?.text?.primary as string) : (Palette?.text?.primary as string)
		);

		return createTheme(theme);
	}, [darkMode, i18n.t('isFontReverse')]);

	return (
		<CustomStylesProvider generateClassName={createGenerateClassNameCustom()}>
			<ThemeProvider theme={themeTest}>
				<StyledEngineProvider injectFirst>
					<LocalizationProvider dateAdapter={AdapterDateFns as any}>
						<SnackBarProviderWrapper snackbarRef={snackbarRef} />
						<KeyListener {...keyListenerProps} />
						<Template />
						<FilterLazyLoading />
						<Sound />
					</LocalizationProvider>
				</StyledEngineProvider>
			</ThemeProvider>
		</CustomStylesProvider>
	);
}

export default ThemeProviderWrapper;
