import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { canvasId } from '@/utils/configuration';

export const appendPdfPage = async (pdf: jsPDF, html: string): Promise<jsPDF> => {
	let newPdf = pdf;

	const element = document.createElement('div');
	element.innerHTML = html;
	const appendElement = document.getElementById(canvasId);
	appendElement?.appendChild(element);

	await html2canvas(element).then(canvas => {
		appendElement?.removeChild(element);
		const imgData = canvas.toDataURL('image/png');
		const imgWidth = 210;
		const imgHeight = (canvas.height * imgWidth) / canvas.width;
		newPdf = pdf.addPage();
		newPdf = pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST');
	});

	return newPdf;
};

export const calculateTableRows = (
	html: string,
	tableId: string,
	maxHeight: number
): {
	sumHeight: number;
	rowsCount: number;
} => {
	const result = {
		sumHeight: 0,
		rowsCount: 0,
	};

	const element = document.createElement('div');
	element.innerHTML = html;
	const appendElement = document.getElementById(canvasId);
	if (!appendElement) return result;
	appendElement.appendChild(element);
	void appendElement.offsetHeight;

	const table = document.querySelector(tableId);
	if (!table) return result;
	const rows = table.querySelectorAll('tr');

	for (let i = 1; i < rows.length; i++) {
		const row = rows[i];
		result.sumHeight += row.clientHeight;
		if (result.sumHeight > maxHeight) {
			appendElement.innerHTML = '';
			return {
				sumHeight: result.sumHeight - row.clientHeight,
				rowsCount: i - 1,
			};
		}
	}

	appendElement.innerHTML = '';
	return {
		sumHeight: result.sumHeight,
		rowsCount: rows.length - 1,
	};
};
