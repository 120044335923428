import uuid from 'uuid';

import { EFieldDefinitionType } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
	selectArgs: {
		fullWidth: true,
		styles: { menu: (base: any) => ({ ...base, zIndex: 2 }) },
	},
	getTypeKey: (key: number) => `fieldDefinitionType_${key}_${uuid()}`,
	getDictionaryKey: (key: string) => `dictionary_${key}_${uuid()}`,
	getTagGroupKey: (key: string) => `tagGroup_${key}_${uuid()}`,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							name: 'Nazwa pola',
							description: 'Opis',
							isMandatory: 'Rekord obowiązkowy',
							mandatoryTooltip:
								'Zaznaczenie tej opcji sprawi, że pole będzie obowiązkowe do uzupełnienia podczas tworzenia zgłoszenia',
							isActive: 'Rekord aktywny',
							activeTooltip:
								'Zaznaczenie tej opcji sprawi, że pole będzie widoczne podczas tworzenia zgłoszenia',
							deleteIcon: 'Usuń rekord',
							isStatusTooltip:
								'Zaznaczenie tej opcji sprawi, że pole będzie traktowanie jako status w tym formularzu. Tylko jedno pole w formularzu może być oznaczone jako status',
							isStatus: 'Pole statusowe',
							fieldDefinitionType: {
								[EFieldDefinitionType.Boolean]: 'Prawda fałsz',
								[EFieldDefinitionType.Integer]: 'Liczba całkowita',
								[EFieldDefinitionType.Decimal]: 'Liczba dziesiętna',
								[EFieldDefinitionType.Text]: 'Tekst',
								[EFieldDefinitionType.Dictionary]: 'Słownik',
								[EFieldDefinitionType.TagGroup]: 'Grupa tagów',
								[EFieldDefinitionType.Date]: 'Data',
								[EFieldDefinitionType.DateTime]: 'Data wraz z czasem',
								[EFieldDefinitionType.TextArea]: 'Długi tekst',
								// 9: 'Stwórz nowy słownik',
							},
							typeLabel: 'Typ zmiennych',
							tagGroupLabel: 'Grupa tagów',
							dictionaryLabel: 'Słownik',
							isMultiValue: 'Zezwól na wiele',
							defaultValue: 'Wartość domyślna',
							defaultValueTooltip:
								'Ta wartość będzie domyślnie wpisana w polu przy wypełnianiu formularza',
							multiValueTooltip:
								'Zaznaczenie tej opcji pozwoli na wybór wielu wartości ze słownika podczas tworzenia zgłoszenia',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							name: 'Field name',
							description: 'Description',
							isMandatory: 'Record mandatory',
							mandatoryTooltip:
								'Checking this option will make the field mandatory to fill in when creating an issue',
							isActive: 'Record active',
							activeTooltip: 'Checking this option will make the field visible when creating an issue',
							deleteIcon: 'Delete record',
							isStatusTooltip:
								'Checking this option will make the field a status field in this form. Only one field in form can be a status one',
							isStatus: 'Status field',
							fieldDefinitionType: {
								[EFieldDefinitionType.Boolean]: 'Boolean',
								[EFieldDefinitionType.Integer]: 'Integer',
								[EFieldDefinitionType.Decimal]: 'Decimal',
								[EFieldDefinitionType.Text]: 'Text',
								[EFieldDefinitionType.Dictionary]: 'Dictionary',
								[EFieldDefinitionType.TagGroup]: 'TagGroup',
								[EFieldDefinitionType.Date]: 'Date',
								[EFieldDefinitionType.DateTime]: 'DateTime',
								[EFieldDefinitionType.TextArea]: 'Text area',
								// 9: 'Create new Dictionary',
							},
							typeLabel: 'Label type',
							tagGroupLabel: 'Tag group',
							dictionaryLabel: 'Dictionary',
							isMultiValue: 'Is multi value',
							defaultValue: 'Default value',
							defaultValueTooltip:
								'This value will be automatically filled in the field when filling out the form',
							multiValueTooltip:
								'Checking this option will allow you to select multiple values from the dictionary when creating an issue',
						},
					},
				},
			},
		},
	},
};
