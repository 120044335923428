import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { EOpenIssue, IIssueIssueModal } from '@skillandchill/tasker-types';
import { errorVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { fetch_IssueModalComments, issue_fetchOneNew, issueModal_fetchBasic } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useQuery } from '@/utils/hooks/useQuery';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';

export const useInitialLoad = (props: Props) => {
	const { onError, onClose } = props;
	const query = useQuery();
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();
	const { t } = useTrans('IssueModal.Hooks.UseInitialLoad');

	const onErrorWrapper = () => {
		onError();
		snackbar.enqueueSnackbar(t('error'), errorVariant);
	};

	const onSuccess = (x: { data: IIssueIssueModal }) => {
		if (x?.data?.OpenIssue === EOpenIssue.Portal) {
			const path = Resources.portal(x?.data?.Issue?.Id);
			history.replace(path);
			onClose();
		}
	};

	useEffect(() => {
		dispatch(issueModal_fetchBasic());

		const issueId = query['issueId'];

		if (issueId === undefined || issueId === null || issueId === 'undefined') {
			onClose();
			return;
		}
		if (Number.isNaN(issueId) || !issueId || Number.parseInt(issueId) === 0) return;

		dispatch(fetch_IssueModalComments(Number(issueId)));
		dispatch(issue_fetchOneNew(Number(issueId)))
			.then(x => onSuccess(x))
			.catch(onErrorWrapper);
	}, [query['issueId']]);
};
