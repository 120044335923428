import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	new: '/holiday/new',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Holiday: {
			Event: {
				List: {
					add: 'Dodaj ewent',
					pageTitle: 'Dni wolne',
					headerType: 'Dni wolne',
					holidayDate: 'Data dnia wolnego: ',
					type: 'Typ: ',
					useTableData: {
						name: 'Nazwa',
						holidayDate: 'Data obowiązywania dnia wolnego',
						type: 'Typ dnia wolnego',
						creator: 'Twórca',
					},
				},
			},
		},
	},
	en: {
		Holiday: {
			Event: {
				List: {
					add: 'Add event',
					pageTitle: 'Holidays',
					headerType: 'Holidays',
					holidayDate: 'Holiday date: ',
					type: 'Type: ',
					useTableData: {
						name: 'Name',
						holidayDate: 'Holiday date',
						type: 'Holiday type',
						creator: 'Creator',
					},
				},
			},
		},
	},
};
