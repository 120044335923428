export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				add: 'Dodaj członka projektu',
				pageTitle: 'Członkowie projektu',
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				add: 'Add project member',
				pageTitle: 'Project members',
			},
		},
	},
};
