import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	options: {
		variant: 'h6' as const,
	},
};
export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					HistoryHeader: {
						date: 'Data',
						from: 'Od',
						to: 'Do',
						user: 'Użytkownik',
						issue: 'Zgłoszenie',
						description: 'Opis',
						logged: 'Zalogowano',
						actions: 'Akcje',
						edit: 'Modyfikuj',
						delete: 'Usuń',
					},
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					HistoryHeader: {
						date: 'Date',
						from: 'From',
						to: 'To',
						user: 'User',
						issue: 'Issue',
						description: 'Description',
						logged: 'Logged',
						actions: 'Actions',
						edit: 'Edit',
						delete: 'Delete',
					},
				},
			},
		},
	},
};
