import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		item: {
			display: 'block',
			paddingTop: 0,
			paddingBottom: 0,
		},
		itemLeaf: {
			paddingTop: 0,
			paddingBottom: 0,
			width: theme.spacing(5),
		},
		button: {
			padding: theme.spacing(1.25, 1),
			justifyContent: 'flex-start',
			textTransform: 'none',
			letterSpacing: 0,
		},
		buttonLeaf: {
			display: 'flex',
			justifyContent: 'center',
			padding: theme.spacing(1.25, 1),
			textTransform: 'none',
			letterSpacing: 0,
			fontWeight: theme.typography.fontWeightRegular as 'normal',
			'&.depth-0': {
				minWidth: 'unset',
				fontWeight: theme.typography.fontWeightMedium as 'normal',
			},
		},
		icon: {
			// @ts-ignore
			color: theme.palette.icon,
		},
		expandIcon: {
			marginLeft: 'auto',
			height: theme.spacing(2),
			width: theme.spacing(2),
		},
		label: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: 'auto',
		},
		active: {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium as 'normal',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
	}),
	{ name: `Dashboard_NavBar_DrawerContentResized_NavBarSection_NavItem_NavItemBase` }
);
