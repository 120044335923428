import { makeStyles } from '@mui/styles';

import { CustomTheme, isRtl } from '@skillandchill/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: CustomTheme) => ({
		...getGlobalStyles(),
		container: {
			display: 'block',
			'&:not(:last-of-type)': {
				margin: theme.spacing(0, 0, 1.875, 0),
			},
		},
		card: {
			position: 'relative',
			transition: theme.transitions.fast,
			overflow: 'visible',
			'& a': {
				color: 'inherit',
			},

			'& strong': {
				fontStyle: 'italic',
			},
			'&:nth-last-child(2)': {
				'& .userLink div.roles': {
					bottom: `calc(100% + ${theme.spacing(0.625)})`,
					top: 'auto',
				},
			},
		},
		notificationPriority: {
			position: 'absolute',
			left: theme.spacing(3.5),
			bottom: theme.spacing(2.5),
			borderRadius: theme.spacing(1),
			border: '0px solid white',
			backgroundColor: theme.palette.background.paper,
		},
		lowPriorityColor: {
			color: theme.palette.green.main,
		},
		mediumPriorityColor: {
			color: theme.palette.blue.main,
		},
		highPriorityColor: {
			color: theme.palette.yellow.light,
		},
		criticalPriorityColor: {
			color: theme.palette.red.dark,
		},
		notificationMessage: {
			marginTop: theme.spacing(0.375),
			fontSize: theme.spacing(1.875),
			fontWeight: 400,
			'& ul': {
				paddingLeft: theme.spacing(2.5),
				'& li i': {
					whiteSpace: 'normal',
					hyphens: 'auto',
					wordBreak: 'break-all',
				},
			},
			'& strong': {
				fontWeight: '500',
				color: theme.palette.darkGray.main,
			},
			'& a div': {
				display: 'none',
			},
			'& .comment': {
				backgroundColor: 'rgba(96,144,223,0.1)',
				borderRadius: theme.spacing(0.625),
				fontStyle: 'italic',
				padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
				textAlign: 'center',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				maxWidth: '300px',
				overflow: 'hidden',
				margin: theme.spacing(0.5, 0, 0, 0),
				fontSize: theme.spacing(1.625),
				fontWeight: 450,
			},
			'& a': {
				position: 'relative',
				fontStyle: 'italic',
				color: theme.palette.blue.light,
				hyphens: 'auto',
				overflowWrap: 'break-word',

				'&.instanceObjectName': {
					whiteSpace: 'normal',
					hyphens: 'auto',
					wordBreak: 'break-all',
				},
				'&:hover': {
					textDecoration: 'underline',
				},
				'&:hover div.roles': {
					visibility: 'visible',
					opacity: '1',
				},
				'& div.roles': {
					fontStyle: 'normal',
					visibility: 'hidden',
					backgroundColor: theme.palette.lightGray.dark,
					color: theme.palette.background.default,
					borderRadius: theme.spacing(0.75),
					position: 'absolute',
					zIndex: 99999,
					top: `calc(100% + ${theme.spacing(1.25)})`,
					left: `-${theme.spacing(1.25)}`,
					opacity: '0',
					transition: '.2s',
					padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
					fontSize: theme.spacing(1.375),
					fontWeight: '500',
					width: 'min-content',
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'wrap',
					'& span': {
						whiteSpace: 'nowrap',
					},
					'& ul li': {
						whiteSpace: 'nowrap',
					},
				},
			},
		},
		notificationReadStatus: {
			marginLeft: theme.spacing(0.5),
		},
		badge: {
			alignSelf: 'flex-start',
			color: theme.palette.background.paper,
			marginRight: theme.spacing(1.5),
		},
		badgeContent: {
			backgroundColor: theme.palette.green.light,
			borderRadius: '50%',
			padding: theme.spacing(0.1875),
			border: '1px solid white',
			width: theme.spacing(2.75),
			height: theme.spacing(2.75),
		},
		newDate: {
			fontWeight: 450,
			marginLeft: theme.spacing(0.5),
			fontSize: theme.spacing(1.5),
			color: theme.palette.lightGray.main,
		},
		newDateColor: {
			color: theme.palette.lightGray.main,
		},
		readStatusColumn: {
			width: theme.spacing(1.25),
			height: theme.spacing(1.25),
			backgroundColor: theme.palette.blue.dark,
			borderRadius: '50%',
			position: 'absolute',
			right: isRtl() ? 'unset' : theme.spacing(0.625),
			left: isRtl() ? theme.spacing(0.625) : 'unset',
			top: theme.spacing(1),
			transition: '.2s',
			opacity: 1,
		},
		avatar: {
			borderRadius: theme.spacing(1.25),
		},
		avatarColumn: {
			marginRight: theme.spacing(1),
		},
		notificationContent: {
			margin: theme.spacing(0, 0, 0, 1.25),
			padding: theme.spacing(1.875, 0),
			borderBottom: '2px solid #D2D6DD',
		},
		notificationHeader: {
			color: theme.palette.lightGray.main,
			fontWeight: 450,
			fontSize: theme.spacing(1.625),
		},
		headerSeparator: {
			width: theme.spacing(0.5),
			height: theme.spacing(0.5),
			borderRadius: '50%',
			marginRight: theme.spacing(0.7),
			marginLeft: theme.spacing(0.7),
			backgroundColor: theme.palette.lightGray.main,
		},
		headerDivider: {
			width: theme.spacing(0.25),
			height: theme.spacing(2),
			color: theme.palette.lightGray.main,
			backgroundColor: theme.palette.lightGray.main,
			marginRight: theme.spacing(0.7),
			marginLeft: theme.spacing(1),
		},
		markAsReadIcon: {
			position: 'absolute',
			transition: '.2s',
			padding: '3px',
			right: isRtl() ? 'unset' : '-2px',
			left: isRtl() ? '-2px' : 'unset',
			top: '3px',
		},
		bookmarkIcon: {
			fontSize: theme.spacing(1.25),
		},
	}),
	{ name: `Dashboard_NotificationPopover_Notification` }
);
