import { useSelector } from 'react-redux';

import { Card, Grid, Popover, Typography } from '@mui/material';

import { IReducerState } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useTrans } from '@/utils/hooks/useTrans';

import { AlertPopoverProps } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

const HotkeysPopover = (props: AlertPopoverProps): JSX.Element => {
	const { anchorEl, open, closeModal } = props;
	const classes = useStyles();
	const { t } = useTrans('HotkeysPopover');

	const canOpenIssueModal = useSelector((state: IReducerState) => state?.Session?.canOpenIssueModal);

	return (
		<Popover
			open={open ?? false}
			onClose={closeModal}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: Resources.GlobalResources.bottom,
				horizontal: Resources.GlobalResources.right,
			}}
		>
			<Card className={classes.popover}>
				<Grid container direction={Resources.column}>
					<VisibilityControl condition={canOpenIssueModal}>
						<Grid item className={classes.margin}>
							<Typography variant={Resources.GlobalResources.h4}>{t('issue')}</Typography>
							<Grid container alignItems={Resources.GlobalResources.center}>
								<Typography className={classes.border}>{Resources.ctrl}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.alt}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.arrowLeft}</Typography>
								<Typography>{t('or')}</Typography>
								<Typography className={classes.border}>{Resources.arrowRight}</Typography>
								<Typography>{t('ArrowsToChangeIssue')}</Typography>
							</Grid>
							<Grid container alignItems={Resources.GlobalResources.center}>
								<Typography className={classes.border}>{Resources.ctrl}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.alt}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.plus}</Typography>
								<Typography>{t('PlusToAdd')}</Typography>
							</Grid>

							<Grid container alignItems={Resources.GlobalResources.center}>
								<Typography className={classes.border}>{Resources.esc}</Typography>
								<Typography>{t('EscapeToClose')}</Typography>
							</Grid>
							<Grid container alignItems={Resources.GlobalResources.center}>
								<Typography className={classes.border}>{Resources.Shift}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.alt}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.plus}</Typography>
								<Typography>{t('ResizePlus')}</Typography>
							</Grid>
							<Grid container alignItems={Resources.GlobalResources.center}>
								<Typography className={classes.border}>{Resources.Shift}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.alt}</Typography>
								<Typography>{Resources.plus}</Typography>
								<Typography className={classes.border}>{Resources.minus}</Typography>
								<Typography>{t('ResizeMinus')}</Typography>
							</Grid>
						</Grid>
					</VisibilityControl>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('kanbanBoard')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.esc}</Typography>
							<Typography>{t('EscapeToClose')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('calendar')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.arrowLeft}</Typography>
							<Typography>{t('or')}</Typography>
							<Typography className={classes.border}>{Resources.arrowRight}</Typography>
							<Typography>{t('ArrowsToTime')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('time')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.arrowLeft}</Typography>{' '}
							<Typography>{t('or')}</Typography>
							<Typography className={classes.border}>{Resources.arrowRight}</Typography>
							<Typography>{t('ArrowsToTime')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('timeModal')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.esc}</Typography>
							<Typography>{t('EscapeToClose')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('dictionary')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.esc}</Typography>
							<Typography>{t('EscapeToClose')}</Typography>
						</Grid>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.enter}</Typography>
							<Typography>{t('EnterToSave')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('gant')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.arrowLeft}</Typography>{' '}
							<Typography>{t('or')}</Typography>
							<Typography className={classes.border}>{Resources.arrowRight}</Typography>
							<Typography>{t('ArrowsToTime')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('project')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.arrowLeft}</Typography>{' '}
							<Typography>{t('or')}</Typography>
							<Typography className={classes.border}>{Resources.arrowRight}</Typography>
							<Typography>{t('ArrowsToSwitch')}</Typography>
						</Grid>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.esc}</Typography>
							<Typography>{t('EscapeToClose')}</Typography>
						</Grid>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.enter}</Typography>
							<Typography>{t('EnterToSave')}</Typography>
						</Grid>
					</Grid>
					<Grid item className={classes.margin}>
						<Typography variant={Resources.GlobalResources.h4}>{t('clock')}</Typography>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.one}</Typography>
							<Typography>{t('StartIssue')}</Typography>
						</Grid>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.two}</Typography>
							<Typography>{t('PauseIssue')}</Typography>
						</Grid>
						<Grid container alignItems={Resources.GlobalResources.center}>
							<Typography className={classes.border}>{Resources.ctrl}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.alt}</Typography>
							<Typography>{Resources.plus}</Typography>
							<Typography className={classes.border}>{Resources.three}</Typography>
							<Typography>{t('EndIssue')}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Popover>
	);
};

export default HotkeysPopover;
