import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mainKey: 'UserList',
	userDetailsLink: '/organization/userDetails',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				list: 'Lista użytkowników',
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				list: 'User list',
			},
		},
	},
};
