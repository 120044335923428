import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { v1 as uuid } from 'uuid';

import ClearIcon from '@mui/icons-material/Clear';
import { Link } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip/Tooltip';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const AvatarTooltip = (props: Props<any>) => {
	const { item, avatar, navigateOnClick, sizePx, handleChoose, isSelected, isDefault, getTooltip } = props;
	const classes = useStyles();

	const { tString } = useTrans('SearchBar.ProfileSelector');

	const selectedTemp = isSelected || isDefault ? classes.selected : undefined;
	const isClear = item?.Id == 0;
	const isUnassigned = item?.Id == -1;
	const avatarTemp = avatar == Resources.GlobalResources.empty || !avatar ? classes.noAvatar : classes.avatar;

	const content = (
		<div>
			<VisibilityControl
				condition={navigateOnClick}
				alternative={
					<VisibilityControl
						condition={!isClear}
						alternative={
							<Avatar
								sx={sizePx}
								key={uuid()}
								onClick={() => handleChoose(item)}
								className={clsx(selectedTemp, avatarTemp, classes.textBlack)}
							>
								<ClearIcon />
							</Avatar>
						}
					>
						<Avatar
							sx={sizePx}
							key={uuid()}
							onClick={() => handleChoose(item)}
							className={clsx(selectedTemp, avatarTemp)}
							src={avatar}
						/>
					</VisibilityControl>
				}
			>
				<Link
					key={uuid()}
					underline={Resources.GlobalResources.none}
					target={Resources.GlobalResources.blank}
					to={`${Resources.user}${item?.Id}`}
					component={RouterLink}
				>
					<div>
						<Avatar
							sx={sizePx}
							onClick={() => handleChoose(item)}
							className={clsx(selectedTemp, avatarTemp, classes.inheritPosition)}
							src={avatar}
						/>
					</div>
				</Link>
			</VisibilityControl>
		</div>
	);

	return (
		<div style={{ zIndex: 2 }} key={`${Resources.key}${item?.Id}`}>
			<VisibilityControl
				condition={!getTooltip}
				alternative={
					<VisibilityControl
						condition={!isUnassigned && !isClear}
						alternative={
							<Tooltip
								key={uuid()}
								followCursor
								title={isUnassigned ? tString('unassigned') : tString('clear')}
							>
								<div>{content}</div>
							</Tooltip>
						}
					>
						<Tooltip key={uuid()} title={getTooltip(item)}>
							<div>{content}</div>
						</Tooltip>
					</VisibilityControl>
				}
			>
				{content}
			</VisibilityControl>
		</div>
	);
};
