import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { ETab } from './model';

export const Resources = {
	...GlobalResources,
	Configuration: {
		HeaderTop: {
			add: '80px',
			edit: '40px',
		},
	},
	propertyField: {
		title: 'title' as const,
		projectId: 'projectId' as const,
		description: 'description' as const,
		assignedToId: 'assignedToId' as const,
		startDate: 'startDate' as const,
		dueDate: 'dueDate' as const,
		comment: 'comment' as const,
	},
	issue: {
		description: '',
		isCustomerVisible: true,
		projectId: 31,
		reckonId: 0,
		title: '',
	},
	fileUpload: 'file-upload',
	ariaValue: 'primary checkbox' as const,
	portalComments: 'portalComments',
};

export const Translation = {
	pl: {
		PortalIssue: {
			IssueColumn: {
				send: 'Wyślij',
				labelsModal: {
					title: 'Tytuł',
					project: 'Projekt',
					description: 'Wiadomość',
					assignee: 'Osoba wykonująca',
					reporter: 'Zgłaszający',
					tag: 'Tag',
					started: 'Start',
					due: 'Koniec',
					hint: 'Nazwa zgłoszenia',
					today: 'Dziś',
					time: 'Czas',
				},
				attachment: 'Załączniki',
				comments: 'Komentarze',
				addAttachment: 'Dodaj załącznik',
				sendAsDone: 'Zrobione',
				sendAsCancel: 'Anulowane',
				pleaseEnter: 'Proszę wpisać ',
				emptyDescription: 'wiadomość',
				emptyTitle: 'tytuł zgłoszenia',
				createAnother: 'Zgłoś kolejne',
				issueDescription: 'Opis zgłoszenia',
			},
		},
	},
	en: {
		PortalIssue: {
			IssueColumn: {
				send: 'Send',
				labelsModal: {
					title: 'Title',
					project: 'Project',
					description: 'Message',
					assignee: 'Assignee',
					reporter: 'Reporter',
					tag: 'Tag',
					started: 'Started',
					due: 'Due',
					hint: 'Issue name',
					today: 'Today',
					time: 'Time',
				},
				comments: 'Comments',
				attachment: 'Attachment',
				addAttachment: 'Add Attachment',
				sendAsDone: 'Done',
				sendAsCancel: 'Canceled',
				pleaseEnter: 'Please enter  ',
				emptyDescription: 'your message',
				emptyTitle: 'issue title',
				createAnother: 'Create another',
				issueDescription: 'Issue description',
			},
		},
	},
};

export const getTabs = (): { value: string; label?: string; visible: boolean }[] => {
	return [
		{ value: ETab.Comments, label: t('PortalIssue.IssueColumn.comments'), visible: true },
		{ value: ETab.Attachment, label: t('PortalIssue.IssueColumn.attachment'), visible: true },
	];
};
