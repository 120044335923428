import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { IReducerState } from '@/store/reducers/types';

import { Comments } from './Comments';
import { useStyles } from './styles';

export const IssueComments = () => {
	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const classes = useStyles();

	return (
		<div className={clsx(classes.flexColumn, classes.fullWidth)}>
			<Comments issueId={issue?.Id as number} />
		</div>
	);
};
