export const InvoiceTemplateStyles = (): string => {
	return `
    <style>
      body {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        padding: 0;
        margin: 0;
      }
      
      p {
        margin: 0;
      }

      .container {
        height: 850px;
        position: relative;
        padding: 1.5rem;
        width: 100%;
      }

      .heading {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid #71717a20;
      }

      .heading-details {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        max-width: 300px;
        text-align: end;
      }

      .heading-details > p:nth-child(1) {
        font-weight: 600;
      }

      .heading-details > p:not(:nth-child(1)) {
        font-size: 0.75rem;
        font-weight: 400;
        color: #71717a;
      }

      .subheading {
        display: flex;
        padding: 1rem 0;
      }

      .contact-table-wrapper, .items-table-wrapper, .summary-table-wrapper {
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
        border: 1px solid #71717a40;
      }

      .contact-table, .items-table, .summary-table {
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse;
        min-width: 100%;
      }

      .contact-table > thead, .items-table > thead, .summary-table > thead {
        background: #71717a20;
      }

      .contact-table th, .items-table th, .summary-table th {
        padding: 0.5rem 0.75rem;
        text-align: left;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
      }

      .contact-table > tbody > tr, .items-table > tbody > tr, .summary-table > tbody > tr{
        border-top: 1px solid #71717a40;
      }

      .contact-table > tbody > tr > td, .items-table > tbody > tr > td, .summary-table > tbody > tr > td {
        padding: 1rem 0.75rem;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
        color: #71717a;
      }

      .contact-table > tbody > tr > td > p:nth-child(1) {
        font-size: 0.85rem;
        font-weight: 600;
        color: #000;
      }

      .invoice-details, .invoice-summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        column-gap: 2.5rem;
        font-size: 0.75rem;
        font-weight: 400;
        color: #71717a;
        margin: 2rem 0;
      }

      .invoice-details-item {
        display: flex;
        justify-content: space-between;
      }

      .invoice-details-item > p:nth-child(1) {
        font-weight: 600;
        color: #000;
      }

      .page-number {
        color: #71717a;
        font-size: 0.75rem;
        font-weight: 400;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    </style>
  `;
};
