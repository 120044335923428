import { floor } from 'lodash';

export interface IInvoiceTemplateTable {
	data: IInvoiceTemplateTableRow[];
	from?: number;
	to?: number;
}

export const InvoiceTemplateTable = (props: IInvoiceTemplateTable): string => {
	return `
    <div class="items-table-wrapper">
      <table id="items-table" class="items-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Item</th>
            <th>Unit</th>
            <th>Qty</th>
            <th>Net</th>
            <th>VAT</th>
            <th>Gross</th>
            <th>Net Tot.</th>
            <th>Gross Tot.</th>
          </tr>
        </thead>
        <tbody>
          ${props.data
				.slice(props.from, props.to)
				.map(row => InvoiceTemplateTableRow(row))
				.join('')}
        </tbody>
      </table>
    </div>
  `;
};

export interface IInvoiceTemplateTableRow {
	number: number;
	item: string;
	unit: string;
	quantity: number;
	net: number;
	vat: number;
	gross: number;
	netTotal: number;
	grossTotal: number;
}

export const InvoiceTemplateTableRow = (props: IInvoiceTemplateTableRow): string => {
	return `
    <tr>
      <td>${props.number}</td>
      <td>${props.item}</td>
      <td>${props.unit}</td>
      <td>${props.quantity}</td>
      <td>${floor(props.net, 2).toFixed(2)}</td>
      <td>${props.vat}%</td>
      <td>${floor(props.gross, 2).toFixed(2)}</td>
      <td>${floor(props.netTotal, 2).toFixed(2)}</td>
      <td>${floor(props.grossTotal, 2).toFixed(2)}</td>
    </tr>
  `;
};
