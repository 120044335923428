export const DelegationTemplateStyles = (): string => {
	return `
    <style>
      body {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        padding: 0;
        margin: 0;
      }

      p {
        margin: 0;
      }

      .container {
        height: 850px;
        position: relative;
        padding: 1.5rem;
        width: 100%;
      }

      .report-heading {
        color: #09090b;
        font-size: 2.25rem;
        font-weight: 600;
        letter-spacing: -0.025em;
        line-height: 2.5rem;
      }

      .company-name {
        color: #71717a;
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: -0.025em;
        line-height: 1.75rem;
      }

      .report-details {
        color: #71717a;
        font-size: 0.75rem;
        line-height: 1rem;
        margin-top: 1rem;
      }

      .report-description {
        color: #71717a;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
        margin-top: 1rem;
      }

      .payout-amount {
        border-bottom: 1px solid #71717a20;
        color: #71717a;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.25rem;
        margin-top: 1rem;
        padding-bottom: 0.5rem;
        text-align: right;
        width: 100%;
      }

      .conversion-rate-wrapper {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
      }

      .conversion-rate {
        color: #71717a;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      .delegation-info {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 500;
        letter-spacing: -0.025em;
        margin: 1rem 0;
        width: 100%;
      }

      .report-table-wrapper, .perdiem-table-wrapper {
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
        border: 1px solid #71717a40;
      }

      .report-table, .perdiem-table {
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse;
        min-width: 100%;
      }

      .report-table > thead, .perdiem-table > thead {
        background: #71717a20;
      }

      .report-table th, .perdiem-table th {
        padding: 0.5rem 0.75rem;
        text-align: left;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
      }

      .report-table th:nth-child(3), .report-table th:nth-child(4) {
        text-align: right;
      }

      .report-table > tbody > tr, .perdiem-table > tbody > tr {
        border-top: 1px solid #71717a40;
      }

      .report-table > tbody > tr > td {
        padding: 1rem 0.75rem;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 300;
      }

      .perdiem-table > tbody > tr > td {
        padding: 0.75rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 300;
      }

      .small {
        font-size: 0.65rem;
        color: #71717a;
      }

      .report-table > tbody > tr > td:nth-child(1) > p:nth-child(1) {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 400;
      }

      .report-table > tbody > tr > td:nth-child(1) > p:nth-child(2) {
        font-weight: 400;
        color: #71717a;
      }

      .report-table > tbody > tr > td:nth-child(1), .report-table > tbody > tr > td:nth-child(2) {
        max-width: 150px;
        word-break: break-word;
      }

      .report-table > tbody > tr > td:nth-child(3) {
        text-align: right;
      }

      .report-table > tbody > tr > td:nth-child(4) {
        text-align: right;
        font-size: 1rem;
        line-height: 1.25rem;
      }

      .perdiem-table > tbody > tr > td:nth-child(1) > p:nth-child(2) {
        font-size: 0.65rem;
        font-weight: 400;
        color: #71717a;
      }

      .perdiem-table > tbody > tr > td:nth-child(2) > p:nth-child(2) {
        font-size: 0.65rem;
        font-weight: 400;
        color: #71717a;
      }

      .summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        width: 100%;
        max-width: 200px;
        margin-left: auto;
        font-size: 0.825rem;
        line-height: 1rem;
      }

      .summary > :nth-child(odd) {
        text-align: end;
      }

      .summary > :nth-child(even) {
        text-align: end;
      }

      .summary-gross-label, .summary-not-reimbursable-label, .summary-payout-label {
        color: #71717a;
      }

      .summary-not-reimbursable-label, .summary-not-reimbursable-amount {
        margin-top: 1.5rem;
      }

      .header-icon-wrapper {
        display: flex;
        align-items: center;
      }

      .header-icon {
        fill: #71717a;
        height: 1rem;
        width: 1rem;
      }

      .perdiem-positive {
        color: #22c55e;
      }

      .perdiem-negative {
        color: #ef4444;
      }

      .page-number {
        color: #71717a;
        font-size: 0.75rem;
        font-weight: 400;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    </style>
  `;
};
