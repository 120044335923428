import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			dropzoneFile: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '0.5rem',
				marginBottom: '1rem',
				width: '100%',
				border: `1px ${theme.palette.border.dark} solid`,
				backgroundColor: theme.palette.background.paper,
				padding: '1.25rem 1.5rem',
				boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)',
				cursor: 'default',
			},

			dropzoneFileContent: {
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				justifyContent: 'space-between',
			},

			dropzoneFileIcon: {
				flexShrink: 0,
				color: theme.palette.lightGray.main,
				height: '2.5rem',
				width: '2.5rem',
			},

			dropzoneFileIconButton: {
				borderRadius: '100px',
			},

			dropzoneFileInfo: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'center',
			},

			dropzoneFileLoadingBar: {
				minWidth: '10rem',
				height: '0.5rem',
				borderRadius: '0.25rem',
				backgroundColor: theme.palette.background.default,
				marginRight: '1rem',
			},

			dropzoneFileLoadingBarProgress: {
				height: '100%',
				borderRadius: '0.25rem',
				backgroundColor: theme.palette.primary.main,
			},

			dropzoneFileCollapse: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '1rem',
				width: '100%',
				marginTop: '2rem',
			},

			dropzoneFileCollapseFooter: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'end',
				marginTop: '1rem',
				width: '100%',
			},

			dropzoneFileDownload: {
				'&:hover': {
					'&& svg': {
						color: theme.palette.primary.main,
					},
					backgroundColor: `${theme.palette.primary.main}11`,
				},
			},

			dropzoneFileEdit: {
				'&:hover': {
					'&& svg': {
						color: theme.palette.warning.light,
					},
					backgroundColor: `${theme.palette.warning.light}11`,
				},
			},

			dropzoneFileDelete: {
				'&:hover': {
					backgroundColor: `${theme.palette.error.light}11`,
					'&& svg': {
						color: theme.palette.error.light,
					},
				},
			},

			dropzoneFileMenu: {
				zIndex: 9999,
			},

			footerButton: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				display: 'flex',
				gap: theme.spacing(1),
			},
		};
	},
	{
		name: `DelegationList_DelegationModal_DelegationModalContent_DelegationDetails_DelegationAttachments_Dropzone_DropzoneFile`,
	}
);
