import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	customers: '#/customers/',
};

export const Translation = {
	pl: {
		WorkDayEndSummary: {
			workDaySummary: 'Podsumowanie dnia pracy z ',
			creationDate: 'Data rozpoczęcia',
			dateOfCompletion: 'Data wypełnienia',
			user: 'Użytkownik',
			finalForm: 'Formularz zakończenia pracy',
		},
	},
	en: {
		WorkDayEndSummary: {
			workDaySummary: 'Workday summary from ',
			creationDate: 'Start date',
			dateOfCompletion: 'Completion date',
			user: 'User',
			finalForm: 'Formularz zakończenia pracy',
		},
	},
};
