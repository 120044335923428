import * as xlsx from 'exceljs';
import { floor } from 'lodash';

export interface IDelegationExcelTemplateExpense {
	Name: string;
	Type: string;
	Description: string;
	Reimbursable?: boolean;
	GrossBeforeConversion?: number;
	Currency?: string;
	ConversionRate?: number;
	Vat?: number;
	VatAmount?: number;
	GrossAmount: number;
}

export const DelegationExcelTemplateExpenses = (props: IDelegationExcelTemplateExpense[], workbook: xlsx.Workbook) => {
	const sheet = workbook.addWorksheet('Expenses');

	sheet.addTable({
		name: 'Delegation expenses',
		ref: 'A1',
		headerRow: true,
		totalsRow: false,
		style: {
			theme: 'TableStyleLight2',
			showRowStripes: true,
		},
		columns: [
			{ name: 'Expense name' },
			{ name: 'Expense type' },
			{ name: 'Expense description' },
			{ name: 'Reimbursable' },
			{ name: 'Gross before conversion' },
			{ name: 'Currency' },
			{ name: 'Conversion rate' },
			{ name: 'VAT' },
			{ name: 'VAT amount' },
			{ name: 'Gross amount' },
		],
		rows: props.map(expense => [
			expense.Name,
			expense.Type,
			expense.Description,
			expense.Reimbursable ? 'Yes' : 'No',
			expense.GrossBeforeConversion ? floor(expense.GrossBeforeConversion, 2).toFixed(2) : '-',
			expense.Currency ?? '-',
			expense.ConversionRate ?? '-',
			expense.Vat ?? '-',
			expense.VatAmount ? floor(expense.VatAmount, 2).toFixed(2) : '-',
			floor(expense.GrossAmount, 2).toFixed(2),
		]),
	});

	sheet.eachRow(row => {
		row.eachCell(cell => {
			cell.alignment = { vertical: 'top', horizontal: 'left' };
		});
	});

	sheet.columns.forEach(function(column) {
		let maxLength = 0;
		if (column && typeof column['eachCell'] === 'function') {
			column['eachCell']({ includeEmpty: true }, function(cell) {
				const columnLength = cell.value ? cell.value.toString().length : 10;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
			});
			column.width = maxLength < 10 ? 10 : maxLength;
		}
	});
};
