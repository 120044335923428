import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	formKey: 'Form',
	projectKey: 'Project',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							MappingsTab: {
								mappingsTitle: 'Mapowania kanbana',
								subtext:
									'W tym miejscu możesz zarządzać mapowaniami kanbana, dodawać, usuwać lub generować automatycznie',
								save: 'Zapisz',
								form: 'Formularz',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							MappingsTab: {
								mappingsTitle: 'Kanban mappings',
								subtext: 'Here You can manage mappings for kanban, add, delete or generate automaticly',
								save: 'Save',
								form: 'Form',
							},
						},
					},
				},
			},
		},
	},
};
