import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		iframe: {
			border: 'none',
			width: '95%',
			height: '95%',
			objectFit: 'contain',
		},
		root: {
			position: 'fixed',
			height: '80%',
			width: '80%',
			top: '10%',
			left: '10%',
			background: '#000000c0',
			zIndex: 1150,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'hidden',
		},
		imageContainer: {
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),

	{
		name: `DelegationList_DelegationModal_DelegationModalContent_DelegationDetails_DelegationAttachments_Dropzone_DropzoneAttachemntRecord_PopupDialog`,
	}
);
