import clsx from 'clsx';
import React from 'react';

import { Tooltip, Typography } from '@mui/material';

import { EDictionaryItemType } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useTrans } from '@/utils/hooks/useTrans';

import { DictionaryItemTooltip } from './DIctionaryItemTooltip';
import { Props } from './model';
import { useStyles } from './styles';

export const DictionaryItemComponent = (props: Props) => {
	const { dictionaryItem, className, classNameText, showComma = false } = props;
	const classes = useStyles();

	const { t } = useTrans('IssueModal.dictionaryItemType');

	return (
		<div className={clsx(className, classes.flexYCenter)}>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Text}>
				{/* <TooltipTextBase className={classes.text} text={dictionaryItem?.ValueText} /> */}
				{/* <TooltipTextBase className={classes.text} text={dictionaryItem?.ValueDecimal} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Text}`)}
							label={dictionaryItem?.ValueText ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueText}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.TagGroup}>
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.TagGroup}`)}
							label={dictionaryItem?.ValueTagGroup?.Label ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueTagGroup?.Label}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Integer}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueInteger} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Integer}`)}
							label={dictionaryItem?.ValueInteger ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueInteger}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Dictionary}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueDictionary?.Name} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Dictionary}`)}
							label={dictionaryItem?.ValueDictionary?.Name ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueDictionary?.Name}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Decimal}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueDecimal} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Decimal}`)}
							label={dictionaryItem?.ValueDecimal ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueDecimal}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.DateTime}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueDateTime} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.DateTime}`)}
							label={dictionaryItem?.ValueDateTime ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueDateTime}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Date}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueDate} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Date}`)}
							label={dictionaryItem?.ValueDate ?? ''}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueDate}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={dictionaryItem?.DictionaryItemTypeId === EDictionaryItemType.Boolean}>
				{/* <TooltipTextBase className={classNameText} text={dictionaryItem?.ValueBoolean} /> */}
				<Tooltip
					title={
						<DictionaryItemTooltip
							dictionaryItemType={t(`${EDictionaryItemType.Boolean}`)}
							label={dictionaryItem?.ValueBoolean ? 'tak' : 'nie'}
						/>
					}
					// hidden={false}
					placement="top"
				>
					<Typography className={classNameText}>{dictionaryItem?.ValueBoolean}</Typography>
				</Tooltip>
			</VisibilityControl>
			<VisibilityControl condition={!!showComma}>{`, `}</VisibilityControl>
		</div>
	);
};
