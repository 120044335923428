import { EFieldDefinitionType, IDictionaryItem, IFieldValue, ITag } from '@skillandchill/tasker-types';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { FieldValueFactory } from '@/layouts/Widgets/IssueFormSectionWidget/IssueFormSection/utils/fieldValueFactory';

import { IExtendedFieldValue } from 'view/IssueModal/modal';

export const getChangesInItems = (
	issueFormSectionToIssueField: IIssueFormSectionToIssueField,
	newValues: ITag[] | ITag | IDictionaryItem[] | IDictionaryItem | boolean | number | string | Date
): {
	deletedFieldValues: Partial<IExtendedFieldValue>[];
	addedFieldValues: Partial<IExtendedFieldValue>[];
} => {
	let deletedFieldValues = [] as Partial<IExtendedFieldValue>[];
	let addedFieldValues = [] as Partial<IExtendedFieldValue>[];

	const initialFieldValues = (issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? []) as Partial<
		IExtendedFieldValue
	>[];
	const type = issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId as number;

	const typesWithSingleValue = [
		EFieldDefinitionType.Boolean,
		EFieldDefinitionType.Date,
		EFieldDefinitionType.DateTime,
		EFieldDefinitionType.Decimal,
		EFieldDefinitionType.Integer,
		EFieldDefinitionType.Text,
		EFieldDefinitionType.TextArea,
	];

	const typesWithMultipleValues = [EFieldDefinitionType.Dictionary, EFieldDefinitionType.TagGroup];

	if (typesWithSingleValue.includes(type)) {
		deletedFieldValues = initialFieldValues;

		addedFieldValues = [
			FieldValueFactory.create(
				type,
				issueFormSectionToIssueField?.AttributeDefinitionId as number,
				newValues as boolean | number | string | Date
			),
		];
	} else if (typesWithMultipleValues.includes(type)) {
		if (type === EFieldDefinitionType.TagGroup) {
			const newValuesArray = (Array.isArray(newValues) ? newValues : [newValues]) as ITag[];
			deletedFieldValues = initialFieldValues?.filter(
				(fieldValue: Partial<IFieldValue>) => !newValuesArray.includes(fieldValue.ValueTag as ITag)
			);

			const addedTags = newValuesArray?.filter(
				(tag: ITag) =>
					!initialFieldValues
						?.map((fieldValue: Partial<IFieldValue>) => fieldValue.ValueTag as ITag)
						?.includes(tag)
			);
			addedFieldValues = addedTags?.map((tag: ITag) => {
				return FieldValueFactory.create(
					type,
					issueFormSectionToIssueField?.AttributeDefinitionId as number,
					tag.Id
				);
			});
		}

		if (type === EFieldDefinitionType.Dictionary) {
			const newValuesArray = (Array.isArray(newValues) ? newValues : [newValues]) as IDictionaryItem[];
			deletedFieldValues = initialFieldValues?.filter(
				(fieldValue: Partial<IFieldValue>) =>
					!newValuesArray.includes(fieldValue.ValueDictionaryItem as IDictionaryItem)
			);

			const addedTags = newValuesArray?.filter(
				(tag: IDictionaryItem) =>
					!initialFieldValues
						?.map((fieldValue: Partial<IFieldValue>) => fieldValue.ValueDictionaryItem as IDictionaryItem)
						?.includes(tag)
			);
			addedFieldValues = addedTags?.map((tag: IDictionaryItem) => {
				return FieldValueFactory.create(
					type,
					issueFormSectionToIssueField?.AttributeDefinitionId as number,
					tag.Id
				);
			});
		}
	}

	return { deletedFieldValues, addedFieldValues };
};
