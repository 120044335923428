export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							validationError:
								'Brak wypełnionych wszystkich wymaganych pól, proszę uzupełnić je przed zapisaniem faktury',
							created: 'Faktura została stworzona',
							updated: 'Faktura została zaktualizowana',
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							validationError:
								'Not all required fields are filled, please fill them before saving the invoice',
							created: 'Invoice has been created',
							updated: 'Invoice has been updated',
						},
					},
				},
			},
		},
	},
};
