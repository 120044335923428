export const Translation = {
	pl: {
		Settings: {
			WidgetsConfiguration: {
				pageTitle: 'Konfiguracja komponentów',
			},
		},
	},
	en: {
		Settings: {
			WidgetsConfiguration: {
				pageTitle: 'Widget configuration',
			},
		},
	},
};
