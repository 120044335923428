import * as xlsx from 'exceljs';
import { saveAs } from 'file-saver';

import { DelegationExcelTemplateDetails, IDelegationExcelTemplateDetails } from './details';
import { DelegationExcelTemplateExpenses, IDelegationExcelTemplateExpense } from './expenses';
import { DelegationExcelTemplatePerdiems, IDelegationExcelTemplatePerDiem } from './perdiems';

interface IDelegationExcelTemplate {
	details: IDelegationExcelTemplateDetails;
	expenses: IDelegationExcelTemplateExpense[];
	perdiems: IDelegationExcelTemplatePerDiem[];
}

export const DelegationExcelTemplate = async (props: IDelegationExcelTemplate) => {
	const workbook = new xlsx.Workbook();
	DelegationExcelTemplateDetails(props.details, workbook);
	DelegationExcelTemplateExpenses(props.expenses, workbook);
	DelegationExcelTemplatePerdiems(props.perdiems, workbook);
	const buffer = await workbook.xlsx.writeBuffer();
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const fileExtension = '.xlsx';

	const blob = new Blob([buffer], { type: fileType });

	saveAs(blob, 'delegationtest' + fileExtension);
};
