import clsx from 'clsx';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Typography } from '@mui/material';

import { Button } from '@skillandchill/tasker-widgets2';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './modal';
import { Resources } from './resources';
import { useStyles } from './styles';
export const DeleteIssue = (props: Props) => {
	const { handleDeleteIssueWithConfirmation } = props;
	const classes = useStyles();

	const { t } = useTrans('IssueModal.IssueModalTitle.MoreOptions.DeleteIssue');

	return (
		<div className={clsx(classes.flexColumn, classes.container)}>
			<Button
				onClick={handleDeleteIssueWithConfirmation}
				variant={Resources.GlobalResources.contained}
				className={clsx(classes.deleteArchiv)}
			>
				<DeleteForeverIcon />
				{t('delete')}
			</Button>
			<Typography className={classes.subText} variant={Resources.GlobalResources.body2}>
				{t('description')}
			</Typography>
		</div>
	);
};
