import uuid from 'uuid';

import { ITaskerUser } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: (x: Partial<ITaskerUser>) => `Tasker_User_MultiValueContainer${uuid()}_${x?.Id}`,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					ProjectOwnerManager: {
						MultiValueContainer: {
							more: '+ ',
							records: ' rekordów.',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					ProjectOwnerManager: {
						MultiValueContainer: {
							more: '+: ',
							records: ' records.',
						},
					},
				},
			},
		},
	},
};
