import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					add: 'Dodaj Kanban',
					configure: 'Konfiguruj kanban',
					liteMode: 'Lite mode',
					kanbanLightMode:
						'Wyświetlaj tylko podstawowe informacje na kafelkach kanbana, polepszy to wydajność całego kanbana',
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					add: 'Add Kanban',
					configure: 'Configure kanban',
					liteMode: 'Lite mode',
					kanbanLightMode:
						'Display only basic information on kanban tiles, it will improve the efficiency of whole kanban',
				},
			},
		},
	},
};
