import Dialog from '@mui/material/Dialog';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const PopupDialog = (props: Props): JSX.Element => {
	const { onClose, open, iFrameSrc, isImage, title } = props;
	const classes = useStyles();

	return (
		<Dialog onClose={onClose} open={open}>
			<div className={classes.root}>
				{isImage ? (
					<div className={classes.imageContainer}>
						<img
							alt={title ?? Resources.GlobalResources.empty}
							className={classes.iframe}
							src={iFrameSrc}
						/>
					</div>
				) : (
					<iframe
						title={title ?? Resources.GlobalResources.empty}
						className={classes.iframe}
						src={iFrameSrc}
					/>
				)}
			</div>
		</Dialog>
	);
};
