import clsx from 'clsx';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography } from '@mui/material';

import bytesToSize from '@skillandchill/tasker-widgets2/dist/utils/bytesToSize';

import { useTrans } from '@/utils/hooks/useTrans';

import { EDropzonePlacement } from '../model';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const DropzoneInfo = (props: Props): JSX.Element => {
	const {
		allowedFiletypes,
		maxFilesize,
		classes: classesInput,
		dropZonePlacement = EDropzonePlacement.Bottom,
	} = props;

	const classes = useStyles();
	const { t } = useTrans(
		'DelegationList.DelegationModal.DelegationModalContent.DelegationDetails.DelegationAttachments.Dropzone.DropzoneInfo'
	);

	return (
		<div
			className={clsx(
				classes.dropzoneInfo,
				classesInput?.dropZoneArea,
				dropZonePlacement === EDropzonePlacement.Top ? classes.top : classes.bottom
			)}
		>
			<UploadFileIcon className={clsx(classes.dropzoneInfoIcon, classesInput?.dropZoneAreaIcon)} />
			<Typography variant={Resources.GlobalResources.body1} className={classes.dropzoneInfoText}>
				<strong>{t('callToAction1')}</strong> {t('callToAction2')}
			</Typography>
			<Typography variant={Resources.GlobalResources.body2} className={classes.dropzoneInfoText}>
				{t('allowedExtensions')} {allowedFiletypes.Extensions.join(', ')} ({Resources.max}{' '}
				{bytesToSize(maxFilesize)})
			</Typography>
		</div>
	);
};
