import {
	FullFilters,
	IAttachment,
	IComment,
	IDictionary,
	IFieldValue,
	IIssue,
	IIssueForm,
	IIssueKanbanDetails,
	IIssueToIssueRelationship,
	IProject,
	IProjectIssueType,
	ITaskerUser,
	IWorkLog,
} from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { getKanbanDetails } from '@/utils/useRouter';

import { getInitialFilterNew } from 'view/SearchBar/resources';

const State = {
	////////////ISSUE////////////
	issueModal: {} as IIssue,
	issueModalChildren: [] as (IIssue & { RelationId: number })[],
	issueSubtasksCount: 0 as number,
	issueModalParent: [] as (IIssue & { RelationId: number })[],
	issueModalEquivalent: [] as (IIssue & { RelationId: number })[],
	issueHigherOrderTasksCount: 0 as number,
	issueEquivalentTasksCount: 0 as number,

	///////////Filter////////////
	filterIssue: getInitialFilterNew() as FullFilters,
	///////////Form/////////////
	validate: false,
	createIssueValue: [] as Partial<IFieldValue>[],
	updateIssueValue: [] as Partial<IFieldValue>[],
	deleteIssueValue: [] as Partial<IFieldValue>[],
	issueForm: {} as IIssueForm,
	/////////Attachment//////////
	attachmentIssue: [] as IAttachment[],
	fileToUpload: [] as File[],
	/////////Comments//////////
	comments: [] as IComment[],
	/////////Worklogs//////////
	issueUserWorklogNew: [] as IWorkLog[],
	issueTimeModal: {} as IIssue,
	/////////Options//////////
	dictionariesNew: [] as IDictionary[],
	profiles: [] as Partial<ITaskerUser>[],
	projectIssueTypes: [] as IProjectIssueType[],
	projectsListNew: [] as IProject[],
	issueModalOptions: [] as IIssue[],
	issueModalOptionsCount: 0 as number,
	issueModalKanbanDetails: {} as IIssueKanbanDetails,
	isPortalUser: true as boolean,
	kanbanDetails: { ...getKanbanDetails() },
	workLogByIssue: [] as IWorkLog[],
	workLogByIssueCount: 0 as number,

	// projects: [] as IProject[],
	// tags: [] as Partial<ITag>[],
	// taskerUsers: [] as ITaskerUser[],
	// tagGroupWithTags: [] as ITagGroup[],
};
export type IIssueModalEditorState = typeof State;

const issueModalEditorReducer = (state = State, action: ObjectAction): IIssueModalEditorState => {
	switch (action.type) {
		case actionTypes.ISSUE_URL: {
			return {
				...state,
				issueModal: {
					...state?.issueModal,
					IssueUrl: action.data,
				},
			};
		}
		case actionTypes.ISSUE_MODAL_ADD_CONNECTION:
		case actionTypes.ISSUE_MODAL_REMOVE_CONNECTION:
		case actionTypes.ISSUE_FETCH_ONE_NEW: {
			return {
				...state,
				issueModal: action?.data?.Issue,
				issueTimeModal: action?.data?.Issue,
				issueModalChildren: action?.data?.Children?.map((x: IIssueToIssueRelationship) => ({
					...x?.FromIssue,
					RelationId: x?.Id,
				})),
				issueModalParent: action?.data?.Parent?.map((x: IIssueToIssueRelationship) => ({
					...x?.ToIssue,
					RelationId: x?.Id,
				})),
				issueForm: action?.data?.Form,
				issueUserWorklogNew: action?.data?.UserWorkLog?.reverse(),
				attachmentIssue: action?.data?.Issue?.Attachments,
				isPortalUser: action.data?.IsPortalUser,
			};
		}
		case actionTypes.ISSUE_TIME_MODAL_LOCAL: {
			return {
				...state,
				issueModal: action?.issue,
				issueTimeModal: action?.issue,
			};
		}
		case actionTypes.FETCH_ONE_KANBAN: {
			return {
				...state,
				issueModalKanbanDetails: {
					isKanban: true,
					kanbanId: action?.kanban?.Id,
				} as IIssueKanbanDetails,
			};
		}
		case actionTypes.ISSUE_MODAL_PASS_DATA: {
			return {
				...state,
				issueModalKanbanDetails: action?.data,
			};
		}
		case actionTypes.ISSUE_SET_FILTERS_NEW: {
			return {
				...state,
				filterIssue: action?.filter?.filterNew,
			};
		}
		case actionTypes.ISSUE_MODAL_UPDATE_FORM_VALUES_LOCAL: {
			return {
				...state,
				createIssueValue: action?.create,
				updateIssueValue: action?.update,
				deleteIssueValue: action?.toDelete,
			};
		}
		case actionTypes.ISSUE_MODAL_RESET: {
			return {
				...State,
				validate: false,
				issueModalKanbanDetails: state?.issueModalKanbanDetails,
				filterIssue: state?.filterIssue,
				attachmentIssue: [],
			};
		}
		case actionTypes.ISSUE_MODAL_RESET_FORM_VALUES_LOCAL: {
			return {
				...state,
				createIssueValue: [],
				updateIssueValue: [],
			};
		}
		case actionTypes.ATTACHMENT_ISSUE_POST: {
			const attachments = state?.attachmentIssue;
			attachments.push(action.attachment);
			return {
				...state,
				issueModal: {
					...state?.issueModal,
					Attachments: attachments,
					AttachmentCount: attachments?.length,
				},
				attachmentIssue: attachments,
			};
		}
		case actionTypes.ATTACHMENT_ISSUE_GET: {
			const file = action?.file ? { fileToUpload: [...state?.fileToUpload, action?.file] } : {};
			return { ...state, attachmentIssue: action?.attachment, ...file };
		}
		case actionTypes.ATTACHMENT_ISSUE_DELETE: {
			const attachments = state.attachmentIssue?.filter(value => value?.Id != action?.attachmentId);
			return {
				...state,
				issueModal: {
					...state?.issueModal,
					Attachments: attachments,
					AttachmentCount: attachments?.length,
				},
				attachmentIssue: attachments,
			};
		}
		case actionTypes.ISSUE_MODAL_FETCH_COMMENTS: {
			return {
				...state,
				comments: action.comments,
			};
		}
		case actionTypes.ISSUE_MODAL_PATCH_COMMENT: {
			return {
				...state,
				comments: state?.comments?.map(x => {
					if (x.Id === action.comment.Id) return action.comment;
					return x;
				}),
			};
		}
		case actionTypes.SET_DICTIONARY_PAGE: {
			return {
				...state,
				dictionariesNew: [
					...state.dictionariesNew.filter(dictionery => dictionery.Id === -1),
					...action.dictionaries,
				],
			};
		}

		case actionTypes.CREATE_DICTIONARY_NEW_WITH_DEFAULTVALUES: {
			return {
				...state,
				dictionariesNew: [action.dictionary, ...state.dictionariesNew],
			};
		}

		case actionTypes.DELETE_DICTIONARY_WITH_DEFAULTVALUES: {
			return {
				...state,
				dictionariesNew: [...state.dictionariesNew.filter(dictionary => dictionary.Id !== -1)],
			};
		}
		case actionTypes.GET_ISSUE_FORM: {
			return {
				...state,
				issueForm: action?.data,
			};
		}
		case actionTypes.ISSUE_MODAL_GET_FORMS: {
			return {
				...state,
				issueForm: action?.data,
			};
		}
		case actionTypes.ISSUE_MODAL_GET_FORMS_IN_KANBAN: {
			return {
				...state,
				issueForm: action?.data?.form,
			};
		}
		case actionTypes.USER_FETCH_NEW: {
			return {
				...state,
				profiles: action.profiles,
			};
		}
		case actionTypes.ISSUE_MODAL_FETCH_BASIC: {
			return {
				...state,
				profiles: action.modal.taskerUsers,
				projectsListNew: action.modal.projects,
			};
		}
		case actionTypes.GET_PROJECT_ISSUE_TYPE_BY_PROJECT: {
			return {
				...state,
				projectIssueTypes: action?.data.ProjectIssueType,
				isPortalUser: action?.data.IsPortalUser,
			};
		}
		case actionTypes.WORKLOG_CREATE_NEW: {
			return {
				...state,
				issueUserWorklogNew: action?.worklogs?.reverse(),
			};
		}
		case actionTypes.WORKLOG_DELETE: {
			return {
				...state,
				issueUserWorklogNew: state?.issueUserWorklogNew?.filter((x: IWorkLog) => x?.Id != action.id),
			};
		}
		case actionTypes.PROJECT_FETCH_TYPE_NEW: {
			return {
				...state,
				projectsListNew: action.projects,
			};
		}
		case actionTypes.ISSUE_MODAL_ISSUE_OPTIONS: {
			return {
				...state,
				issueModalOptions: action?.data.Data,
				issueModalOptionsCount: action?.data.Count,
			};
		}
		case actionTypes.ISSUE_RESET: {
			return {
				...state,
				issueTimeModal: {} as IIssue,
			};
		}
		case actionTypes.REMOVE_ISSUE_FROM_FAVOURITES: {
			return {
				...state,
				issueModal:
					action.issueId == state?.issueModal?.Id
						? { ...state?.issueModal, FavouriteIssues: undefined }
						: state?.issueModal,
			};
		}
		case actionTypes.ADD_ISSUE_TO_FAVOURITES: {
			return {
				...state,
				issueModal:
					action?.issueId == state?.issueModal?.Id
						? { ...state?.issueModal, FavouriteIssues: action?.favouriteIssue }
						: state?.issueModal,
			};
		}

		case actionTypes.SET_ISSUE_SUBTASKS: {
			return {
				...state,
				issueModalChildren: action?.issueSubtasks ?? [],
				issueSubtasksCount: action.issueSubtasksCount,
			};
		}

		case actionTypes.SET_ISSUE_HIGHERORDERTASKS: {
			return {
				...state,
				issueModalParent: action?.issueHigherOrderTasks ?? [],
				issueHigherOrderTasksCount: action.issueHigherOrderTasksCount,
			};
		}
		case actionTypes.SET_ISSUE_EQUIVALENTTASKS: {
			return {
				...state,
				issueModalEquivalent: action?.issueEquivalentTasks ?? [],
				issueEquivalentTasksCount: action?.issueEquivalentTasksCount,
			};
		}
		case actionTypes.DELETE_ARCHIVED_ISSUES: {
			return {
				...state,
				issueModal: {
					...state?.issueModal,
					ArchivedIssues: [],
				},
			};
		}
		case actionTypes.CREATE_ARCHIVED_ISSUES: {
			return {
				...state,
				issueModal: {
					...state?.issueModal,
					ArchivedIssues: action?.data ?? [],
				},
			};
		}
		case actionTypes.SESSION_RECALCULATE: {
			return {
				...state,
				kanbanDetails: getKanbanDetails(),
			};
		}

		case actionTypes.WORKLOG_FETCH_BY_ISSUE_NEW: {
			return {
				...state,
				workLogByIssue: action?.worklogs,
				workLogByIssueCount: action?.count,
			};
		}

		case actionTypes.PORTAL_VALIDATE: {
			return {
				...state,
				validate: action?.data,
			};
		}

		default: {
			return state;
		}
	}
};

export default issueModalEditorReducer;
