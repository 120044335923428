import clsx from 'clsx';

import { CircularProgress, Icon } from '@mui/material';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const TaskerLogoLoader = (props: Props) => {
	const { isLoading, className } = props;
	const classes = useStyles();

	return (
		<div className={clsx(className, classes.wrapper, !isLoading && classes.wrapperHidden)}>
			<Icon fontSize={Resources.GlobalResources.large}>
				<img
					alt={Resources.taskerIcon}
					className={classes.img}
					src={Resources.taskerIcon}
					height={15}
					width={24}
				/>
			</Icon>
			<CircularProgress color={Resources.GlobalResources.primary} size={50} className={classes.fabProgress} />
		</div>
	);
};
