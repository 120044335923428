import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						TaskListHeader: { issueCount: 'Liczba zgłoszeń', addIssue: 'Dodaj zgłoszenie' },
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						TaskListHeader: { issueCount: 'Number of issues', addIssue: 'Add issue' },
					},
				},
			},
		},
	},
};
