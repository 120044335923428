import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	warning: 'warning' as const,
	selectProps: {
		menuPosition: 'fixed',
		isSearchable: false,
		closeMenuOnSelect: false,
		valuesLimit: 10,
	},
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						nameStep: 'Nazwij kanban',
						projectsStep: 'Wybierz projekty',
						columnsStep: 'Skonfiguruj kolumny',
						swimlinesStep: 'Utwórz pierwszy swimline',
						sureReset: 'Jesteś pewny, że chcesz przywrócić wszystkie zmiany w tym kroku?',
						nameYourKanban: 'Wybierz nazwę swojego kanbana',
						name: 'Nazwa',
						addProjects: 'Dodaj projekty z których ma zostać stworzony kanban',
						projects: 'Projekty',
						configureTags: 'Skonfiguruj grupę tagów. Tagi będą kolumnami kanbana',
						configureYourSwimlines: 'Skkonfiguruj swoje swimliny',
						step: 'Krok',
						editStep: 'Edytuj krok',
						resetStep: 'Resetuj krok',
						resetStepTooltip:
							'Naciśnięcie tego przycisku spowoduje cofnięcie wszystkich zmian w tym kroku.',
						summary: 'Podsumowanie',
						kanbanName: 'Nazwa kanbana',
						tagGroupName: 'Nazwa grupy tagów dla kolumn',
						columns: 'Kolumny',
						Errors: {
							nameErrorMessage: '^Podaj nazwę kanbana!',
							projectsErrorMessage: '^Dodaj projekty do kanbana!',
							tagGroupLabelErrorMessage: '^Podaj nazwę grupy!',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						nameStep: 'Name your kanban',
						projectsStep: 'Choose projects',
						columnsStep: 'Configure columns',
						swimlinesStep: 'Create first swimline',
						sureReset: 'Are you sure you want to reset whole changes in this step?',
						nameYourKanban: 'Name your kanban',
						name: 'Name',
						addProjects: 'Add projects which kanban will be based on',
						projects: 'Projects',
						configureTags: 'Configure tag group. Tags will be the columns of a kanban',
						configureYourSwimlines: 'Configure your swimlines',
						step: 'Step',
						editStep: 'Edit step',
						resetStep: 'Reset step',
						resetStepTooltip: 'Pressing this button will cause reset whole changes in this step.',
						summary: 'Summary',
						kanbanName: 'Kanban name',
						tagGroupName: 'Name of the tag group for columns',
						columns: 'Columns',
						Errors: {
							nameErrorMessage: '^Enter name of the kanban!',
							projectsErrorMessage: '^Add projects to kanban!',
							tagGroupLabelErrorMessage: '^Enter name of the tag group!',
						},
					},
				},
			},
		},
	},
};

export const getValidationRules = (): any => {
	return {
		Name: {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.nameErrorMessage'),
			},
		},
		ProjectIds: {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.projectsErrorMessage'),
			},
		},
		'KanbanColumnWrapper.Label': {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.tagGroupLabelErrorMessage'),
			},
		},
	};
};
