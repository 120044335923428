import * as https from 'https';

import { AsyncAction } from '@skillchill/redux-promisify';

import { IAttachment } from '@skillandchill/tasker-types';
import { Modifiable } from '@skillandchill/tasker-widgets2/dist/utils/types/global';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const ATTACHMENT_ISSUE_POST: ActionType = 'ATTACHMENT_ISSUE_POST';
export const ATTACHMENT_ISSUE_GET: ActionType = 'ATTACHMENT_ISSUE_GET';
export const ATTACHMENT_ISSUE_DELETE: ActionType = 'ATTACHMENT_ISSUE_DELETE';

const basePath = 'Attachment';

export interface Attachment extends Modifiable {
	fileName: string;
	fileSize: number;
	link?: string;
	isInCloud: boolean;
	isDeleted: boolean;
	modifiedById: number;
}

function _postAttachmentIssue(attachment: Partial<Attachment>): Action {
	return {
		type: ATTACHMENT_ISSUE_POST,
		attachment: attachment,
	};
}

function _deleteAttachment(attachmentId: number): Action {
	return {
		type: ATTACHMENT_ISSUE_DELETE,
		attachmentId: attachmentId,
	};
}

export function attachment_issue_post(
	attachment: Partial<IAttachment>,
	file: File,
	issueId: number,
	onFileUploadProgress?: (percentageProgress: number) => void
): AsyncAction {
	const fd = new FormData();
	fd.append('file', file);
	return function(context, dispatch) {
		context.then(response => dispatch(_postAttachmentIssue((response as IJson)?.data)));
		return axios().post(
			`${basePath}/Issue/${issueId}`,
			fd,
			onFileUploadProgress
				? {
						onUploadProgress: (progressEvent: { total: number; loaded: number }) => {
							if (onFileUploadProgress)
								onFileUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
						},
						httpsAgent: new https.Agent({ rejectUnauthorized: false }),
						maxBodyLength: Infinity,
						maxContentLength: Infinity,
						headers: {
							'Access-Control-Allow-Origin': '*',
						},
				  }
				: undefined
		);
	};
}

export function attachment_delete(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_deleteAttachment((response as IJson)?.data?.Id)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function addLocalAttachment(attachment: Partial<IAttachment>[], file?: File): Action {
	return {
		type: ATTACHMENT_ISSUE_GET,
		attachment,
		file,
	};
}
